<template>
  <!-- Le template -->
  <section>
    <small>V1.2</small>
  </section>
</template>

<script>
// Le script
export default {
  name: "version",
  data() {
    return {};
  },
  methods: {},
};
</script>