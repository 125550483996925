<template>
    <!-- Le template -->
    <v-container fill-height v-if="isLoggedIn">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title v-if="!isLoading"> {{formTitle}}  <span v-if="$route.name!='client/create'">{{client.denomination_sociale}}</span></v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-progress-linear :active="isLoading" color="red" :indeterminate="true" />

          <v-card-text class="pt-8 pb-4">
                <v-alert v-if="serverAlert" type="error" 
                close-text="Fermer"
                dismissible>
                <ul>
                    <div v-for="(value, propertyName, index) in formError" :key="index">
                        <li v-if="value && Array.isArray(value)">{{ propertyName }}: {{ value[0] }}</li> 
                        <li v-if="value && typeof value =='string'">{{ propertyName }}: {{ value }}</li>                      
                    </div>
                </ul>
                </v-alert>

        <v-snackbar
      v-model="snackbar"
      bottom
      color="success"      
      multi-line
      :timeout=6000 
   >
      <p v-html="snackbar_text"></p>
        
      <v-btn dark color="green darken-4" @click="snackbar_text =''; snackbar= false">Fermer</v-btn>
      <v-btn v-if="clientModified_saved" dark color="green darken-4" @click="snackbar= false" :to="{name:'client/list'}">Fermer et retourner au listing</v-btn>
    </v-snackbar>

            <v-form ref="form" v-model="valid" v-if="!isLoading">
              <v-row>
                <v-col>

                    <!--Statut du client -->
                    <v-radio-group class='mb-3' row hide-details                    
                    :disabled="$route.name=='client/show'"
                    v-model="clientModified.stade_de_vie"                                       
                    :error-messages="formError.stade_de_vie"
                    @focus="formError.stade_de_vie=null">
                    
                        <v-radio  label="Projet" value="projet"></v-radio>
                        <v-radio  label="Création" value="creation"></v-radio>
                        <v-radio  label="Développement" value="dev"></v-radio>
                        <v-radio  label="Consolidation" value="consolidation"></v-radio>
                    </v-radio-group>

                  <!--Denomination sociale-->  
                  <v-text-field  dense required                 
                    label="Dénomination Sociale"
                    name="denomination_sociale"
                    :disabled="$route.name=='client/show'"
                    v-model.trim="clientModified.denomination_sociale"
                    :rules="[rules.required]"
                    :error-messages="formError.denomination_sociale"
                    @focus="formError.denomination_sociale=null"                    
                  />

                  <!--Description du client :auto-grow="true"-->  
                  <v-textarea dense auto-grow                    
                    rows="1"
                    label="Description"
                    :disabled="$route.name=='client/show'"
                    v-model.trim="clientModified.description"                    
                  />   


                    <fieldset>
                    <legend>Infos contact client</legend>

                        <!--Nom contact du client-->
                        <v-text-field dense                           
                            label="Nom du contact"
                            name="nom_contact"  
                            :disabled="$route.name=='client/show'"                          
                            v-model="clientModified.nom_contact"                          
                            :error-messages="formError.nom_contact"                            
                            @focus="formError.nom_contact=null"                            
                        />

                        <!--prenom contact du client-->
                        <v-text-field  dense                          
                            label="Prénom du contact"
                            name="prenom_contact" 
                            :disabled="$route.name=='client/show'"                           
                            v-model="clientModified.prenom_contact"                           
                            :error-messages="formError.prenom_contact"                            
                            @focus="formError.prenom_contact=null"                            
                        /> 

                        <!--email contact du client-->
                        <v-text-field dense
                            type="email"                           
                            label="Email du contact"
                            name="email_contact"   
                            :disabled="$route.name=='client/show'"                         
                            v-model="clientModified.email_contact"                            
                            :rules="[rules.validEmail]"
                            :error-messages="formError.email_contact"                            
                            @focus="formError.email_contact=null"                            
                        />

                        <!--tel contact du client-->
                        <v-text-field dense                           
                            label="Téléphone du contact"
                            name="tel_contact"
                            :disabled="$route.name=='client/show'"
                            v-model="clientModified.tel_contact"                   
                            :error-messages="formError.tel_contact"                            
                            @focus="formError.tel_contact=null"                            
                        />    

                        <!--fonction contact du client-->
                        <v-text-field  dense                          
                            label="Fonction du contact"
                            name="fonction_contact"
                            :disabled="$route.name=='client/show'"                            
                            v-model="clientModified.fonction_contact"                            
                            :error-messages="formError.fonction_contact"                            
                            @focus="formError.fonction_contact=null"                            
                        />   

                </fieldset>                                 

                </v-col>

                <v-col>
                    <fieldset  >
                    <legend>Infos client</legend>
                        <v-row>
                            <v-col >
                                <!--SIREN/SIRET du client-->
                                <v-text-field dense                            
                                    label="SIREN/SIRET"
                                    name="siren_siret"
                                    :disabled="$route.name=='client/show'"
                                    v-model="clientModified.siren_siret"
                                    :error-messages="formError.siren_siret"                            
                                    @focus="formError.siren_siret=null"                                    
                                /> 

                                <!--APE du client-->
                                <v-text-field dense                           
                                    label="APE"
                                    name="ape" 
                                    :disabled="$route.name=='client/show'"                           
                                    v-model="clientModified.ape"
                                    :error-messages="formError.ape"
                                    @focus="formError.ape=null"                                    
                                /> 

                                <!--Siege social du client-->  
                                <v-textarea dense auto-grow
                                    rows="1"
                                    label="Siège social"
                                    :disabled="$route.name=='client/show'"
                                    v-model.trim="clientModified.adresse_siege"                                    
                                /> 

                                <!--Capital social du client-->
                                <v-text-field dense
                                    label="Capital social"
                                    name="capital_social"
                                    :disabled="$route.name=='client/show'"                            
                                    v-model="clientModified.capital_social"                                    
                                    :error-messages="formError.capital_social"                            
                                    @focus="formError.capital_social=null"
                                />                                                                                                                              

                            </v-col>
                            <v-col >

                                <!--Statut juridique du client-->
                                <v-radio-group dense row  hide-details                            
                                    :disabled="$route.name=='client/show'"
                                    v-model="clientModified.statut_juridique"
                                    :error-messages="formError.statut_juridique"
                                    @focus="formError.statut_juridique=null"
                                >
                                
                                <v-radio  label="ASSO" value="ASSO"></v-radio>
                                <v-radio  label="PME" value="PME"></v-radio>
                                <v-radio  label="STARTUP" value="STARTUP"></v-radio>
                                
                                </v-radio-group>


                            <!--Date immat client-->
                            <v-menu max-width="290"
                                v-model="datepickermenu"
                                :close-on-content-click="false"                            
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field clearable readonly dense
                                    label="Date d'immatriculation"
                                    :disabled="$route.name=='client/show'"
                                    :value="computedDateFormattedMomentjs"                                
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="clientModified.date_immat = null"
                                    :error-messages="formError.date_immat"                                    
                                    @focus="formError.date_immat=null"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                locale="fr"
                                v-model="clientModified.date_immat"
                                @change="datepickermenu = false"
                            ></v-date-picker>
                            </v-menu>

                            <!--fiscalite client-->
                            <v-switch  dense
                                :label="clientModified.fiscalite ? 'Fiscalité : oui' :'Fiscalité : non'"
                                :disabled="$route.name=='client/show'"
                                v-model="clientModified.fiscalite"
                            ></v-switch>  

                            <!--eligibilite JEI client-->
                            <v-switch dense
                                :label="clientModified.eligibilite_JEI ? 'Eligibilité JEI : oui':'Eligibilité JEI : non'"
                                :disabled="$route.name=='client/show'"                           
                                v-model="clientModified.eligibilite_JEI"                            
                            ></v-switch>  

                            <!--Solde trésorerie du client-->
                            <!-- 
                                On retire le solde de tréso du client : cf recette dec 2024
                            <v-text-field  dense
                                label="Solde trésorerie"
                                name="solde_tresorerie"
                                :disabled="$route.name=='client/show'"                                                     
                                v-model.number="clientModified.solde_tresorerie"
                                :rules="[rules.numeric]"
                                :error-messages="formError.solde_tresorerie"                            
                                @focus="formError.solde_tresorerie=null"
                            />  -->
                            </v-col>
                        </v-row>                                                                                                                  
                </fieldset>        <!---->              
                    <v-autocomplete clearable multiple chips small-chips deletable-chips 
                                              
                        label="Collaborateur(s) associé(s)"
                        :disabled = this.isUserAffectationDisabled
                        v-model="clientModified.users"
                        :items="collaborateurs"
                        item-value="id"
                        return-object
                    ></v-autocomplete>                            
                
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn color="red" @click.prevent="$router.go(-1)">
              <v-icon left>mdi-arrow-left-circle</v-icon>Retour
            </v-btn>
            <v-spacer />

            <v-btn color="primary" @click.prevent="restore" v-if="$route.name!='client/show'">
              <v-icon left>mdi-history</v-icon>Restaurer
            </v-btn>
            <v-btn color="primary" @click.prevent="submit" :disabled="!valid" v-if="$route.name!='client/show'">
              <v-icon left>mdi-check</v-icon>
              {{submitLabel}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    </v-container>
</template>

<script>
    // Le script
    export default {
        data() {return {
            isCollaborateursLoading:true,
            isClientLoading:true,
            isSaving:false,

            formTitle: null,
            submitLabel: null,

            serverAlert: null,            
            snackbar_text : '', 
            snackbar : false,   
            
            datepickermenu: false,

            client:{
                    denomination_sociale : null,
                    description : null,
                    statut_juridique : 'SARL', //'SARL', 'SA', 'EIRL'
                    date_immat :  null,
                    adresse_siege : null,
                    siren_siret : null,
                    ape : null,
                    capital_social : null,
                    fiscalite : false,
                    eligibilite_JEI : false,
                    solde_tresorerie : 0,
                    nom_contact : null,
                    prenom_contact : null,
                    email_contact : null,
                    tel_contact : null,
                    fonction_contact : null,
                    stade_de_vie : 'projet', //'projet', 'creation', 'dev', 'consolidation'
                    office_id :  null,
                    users : [],
                    can : {
                        modifyUserAffectation : true
                    }
                },

            clientModified: {
                    denomination_sociale : null,
                    description : null,
                    statut_juridique : 'SARL', //'SARL', 'SA', 'EIRL'
                    date_immat :  null,
                    adresse_siege : null,
                    siren_siret : null,
                    ape : null,
                    capital_social : null,
                    fiscalite : false,
                    eligibilite_JEI : false,
                    solde_tresorerie : 0,
                    nom_contact : null,
                    prenom_contact : null,
                    email_contact : null,
                    tel_contact : null,
                    fonction_contact : null,
                    stade_de_vie :'projet',
                    office_id :  null,   
                    users : [],
                    can : {
                        modifyUserAffectation : true
                    }                      
            },

            collaborateurs : [],

            clientModified_saved:false,

            valid: false,

            formError: {
                stade_de_vie: null,
                denomination_sociale: null,
                nom_contact: null,
                prenom_contact: null,
                email_contact: null,
                tel_contact: null,
                fonction_contact: null,
                siren_siret : null,
                ape : null,
                adresse_siege : null,
                capital_social : null,
                statut_juridique : null,
                date_immat : null,
                solde_tresorerie : null,

               
            },

            rules: {
                validEmail: v => (/.+@.+\..+/.test(v) || !v ) || "L'email doit être valide",

                required: v => !!v || "Ce champ est requis",

                numeric: v => !isNaN(v) || "Valeur numérique attendue",

            },            

          

        }},
        computed : {
            isLoading: function(){
                if(this.isSaving ||this.isClientLoading ){ return true}
                return false
            },

            LoggedUser () {
                console.log('computed LoggedUser',this.$store.getters["currentUser/LoggedUser"])
                return this.$store.getters["currentUser/LoggedUser"]
            },
            isLoggedIn (){                
                return this.$store.getters["currentUser/isLoggedIn"]
            },
            computedDateFormattedMomentjs () {
                return this.clientModified.date_immat ? this.$moment(this.clientModified.date_immat).format('DD/MM/YYYY') : ''
            }, 
            isUserAffectationDisabled (){
                if(this.$route.name=='client/show'){return true}
                if(this.clientModified.can.modifyUserAffectation==false){return true}
                return false
            }                       

        },
        watch:{

           LoggedUser : function(){
               this.client.office_id=this.LoggedUser.office_id
               this.clientModified.office_id=this.LoggedUser.office_id
           },
           'clientModified.eligibilite_JEI': function(){
               
               if(this.clientModified.eligibilite_JEI==0){this.clientModified.eligibilite_JEI=false}
               if(this.clientModified.eligibilite_JEI==1){this.clientModified.eligibilite_JEI=true} 
             }, 
            'clientModified.fiscalite': function(){
               
                if(this.clientModified.fiscalite==0){this.clientModified.fiscalite=false}
               if(this.clientModified.fiscalite==1){this.clientModified.fiscalite=true} 
           }            


        },
        methods : {

            /*
             * Liste des clients
             */
            getCollaborateurs(){
                this.isCollaborateursLoading = true
                
                    axios.get('/api/collaborateurlist')
                    .then((response)=>{
                        console.log('/api/collaborateurlist/ OK')
                        this.collaborateurs = response.data.data                   
                        this.isCollaborateursLoading = false                                             
                    })
                    .catch((err)=>{
                        console.log('/api/collaborateurlist/ KO',err)
                        console.log(err)
                        this.isCollaborateursLoading = false                        
                    })
                

            },
            /*
             * get client info
             */            

            getClient(){
                console.log('get client info')
                this.isClientLoading = true
                axios.get('/api/client/'+this.$route.params.clientId+'?from='+this.$route.name )
                .then((response)=>{
                    Object.assign(this.client, response.data)  
                    Object.assign(this.clientModified, response.data)
                    this.isClientLoading = false
                    
                })
                .catch((err)=>{
                    console.log('/api/client/'+this.$route.params.clientId+' KO',err)
                    console.log(err.response)
                    this.isClientLoading = false
                    if (err.response.status == 403) {
                        this.$router.push({name:'unauthorized'})
                    }
                })

            },
            
            restore(){

                console.log('restore')

                if(this.$route.name=='client/create'){

                        this.isClientLoading = false
                        this.clientModified=null;
                        this.clientModified=JSON.parse(JSON.stringify(this.client)); // clone without reference                                    
                }
                else{
                    this.getClient();
                  
                }
            },
            
            submit(){

                console.log('submit clicked');

                this.isSaving = true;
                this.serverAlert= null;
                let url

                let payload = this.clientModified;


                if(this.$route.name=='client/edit'){url="/api/client/edit/"+this.clientModified.id}
                else{url="/api/client/create"}

                axios.get('/sanctum/csrf-cookie').then(response =>{
                axios
                    .post(url, payload)
                    .then(response => {

                        console.log('submit OK',response);
                        
                        this.isSaving = false;
                        this.snackbar= true 
                        this.clientModified_saved = true
                        if(this.$route.name=='client/edit'){this.snackbar_text="Client modifié avec succès"}
                        else{this.snackbar_text="Client ajouté avec succès"}
                        
                        this.restore();                        
                        
                    })
                    .catch(err => {
                    this.serverAlert= true;    
                    this.isSaving = false;
                    console.log("submit_ko", err);

                    if (err.response.status == 422) {
                        Object.assign(this.formError, err.response.data.errors);
                    }

                    this.formError.general = err.response.data.message;

                    });


                })
                return ;

            },            


        },
        mounted(){

            switch (this.$route.name) {

            case 'client/show':

                this.formTitle="Détail du client"
                this.getClient();
                break;

            case 'client/edit':

                this.formTitle="Modification du client"
                this.submitLabel="Mettre à jour"
                this.getClient();
                break;

            case 'client/create':

                this.formTitle="Création du client"
                this.submitLabel="Créer"
                this.clientModified=JSON.parse(JSON.stringify(this.client)); // clone without reference
                this.isClientLoading = false              
                break;

            default:

                console.log("Sorry, we are out of "+$route.name);
            
            }

            this.getCollaborateurs();


        }
    }
</script>
<style>
fieldset {
    border: 1px solid #333333;
    padding: 10px;
    background-color: #191919;
    }
fieldset legend {
    font-size:16px;
    width : auto;
    padding : 0px 8px;
    margin-bottom : 0px;
    color : gray;
} 
.v-input--radio-group label {margin-bottom:0px}

</style>