<!-- Le template -->
<template>
  <v-container :key="componentKey">
    <v-row class="mt-1">
      <v-col cols="12" class="d-flex justify-start align-center">
        <!--Choix du client-->
        <h3 class="mr-3 my-0">Diagnostic de :</h3>
        <v-autocomplete
          class="mr-3 my-0"
          label="Rechercher Sélectionner un client"
          :items="clients"
          clearable
          deletable-chips
          item-value="id"
          return-object
          v-model="selectedClient"
          dense
          :rules="[rules.required]"
        ></v-autocomplete>

        <!--Annee-->
        <v-select
          v-if="!diagnostic_years_loading"
          menu-props="auto"
          class="mr-4 my-0"
          dense
          multiple
          small-chips
          deletable-chips
          @change="get_crs()"
          label="Année de diagnostic"
          v-model="diagnostic_years"
          :items="possibles_diagnostic_years"
          :rules="[rules.required_select]"
        ></v-select>

        <!--Switch K€ -->
        <v-switch
          class="mr-3 my-0"
          dense
          label="K€"
          v-model="kilo_euro"
        ></v-switch>
        <!--Switch % Visibility -->
        <v-switch v-if="activTab == 0 || activTab == 2 || activTab == 4 || activTab == 6"
          class="mr-3 my-0"
          dense
          label="K€"
          v-model="show_pourcent"
        >
        <template v-slot:label>          
          <v-icon v-if="show_pourcent">mdi-eye-outline</v-icon>
          <v-icon v-else>mdi-eye-off-outline</v-icon>%        
        </template>

        </v-switch>
        <!--Switch base % -->
        <v-switch
          v-model="pourcent_PE"
          v-if="activTab == 0 || activTab == 2 || activTab == 4 || activTab == 6" 
          class="mr-3 my-0"
          dense
          label="%PE"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="selectedClient && diagnostic_years.length != 0">
      <v-col>
        <v-tabs v-model="activTab" vertical show-arrows>
          <v-tab v-for="tab in tabs" :key="tab.index">{{ tab.name }}</v-tab>

          <v-tab-item
            v-for="tab in tabs"
            :key="tab.index"
            class="pa-8 custombgcolor"
          >
            <v-row>
              <v-col class="d-flex justify-space-between pb-5 align-center">
                <h6 class="mb-0">{{ tab.name }}</h6>
                <div class="d-flex justify-space-between">
                  <v-btn
                    @click="exportImage(tab)"
                    color="grey darken-3"
                    class="mr-1"
                  >
                    <v-icon dark left>mdi-file-image</v-icon>image
                  </v-btn>

                  <export-excel
                    v-if="!sig_loading && activTab == 0"
                    :data="sigs"
                    name="sig.xls"
                    :fields="set_excelHeaders()"
                  >
                    <v-btn color="grey darken-3 ">
                      <v-icon dark left>mdi-file-export-outline</v-icon>
                      Excel
                    </v-btn>
                  </export-excel>

                  <export-excel
                    v-if="!pb_loading && activTab == 1"
                    :data="pbs"
                    name="produits_par_budget.xls"
                    :fields="set_excelHeaders()"
                  >
                    <v-btn color="grey darken-3">
                      <v-icon dark left>mdi-file-export-outline</v-icon>Excel
                    </v-btn>
                  </export-excel>

                  <export-excel
                    v-if="!cb_loading && activTab == 2"
                    :data="cbs"
                    name="charges_par_budget.xls"
                    :fields="set_excelHeaders()"
                  >
                    <v-btn color="grey darken-3">
                      <v-icon dark left>mdi-file-export-outline</v-icon>
                      Excel
                    </v-btn>
                  </export-excel>

                  <export-excel
                    v-if="!sr_loading && activTab == 3"
                    :data="srs"
                    name="seuil_rentabilite.xls"
                    :fields="set_excelHeaders()"
                  >
                    <v-btn color="grey darken-3">
                      <v-icon dark left>mdi-file-export-outline</v-icon>
                      Excel
                    </v-btn>
                  </export-excel>

                  <export-excel
                    v-if="!cr_loading && activTab == 4"
                    :data="crs"
                    name="compte_de_resultat.xls"
                    :fields="set_excelHeaders()"
                  >
                    <v-btn color="grey darken-3">
                      <v-icon dark left>mdi-file-export-outline</v-icon>
                      Excel
                    </v-btn>
                  </export-excel>

                  <export-excel
                    v-if="!bf_loading && activTab == 5"
                    :data="bfs"
                    name="bilan_fonctionnel.xls"
                    :fields="set_excelHeaders()"
                  >
                    <v-btn color="grey darken-3">
                      <v-icon dark left>mdi-file-export-outline</v-icon>
                      Excel
                    </v-btn>
                  </export-excel>

                  <export-excel
                    v-if="!bd_loading && activTab == 6"
                    :data="bds"
                    name="bilan_detaille.xls"
                    :fields="set_excelHeaders()"
                  >
                    <v-btn color="grey darken-3">
                      <v-icon dark left>mdi-file-export-outline</v-icon>
                      Excel
                    </v-btn>
                  </export-excel>

                  <export-excel
                    v-if="!br_loading && activTab == 7"
                    :data="brs"
                    name="board_ratio.xls"
                    :fields="set_excelHeaders()"
                  >
                    <v-btn color="grey darken-3">
                      <v-icon dark left>mdi-file-export-outline</v-icon>
                      Excel
                    </v-btn>
                  </export-excel>

                  <export-excel
                    v-if="!ff_loading && activTab == 8"
                    :data="ffs"
                    name="flux_financiers.xls"
                    :fields="set_excelHeaders()"
                  >
                    <v-btn color="grey darken-3">
                      <v-icon dark left>mdi-file-export-outline</v-icon>
                      Excel
                    </v-btn>
                  </export-excel>
                </div>
              </v-col>
            </v-row>

            <!--  SOLDE INTERMEDIAIRE DE GESTION  -->
            <div v-if="!GlobalLoading && !sig_loading && activTab == 0">
              <div v-for="annee in diagnostic_years" :key="annee">
                <v-alert dense :type="validSig(annee) ? 'success' : 'error'"
                  ><span v-html="verifSig(annee)"></span
                ></v-alert>
              </div>
            </div>

            <v-progress-linear
              v-if="GlobalLoading && activTab == 0"
              color="light-blue darken-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>

            <!-- SIG Visible Table -->
            <v-simple-table
              v-if="tab.name == 'SIG' && !sig_loading && !GlobalLoading"
              fixed-header
              dark
              dense
              :key="sigDatatableKey"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" rowspan="2" style="padding: 5px">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            @click="toggleAllRowVisibility(selectall_sig, sigs)"
                            v-model="selectall_sig"
                            true-value="1"
                            false-value="0"
                            v-on="on"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span>Tout Masquer (pour l'exportation en image)</span>
                      </v-tooltip>
                    </th>
                    <th class="text-left" rowspan="2">Intitulé</th>
                    <th
                      v-for="annee in diagnostic_years"
                      :key="annee.entete1"
                      class="text-right border-left border-right"                       
                      :colspan="show_pourcent ? 2 : 1"
                    >
                      {{ annee }}
                    </th>
                  </tr>

                  <tr>
                    <template v-for="annee in diagnostic_years">
                      <th
                        :key="annee.entete2"                        
                        :class="show_pourcent ? 'text-right euro_header border-left':'text-right euro_header border-left border-right'"
                      >
                        <span v-if="kilo_euro">K</span>€
                      </th>
                      <th v-if="show_pourcent"
                        :key="annee.entete2"
                        class="text-right pourcent_header  border-right"
                      >
                        %
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(sig, sigindex) in sigs"
                    :key="sigindex"
                    :class="`rang` + sig.rang"
                  >
                    <td class="checkboxTD">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="sigs[sigindex].show"
                            @click="
                              toggleRowVisibility(
                                sigs[sigindex].show,
                                sigs,
                                sigindex
                              )
                            "
                            v-on="on"
                            :input-value="sigs[sigindex].show"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span
                          >Masquer cette ligne pour l'exportation en image</span
                        >
                      </v-tooltip>
                    </td>
                    <td>{{ sig.intitule }}</td>
                    <template v-for="annee in diagnostic_years">
                      <td :key="annee.index" :class="show_pourcent ? 'text-right border-left' : 'text-right border-left border-right'"> <!--class="text-right border-left"-->
                        {{ format(sig[annee], sig) }}
                      </td>
                      <td v-if="show_pourcent" :key="annee.index" class="text-right pourcent">
                        {{ pourcent_sig(sig, annee) }}
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!-- SIG Export image Table    -->
            <div style="position: absolute; z-index: -1">
              <v-simple-table
                v-if="tab.name == 'SIG' && !sig_loading && !GlobalLoading"
                dense
                :id="tab.name"
                class="pb-3 imgtable"
                light
                :key="exportSigDatatableKey"
              >
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #0c6682">
                      <th
                        class="text-left"
                        rowspan="2"
                        style="color: #ffffff !important"
                      >
                        Intitulé
                      </th>
                      <th
                        v-for="annee in diagnostic_years"
                        :key="annee.entete1"
                        class="text-right border-left"
                        :colspan="show_pourcent ? 2 : 1"
                        style="color: #ffffff !important"
                      >
                        {{ annee }}
                      </th>
                    </tr>

                    <tr style="background-color: #0c6682">
                      <template v-for="annee in diagnostic_years">
                        <th
                          :key="annee.entete2"
                          class="text-right euro_header border-left"
                          style="color: #ffffff !important"
                        >
                          <span v-if="kilo_euro">K</span>€
                        </th>
                        <th v-if="show_pourcent"
                          :key="annee.entete2"
                          class="text-right pourcent_header"
                          style="color: #ffffff !important"
                        >
                          %
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(sigitem, sigitemindex) in sigs">
                      <tr
                        v-if="sigs[sigitemindex].show == '1'"
                        :key="sigitemindex"
                        :class="`rang` + sigitem.rang"
                      >
                        <td>{{ sigitem.intitule }}</td>
                        <template v-for="annee in diagnostic_years">
                          <td :key="annee.index" class="text-right border-left">
                            {{ format(sigitem[annee], sigitem) }}
                          </td>
                          <td v-if="show_pourcent" :key="annee.index" class="text-right pourcent">
                            {{ pourcent_sig(sigitem, annee) }}
                          </td>
                        </template>
                      </tr>

                      <tr
                        style="background-color: #ffffff !important"
                        v-if="
                          sigitem.rang == 3 && sigs[sigitemindex].show == '1'
                        "
                        :key="sigitemindex + 'ter1'"
                      >
                        <td style="border-bottom: 0px !important">&nbsp;</td>
                        <template v-for="annee in diagnostic_years">
                          <td
                            :key="annee.index"
                            class="text-right value border-left"
                            style="border-bottom: 0px !important"
                          ></td>
                          <td
                            v-if="show_pourcent"
                            :key="annee.index"
                            class="text-right value"
                            style="border-bottom: 0px !important"
                          ></td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <!--  PRODUITS / BUDGET  -->
            <v-progress-linear
              v-if="activTab == 1 && GlobalLoading"
              color="light-blue darken-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>

            <!-- PB Visible Table -->
            <v-simple-table
              v-if="
                tab.name == 'Produits / Budget' && !pb_loading && !GlobalLoading
              "
              fixed-header
              dark
              dense
              :key="produitsDatatableKey"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="padding: 5px">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            @click="toggleAllRowVisibility(selectall_pb, pbs)"
                            v-model="selectall_pb"
                            true-value="1"
                            false-value="0"
                            v-on="on"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span>Tout Masquer (pour l'exportation en image)</span>
                      </v-tooltip>
                    </th>
                    <th class="text-left">Intitulé</th>
                    <th
                      v-for="annee in diagnostic_years"
                      :key="annee.entete1"
                      class="text-right euro_header border-left"
                    >
                      {{ annee }}<br /><span v-if="kilo_euro">K</span>€
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(pb, pbindex) in pbs"
                    :key="pbindex"
                    :class="`rang` + pb.rang"
                  >
                    <td class="checkboxTD">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            @click="
                              toggleRowVisibility(
                                pbs[pbindex].show,
                                pbs,
                                pbindex
                              )
                            "
                            v-model="pbs[pbindex].show"
                            :input-value="pbs[pbindex].show"
                            true-value="1"
                            false-value="0"
                            v-on="on"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span
                          >Masquer cette ligne pour l'exportation en image</span
                        >
                      </v-tooltip>
                    </td>
                    <td>{{ pb.intitule }}</td>
                    <template v-for="annee in diagnostic_years">
                      <td :key="annee.index" class="text-right border-left">
                        {{ format(pb[annee], pb) }}
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!-- PB Export image Table -->
            <div style="position: absolute; z-index: -1">
              <v-simple-table
                v-if="
                  tab.name == 'Produits / Budget' &&
                  !pb_loading &&
                  !GlobalLoading
                "
                :id="tab.name"
                dense
                class="pb-3 imgtable"
                light
                :key="exportProduitsDatatableKey"
              >
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #0c6682">
                      <th class="text-left" style="color: #ffffff !important">
                        Intitulé
                      </th>
                      <th
                        v-for="annee in diagnostic_years"
                        :key="annee.entete1"
                        class="text-right euro_header border-left"
                        style="color: #ffffff !important"
                      >
                        {{ annee }}<br /><span v-if="kilo_euro">K</span>€
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(pb, pbindex) in pbs">
                      <tr
                        v-if="pbs[pbindex].show == '1'"
                        :key="pbindex"
                        :class="`rang` + pb.rang"
                      >
                        <td>{{ pb.intitule }}</td>
                        <template v-for="annee in diagnostic_years">
                          <td :key="annee.index" class="text-right border-left">
                            {{ format(pb[annee], pb) }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <!-- CHARGES / BUDGET -->

            <v-progress-linear
              v-if="activTab == 2 && GlobalLoading"
              color="light-blue darken-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>

            <!-- CB Visible Table -->
            <v-simple-table
              v-if="
                tab.name == 'Charges / Budget' && !cb_loading && !GlobalLoading
              "
              fixed-header
              dark
              dense
              id="2"
              :key="chargesDatatableKey"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left checkboxTD" rowspan="2">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="selectall_cb"
                            @click="toggleAllRowVisibility(selectall_cb, cbs)"
                            v-on="on"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span>Tout Masquer (pour l'exportation en image)</span>
                      </v-tooltip>
                    </th>
                    <th class="text-left" rowspan="2">Intitulé</th>
                    <th
                      v-for="annee in diagnostic_years"
                      :key="annee.entete1"
                      class="text-right border-left border-right"
                      :colspan="show_pourcent ? 2 : 1"
                    >
                      {{ annee }}
                    </th>
                  </tr>
                  <tr>
                    <template v-for="annee in diagnostic_years">
                      <th
                        :key="annee.entete2"
                        :class="show_pourcent ? 'text-right euro_header border-left':'text-right euro_header border-left border-right' "
                        
                      ><!--class="text-right euro_header border-left"-->
                        <span v-if="kilo_euro">K</span>€
                      </th>
                      <th v-if="show_pourcent"
                        :key="annee.entete2"
                        class="text-right pourcent_header border-right"
                      >
                        %
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(cb, cbindex) in cbs"
                    :key="cbindex"
                    :class="`rang` + cb.rang"
                  >
                    <td class="checkboxTD">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            @click="
                              toggleRowVisibility(
                                cbs[cbindex].show,
                                cbs,
                                cbindex
                              )
                            "
                            v-model="cbs[cbindex].show"
                            :input-value="cbs[cbindex].show"
                            v-on="on"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span
                          >Masquer cette ligne pour l'exportation en image</span
                        >
                      </v-tooltip>
                    </td>
                    <td>
                      <ul v-if="cb.rang == 0">{{cb.intitule}}</ul>
                      <span v-else>{{ cb.intitule }}</span>
                    </td>
                    <template v-for="annee in diagnostic_years">                      
                      
                      <td  :class="show_pourcent ? 'text-right border-left' : 'text-right border-left border-right'"
                       :key="annee.index"><span v-if="cb.rang != 2" >{{ format(cb[annee], cb) }}</span></td>
                      <td v-if="show_pourcent" class="text-right pourcent border-right" :key="annee.index"><span v-if="cb.rang != 2" >{{ pourcent_cb(cb, annee) }}</span></td>
                      

                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!-- CB Export Image table  -->
            <div style="position: absolute; z-index: -1">
              <v-simple-table
                v-if="
                  tab.name == 'Charges / Budget' &&
                  !cb_loading &&
                  !GlobalLoading
                "
                :id="tab.name"
                light
                dense
                class="pb-3 imgtable"
                :key="exportChargesDatatableKey"
              >
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #0c6682">
                      <th
                        class="text-left"
                        rowspan="2"
                        style="color: #ffffff !important"
                      >
                        Intitulé
                      </th>
                      <th
                        v-for="annee in diagnostic_years"
                        :key="annee.entete1"
                        class="text-center border-left"
                        style="color: #ffffff !important"
                        :colspan="show_pourcent ? 2 : 1"
                      >
                        {{ annee }}
                      </th>
                    </tr>
                    <tr style="background-color: #0c6682">
                      <template v-for="annee in diagnostic_years">
                        <th
                          :key="annee.entete2"
                          class="text-right euro_header border-left"
                          style="color: #ffffff !important"
                        >
                          <span v-if="kilo_euro">K</span>€
                        </th>
                        <th v-if="show_pourcent"
                          :key="annee.entete2"
                          class="text-right pourcent_header"
                          style="color: #ffffff !important"
                        >
                          %
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(cb, cbindex) in cbs">
                      <tr
                        v-if="cbs[cbindex].show == '1'"
                        :key="cbindex"
                        :class="`rang` + cb.rang"
                      >
                        <td>{{ cb.intitule }}</td>
                        <template v-for="annee in diagnostic_years">
                          <td :key="annee.index" class="text-right border-left">
                            {{ format(cb[annee], cb) }}
                          </td>
                          <td v-if="show_pourcent" :key="annee.index" class="text-right pourcent">
                            {{ pourcent_cb(cb, annee) }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <!--  Seuil de rentabilité  -->

            <div v-if="!sr_loading && activTab == 3 && !GlobalLoading">
              <div v-for="annee in diagnostic_years" :key="annee">
                <v-alert v-if="!validSR(annee)" dense type="error">
                  Pour l'année {{ annee }} : Impossible de calculer le seuil de
                  rentabilité avec exactitude car la Marge sur coût variable est
                  < 0</v-alert
                >
              </div>
            </div>
            <v-progress-linear
              v-if="activTab == 3 && GlobalLoading"
              color="light-blue darken-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>

            <!-- SR Visible Table -->
            <v-simple-table
              v-if="
                tab.name == 'Seuil de Rentabilité' &&
                !sr_loading &&
                !GlobalLoading
              "
              :key="seuilDatatableKey"
              fixed-header
              dark
              dense
              id="3"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="padding: 5px">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="selectall_sr"
                            @click="toggleAllRowVisibility(selectall_sr, srs)"
                            v-on="on"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span>Tout Masquer (pour l'exportation en image)</span>
                      </v-tooltip>
                    </th>
                    <th class="text-left">Intitulé</th>
                    <th
                      v-for="annee in diagnostic_years"
                      :key="annee.entete1"
                      class="text-right euro_header border-left"
                    >
                      {{ annee }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(sr, srindex) in srs"
                    :key="srindex"
                    :class="`rang` + sr.rang"
                  >
                    <td class="checkboxTD">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="srs[srindex].show"
                            :input-value="srs[srindex].show"
                            true-value="1"
                            false-value="0"
                            @click="
                              toggleRowVisibility(
                                srs[srindex].show,
                                srs,
                                srindex
                              )
                            "
                            v-on="on"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span
                          >Masquer cette ligne pour l'exportation en image</span
                        >
                      </v-tooltip>
                    </td>
                    <td>{{ sr.intitule }}</td>
                    <template v-for="(annee, anneeindex) in diagnostic_years">
                      <td
                        :key="anneeindex"
                        :class="[
                          sr.taux
                            ? 'text-right pourcent border-left'
                            : 'text-right  border-left',
                        ]"
                      >
                        {{ format(sr[annee], sr)
                        }}<span v-if="sr.taux" class="pourcent">%</span>
                        <span v-else-if="kilo_euro">K€</span
                        ><span v-else>€</span>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!-- SR Export Image Table -->

            <div style="position: absolute; z-index: -1">
              <v-simple-table
                :id="tab.name"
                v-if="
                  tab.name == 'Seuil de Rentabilité' &&
                  !sr_loading &&
                  !GlobalLoading
                "
                light
                dense
                class="pb-3 imgtable"
                style="width: auto"
                :key="exportSeuilDatatableKey"
              >
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #0c6682">
                      <th class="text-left" style="color: #ffffff !important">
                        Intitulé
                      </th>
                      <th
                        v-for="annee in diagnostic_years"
                        :key="annee.entete1"
                        class="text-right euro_header border-left"
                        style="color: #ffffff !important"
                      >
                        {{ annee }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(sr, srindex) in srs">
                      <tr
                        v-if="srs[srindex].show == '1'"
                        :key="srindex"
                        :class="`rang` + sr.rang"
                      >
                        <td>{{ sr.intitule }}</td>
                        <!--style="white-space : nowrap;"-->
                        <template
                          v-for="(annee, anneeindex) in diagnostic_years"
                        >
                          <td
                            :key="anneeindex"
                            :class="[
                              sr.taux
                                ? 'text-right pourcent border-left'
                                : 'text-right border-left',
                            ]"
                          >
                            {{ format(sr[annee], sr)
                            }}<span v-if="sr.taux" class="pourcent">%</span
                            ><span v-else-if="kilo_euro">K€</span
                            ><span v-else>€</span>
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <!--  Compte de résultat -->

            <v-progress-linear
              v-if="activTab == 4 && GlobalLoading"
              color="light-blue darken-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>

            <!-- CR Visible Table -->
            <v-simple-table
              v-if="
                tab.name == 'Compte de résultat' &&
                !cr_loading &&
                !GlobalLoading
              "
              fixed-header
              dark
              dense
              id="4"
              :key="resultatDatatableKey"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="padding: 5px" rowspan="2">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="selectall_cr"
                            @click="toggleAllRowVisibility(selectall_cr, crs)"
                            v-on="on"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span>Tout Masquer (pour l'exportation en image)</span>
                      </v-tooltip>
                    </th>
                    <th class="text-left" rowspan="2">Intitulé</th>
                    <th
                      v-for="annee in diagnostic_years"
                      :key="annee.entete1"
                      class="text-right border-left border-right"
                      :colspan="show_pourcent ? 2 : 1"
                    >
                      {{ annee }}
                    </th>
                  </tr>
                  <tr>
                    <template v-for="annee in diagnostic_years">
                      <th
                        :key="annee.entete2"
                        :class="show_pourcent ? 'text-right euro_header border-left':'text-right euro_header border-left border-right' "
                      >
                        <span v-if="kilo_euro">K</span>€
                      </th>
                      <th v-if="show_pourcent"
                        :key="annee.entete2"
                        class="text-right pourcent_header border-right"
                      >
                        %
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(cr, crindex) in crs"
                    :key="crindex"
                    :class="`rang` + cr.rang"
                  >
                    <td class="checkboxTD">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="crs[crindex].show"
                            :input-value="crs[crindex].show"
                            @click="
                              toggleRowVisibility(
                                crs[crindex].show,
                                crs,
                                crindex
                              )
                            "
                            true-value="1"
                            false-value="0"
                            v-on="on"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span
                          >Masquer cette ligne pour l'exportation en image</span
                        >
                      </v-tooltip>
                    </td>
                    <td>
                      <ul v-if="cr.rang == 0">
                        {{
                          cr.intitule
                        }}
                      </ul>
                      <span v-else>{{ cr.intitule }}</span>
                    </td>
                    <template v-for="annee in diagnostic_years">
                      <td
                        :key="annee.index"
                        :class="show_pourcent ? 'value text-right border-left' : 'value text-right border-left border-right'" >
                        
                      <!--class="text-right value border-left"-->
                        {{ format(cr[annee], cr) }}
                      </td>

                      
                      <td v-if="show_pourcent" :key="annee.index" class="text-right pourcent value  border-right">
                        <span v-if="cr.soustotal || cr.category == null">
                          <span v-if="cr.rang == 3"></span>
                          <span v-else>100.0</span>
                        </span>
                        <span v-else>
                          {{ pourcent_cr(cr, annee) }}
                        </span>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!-- CR Export Image Table -->
            <div style="position: absolute; z-index: -1">
              <v-simple-table
                :id="tab.name"
                v-if="
                  tab.name == 'Compte de résultat' &&
                  !cr_loading &&
                  !GlobalLoading
                "
                light
                dense
                class="pb-3 imgtable"
                style="overflow-x: auto !important"
                :key="exportResultatDatatableKey"
              >
                <template v-slot:default>
                  <thead style="overflow-x: auto !important">
                    <tr style="background-color: #0c6682">
                      <th
                        class="text-left"
                        rowspan="2"
                        style="color: #ffffff !important"
                      >
                        Intitulé
                      </th>
                      <th
                        v-for="annee in diagnostic_years"
                        :key="annee.entete1"
                        class="text-right border-left"
                        style="color: #ffffff !important"
                        :colspan="show_pourcent ? 2 : 1"
                      >
                        {{ annee }}
                      </th>
                    </tr>
                    <tr style="background-color: #0c6682">
                      <template v-for="annee in diagnostic_years">
                        <th
                          :key="annee.entete2"
                          class="text-right euro_header border-left"
                          style="color: #ffffff !important"
                        >
                          <span v-if="kilo_euro">K</span>€
                        </th>
                        <th v-if="show_pourcent"
                          :key="annee.entete2"
                          class="text-right pourcent_header"
                          style="color: #ffffff !important"
                        >
                          %
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody style="overflow-x: auto !important">
                    <template v-for="(cr, crindex) in crs">
                      <tr
                        v-if="crs[crindex].show == '1'"
                        :key="crindex"
                        :class="`rang` + cr.rang"
                      >
                        <td>
                          <ul v-if="cr.rang == 0">
                            {{
                              cr.intitule
                            }}
                          </ul>
                          <span v-else>{{ cr.intitule }}</span>
                        </td>
                        <template v-for="annee in diagnostic_years">

                          
                          <td :key="annee.index" :class="show_pourcent ? 'value text-right border-left' : 'value text-right border-left border-right'" >
                            {{ format(cr[annee], cr) }}
                          </td>
                          
                          <td v-if="show_pourcent" :key="annee.index" class="text-right pourcent value  border-right">
                            <span v-if="cr.soustotal || cr.category == null">
                              <span v-if="cr.rang == 3"></span>
                              <span v-else>100.0</span>
                            </span>
                            <span v-else>{{ pourcent_cr(cr, annee) }}</span>
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <!--  Bilan fonctionnel -->
            <div v-if="!bf_loading && activTab == 5 && !GlobalLoading">
              <div v-for="annee in diagnostic_years" :key="annee">
                <v-alert dense :type="validbf(annee) ? 'success' : 'error'"
                  ><span v-html="verifbf(annee)"></span
                ></v-alert>
              </div>
            </div>

            <v-progress-linear
              v-if="activTab == 5 && GlobalLoading"
              color="light-blue darken-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>

            <!-- BF Visible Table -->
            <v-simple-table
              v-if="
                tab.name == 'Bilan fonctionnel' && !bf_loading && !GlobalLoading
              "
              fixed-header
              dark
              dense
              id="5"
              :key="bilanfDatatableKey"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="padding: 5px" rowspan="2">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="selectall_bf"
                            @click="toggleAllRowVisibility(selectall_bf, bfs)"
                            true-value="1"
                            false-value="0"
                            v-on="on"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span>Tout Masquer (pour l'exportation en image)</span>
                      </v-tooltip>
                    </th>
                    <th class="text-left">Intitulé</th>
                    <th
                      v-for="annee in diagnostic_years"
                      :key="annee.entete1"
                      class="text-right euro_header border-left"
                    >
                      {{ annee }}<br />
                      <span v-if="kilo_euro">K</span>€
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(bf, bfindex) in bfs"
                    :key="bfindex"
                    :class="`rang` + bf.rang"
                  >
                    <td class="checkboxTD">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="bfs[bfindex].show"
                            :input-value="bfs[bfindex].show"
                            @click="
                              toggleRowVisibility(
                                bfs[bfindex].show,
                                bfs,
                                bfindex
                              )
                            "
                            v-on="on"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span
                          >Masquer cette ligne pour l'exportation en image</span
                        >
                      </v-tooltip>
                    </td>
                    <td>{{ bf.intitule }}</td>
                    <template v-for="annee in diagnostic_years">
                      <td :key="annee.index" class="text-right border-left">
                        {{ format(bf[annee], bf) }}
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!-- BF Export Image Table -->
            <div style="position: absolute; z-index: -1">
              <v-simple-table
                :id="tab.name"
                v-if="
                  tab.name == 'Bilan fonctionnel' &&
                  !bf_loading &&
                  !GlobalLoading
                "
                light
                dense
                class="pb-3 imgtable"
                :key="exportBilanfDatatableKey"
              >
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #0c6682">
                      <th class="text-left" style="color: #ffffff !important">
                        Intitulé
                      </th>
                      <th
                        class="text-right euro_header border-left"
                        style="color: #ffffff !important"
                        v-for="annee in diagnostic_years"
                        :key="annee.entete1"
                      >
                        {{ annee }}<br /><span v-if="kilo_euro">K</span>€
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(bf, bfindex) in bfs">
                      <tr
                        v-if="bfs[bfindex].show == '1'"
                        :key="bfindex"
                        :class="`rang` + bf.rang"
                      >
                        <td>{{ bf.intitule }}</td>
                        <template v-for="annee in diagnostic_years">
                          <td :key="annee.index" class="text-right border-left">
                            {{ format(bf[annee], bf) }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <!--  BILAN DETAILLE -->

            <div v-if="!bd_loading && activTab == 6 && !GlobalLoading">
              <div v-for="annee in diagnostic_years" :key="annee">
                <v-alert dense :type="validBd(annee) ? 'success' : 'error'"
                  ><span v-html="verifBd(annee)"></span
                ></v-alert>
              </div>
            </div>

            <v-progress-linear
              v-if="activTab == 6 && GlobalLoading"
              color="light-blue darken-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>

            <!-- BD Visible Table -->
            <v-simple-table
              v-if="
                tab.name == 'Bilan détaillé' && !bd_loading && !GlobalLoading
              "
              fixed-header
              dark
              dense
              id="6"
              :key="bilandDatatableKey"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left checkboxTD" rowspan="2">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="selectall_bd"
                            @click="toggleAllRowVisibility(selectall_bd, bds)"
                            v-on="on"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span>Tout Masquer (pour l'exportation en image)</span>
                      </v-tooltip>
                    </th>
                    <th class="text-left" rowspan="2">Intitulé</th>
                    <th
                      v-for="annee in diagnostic_years"
                      :key="annee.entete1"
                      class="text-right border-left border-right"
                      :colspan="show_pourcent ? 2 : 1"
                    >
                      {{ annee }}
                    </th>
                  </tr>
                  <tr>
                    <template v-for="annee in diagnostic_years">
                      <th
                        :key="annee.entete2"
                        :class="show_pourcent ? 'text-right euro_header border-left' : 'text-right euro_header border-left border-right' ">
                        <span v-if="kilo_euro">K</span>€
                      </th>
                      <th v-if="show_pourcent"
                        :key="annee.entete2"
                        class="text-right pourcent_header border-right"
                      >
                        %
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(bd, bdindex) in bds"
                    :key="bdindex"
                    :class="`rang` + bd.rang"
                  >
                    <td class="checkboxTD">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="bds[bdindex].show"
                            :input-value="bds[bdindex].show"
                            @click="
                              toggleRowVisibility(
                                bds[bdindex].show,
                                bds,
                                bdindex
                              )
                            "
                            v-on="on"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span
                          >Masquer cette ligne pour l'exportation en image</span
                        >
                      </v-tooltip>
                    </td>
                    <td>
                      <ul v-if="bd.rang == 0">
                        {{
                          bd.intitule
                        }}
                      </ul>
                      <span v-else>{{ bd.intitule }}</span>
                    </td>
                    <template v-for="annee in diagnostic_years">

                      <td :key="annee.index" :class="show_pourcent ? 'text-right value border-left':'text-right value border-right border-left' ">
                        <span v-if="bd.rang != 4">{{format(bd[annee], bd)}}</span>
                      </td>

                      <td v-if="show_pourcent" :key="annee.index" class="text-right pourcent value">
                        <span v-if="bd.soustotal || bd.category == null">
                        <span span v-if="bd.rang == 3 || bd.rang == 4"></span>
                          <span v-else>100.0</span>
                        </span>
                        <span v-else>
                          <span v-if="bd.rang != 4">{{pourcent_bd(bd, annee)}}</span>
                        </span>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!-- BD Export Image Table  -->

            <div style="position: absolute; z-index: -1">
              <v-simple-table
                v-if="
                  tab.name == 'Bilan détaillé' && !bd_loading && !GlobalLoading
                "
                :id="tab.name"
                light
                dense
                class="pb-3 imgtable"
                :key="exportBilandDatatableKey"
              >
                <template v-slot:default>                  
                  <tbody>
                    <template v-for="(bd, bdindex) in bds">
                      <tr
                        :key="bdindex"
                        v-if="bd.rang == 4 && bd.show == 1"
                        style="background-color: #0c6682"
                      >
                        <th
                          class="text-left"
                          rowspan="2"
                          style="color: #ffffff"
                        >
                          {{ bd.intitule }}
                        </th>
                        <th
                          v-for="annee in diagnostic_years"
                          :key="annee.entete1"
                          class="text-right border-left"
                          style="color: #ffffff"
                          :colspan="show_pourcent ? 2 : 1"
                        >
                          {{ annee }}
                        </th>
                      </tr>
                      <tr
                        :key="bdindex + 'bis'"
                        style="background-color: #0c6682"
                        v-if="bd.rang == 4 && bd.show == 1"
                      >
                        <template v-for="annee in diagnostic_years">
                          <th
                            :key="annee.entete2"
                            class="text-right border-left euro_header"
                            style="color: #ffffff"
                          >
                            <span v-if="kilo_euro">K</span>€
                          </th>
                          <th  v-if="show_pourcent"
                            :key="annee.entete2"
                            class="text-right pourcent_header"
                            style="color: #ffffff"
                          >
                            %
                          </th>
                        </template>
                      </tr>

                      <tr
                        v-if="bds[bdindex].show == '1' && bd.rang != 4"
                        :key="bdindex + 'ter'"
                        :class="`rang` + bd.rang"
                      >
                        <td>
                          <ul v-if="bd.rang == 0">{{bd.intitule}}</ul>
                          <span v-else>{{ bd.intitule }}</span>
                        </td>
                        <template v-for="annee in diagnostic_years">

                          <td :key="annee.index" class="text-right value border-left" >
                            <span v-if="bd.rang != 4">{{format(bd[annee], bd)}}</span>
                          </td>

                          <td v-if="show_pourcent" :key="annee.index" class="text-right pourcent value">
                            <span v-if="bd.soustotal || bd.category == null" >
                              <span v-if="bd.rang == 3 || bd.rang == 4"></span>
                              <span v-else>100.0</span>
                            </span>
                            <span v-else>
                              <span v-if="bd.rang != 4">{{pourcent_bd(bd, annee)}}</span>
                            </span>
                          </td>


                          <!-- <td :key="annee.index" class="text-right value border-left" >
                            <span v-if="bd.rang != 4">{{format(bd[annee], bd)}}</span>
                          </td>
                          <td v-if="bd.soustotal || bd.category == null" :key="annee.index" class="text-right pourcent value">
                            <span v-if="bd.rang == 3 || bd.rang == 4"></span>
                            <span v-else>100.0</span>
                          </td>
                          <td v-else :key="annee.index" class="text-right pourcent value" >
                            <span v-if="bd.rang != 4">{{pourcent_bd(bd, annee)}}</span>
                          </td> -->


                        </template>
                      </tr>
                      <tr
                        style="background-color: #ffffff !important"
                        v-if="bd.rang == 3 && bds[bdindex].show == '1'"
                        :key="bdindex + 'ter1'"
                      >
                        <td style="border-bottom: 0px !important">&nbsp;</td>
                        <template v-for="annee in diagnostic_years">
                          <td
                            :key="annee.index"
                            class="text-right value border-left"
                            style="border-bottom: 0px !important"
                          ></td>
                          <td v-if="show_pourcent"
                            :key="annee.index"
                            class="text-right value"
                            style="border-bottom: 0px !important"
                          ></td>
                        </template>
                      </tr>
                      <tr
                        style="background-color: #ffffff !important"
                        v-if="bd.rang == 3 && bds[bdindex].show == '1'"
                        :key="bdindex + 'ter2'"
                      >
                        <td style="border-bottom: 0px !important">&nbsp;</td>
                        <template v-for="annee in diagnostic_years">
                          <td
                            :key="annee.index"
                            class="text-right value"
                            style="border-bottom: 0px !important"
                          ></td>
                          <td v-if="show_pourcent"
                            :key="annee.index"
                            class="text-right value"
                            style="border-bottom: 0px !important"
                          ></td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <!--  Board ratio -->
            <v-progress-linear
              v-if="activTab == 7 && GlobalLoading"
              color="light-blue darken-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>

            <!-- BR Visible Table -->
            <v-simple-table
              v-if="tab.name == 'Board Ratio' && !br_loading && !GlobalLoading"
              id="7"
              fixed-header
              dark
              dense
              :key="ratioDatatableKey"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="padding: 5px">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="selectall_br"
                            @click="toggleAllRowVisibility(selectall_br, brs)"
                            true-value="1"
                            false-value="0"
                            v-on="on"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span>Tout Masquer (pour l'exportation en image)</span>
                      </v-tooltip>
                    </th>
                    <th class="text-left">Info Calcul</th>
                    <th class="text-left">Intitulé</th>
                    <th
                      v-for="annee in diagnostic_years"
                      :key="annee.entete1"
                      class="text-right br_header border-left"
                    >
                      {{ annee }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(br, brindex) in brs"
                    :key="brindex"
                    :class="`rang` + br.rang"
                  >
                    <td class="checkboxTD ">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="brs[brindex].show"
                            :input-value="brs[brindex].show"
                            @click="
                              toggleRowVisibility(
                                brs[brindex].show,
                                brs,
                                brindex
                              )
                            "
                            v-on="on"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span
                          >Masquer cette ligne pour l'exportation en image</span
                        >
                      </v-tooltip>
                   
                    </td>
                    <td>                      <v-tooltip
                                top
                                content-class="custom-tooltip"
                                max-width="80%"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon dark v-bind="attrs" v-on="on"
                                    ><v-icon color="grey lighten-1"
                                      >mdi-information</v-icon
                                    ></v-btn
                                  >
                                </template>
                                
                                <span>
                                  <span>Formule de calcul :</span
                                  ><br />
                                  <span
                                    v-html="br.calcul"
                                  ></span>
                                </span>
                              </v-tooltip>   </td>
                    <td>{{ br.intitule }}</td>
                    <template v-for="annee in diagnostic_years">
                      <td :key="annee.index" class="text-right border-left">
                        <span
                          v-if="br.rang != 2"
                          v-html="format(br[annee], br)"
                          :class="[
                            br.taux || br.sans_unite || br.jour || br.annee
                              ? 'pourcent'
                              : '',
                          ]"
                        ></span>
                        <span
                          v-if="br.rang != 2 && !isNaN(parseFloat(br[annee]))"
                        >
                          <span
                            v-if="br.taux"
                            :class="[
                              br.taux || br.sans_unite || br.jour || br.annee
                                ? 'pourcent'
                                : '',
                            ]"
                            >%</span
                          >
                          <span v-else-if="br.sans_unite"></span>
                          <span
                            v-else-if="br.jour"
                            :class="[
                              br.taux || br.sans_unite || br.jour || br.annee
                                ? 'pourcent'
                                : '',
                            ]"
                            >Jour(s)</span
                          >
                          <span
                            v-else-if="br.annee"
                            :class="[
                              br.taux || br.sans_unite || br.jour || br.annee
                                ? 'pourcent'
                                : '',
                            ]"
                            >Annee(s)</span
                          >
                          <span v-else-if="kilo_euro">K€</span>
                          <span v-else>€</span>
                        </span>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!-- BR Export Image Table -->
            <div style="position: absolute; z-index: -1">
              <v-simple-table
                :id="tab.name"
                v-if="
                  tab.name == 'Board Ratio' && !br_loading && !GlobalLoading
                "
                light
                dense
                class="pb-3 imgtable"
                :key="exportRatioDatatableKey"
              >
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #0c6682">
                      <th class="text-left" style="color: #ffffff !important">
                        Intitulé
                      </th>
                      <th
                        v-for="annee in diagnostic_years"
                        :key="annee.entete1"
                        class="text-right br_header border-left"
                        style="color: #ffffff !important"
                      >
                        {{ annee }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(br, brindex) in brs">
                      <tr
                        v-if="brs[brindex].show == '1'"
                        :key="brindex"
                        :class="`rang` + br.rang"
                      >
                        <td>{{ br.intitule }}</td>
                        <template v-for="annee in diagnostic_years">
                          <td :key="annee.index" class="text-right border-left">
                            <span
                              v-if="br.rang != 2"
                              v-html="format(br[annee], br)"
                              :class="[
                                br.taux || br.sans_unite || br.jour || br.annee
                                  ? 'pourcent'
                                  : '',
                              ]"
                            ></span>
                            <span
                              v-if="
                                br.rang != 2 && !isNaN(parseFloat(br[annee]))
                              "
                            >
                              <span
                                v-if="br.taux"
                                :class="[
                                  br.taux ||
                                  br.sans_unite ||
                                  br.jour ||
                                  br.annee
                                    ? 'pourcent'
                                    : '',
                                ]"
                                >%</span
                              >
                              <span
                                v-else-if="br.jour"
                                :class="[
                                  br.taux ||
                                  br.sans_unite ||
                                  br.jour ||
                                  br.annee
                                    ? 'pourcent'
                                    : '',
                                ]"
                                >Jour(s)</span
                              >
                              <span
                                v-else-if="br.annee"
                                :class="[
                                  br.taux ||
                                  br.sans_unite ||
                                  br.jour ||
                                  br.annee
                                    ? 'pourcent'
                                    : '',
                                ]"
                                >Annee(s)</span
                              >
                              <span v-else-if="kilo_euro">K€</span>
                              <span v-else>€</span>
                            </span>
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <!--  Flux financier  -->

            <div v-if="!ff_loading && activTab == 8 && !GlobalLoading">
              <div v-for="annee in diagnostic_years" :key="annee">
                <v-alert dense :type="validFf(annee) ? 'success' : 'error'"
                  ><span v-html="verifFf(annee)"></span
                ></v-alert>
              </div>
            </div>

            <v-progress-linear
              v-if="activTab == 8 && GlobalLoading"
              color="light-blue darken-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>

            <!-- FF Visible Table -->
            <v-simple-table
              id="8"
              v-if="
                tab.name == 'Flux financiers' && !br_loading && !GlobalLoading
              "
              fixed-header
              dark
              dense
              :key="fluxDatatableKey"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="padding: 5px">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="selectall_ff"
                            @click="toggleAllRowVisibility(selectall_ff, ffs)"
                            v-on="on"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span>Tout Masquer (pour l'exportation en image)</span>
                      </v-tooltip>
                    </th>
                    <th class="text-left">Intitulé</th>
                    <th
                      class="text-right euro_header border-left"
                      v-for="annee in diagnostic_years"
                      :key="annee.entete1"
                    >
                      {{ annee }}<br />
                      <span v-if="kilo_euro">K</span>€
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(ff, ffindex) in ffs"
                    :key="ffindex"
                    :class="`rang` + ff.rang"
                  >
                    <td class="checkboxTD">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="ffs[ffindex].show"
                            :input-value="ffs[ffindex].show"
                            @click="
                              toggleRowVisibility(
                                ffs[ffindex].show,
                                ffs,
                                ffindex
                              )
                            "
                            v-on="on"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span
                          >Masquer cette ligne pour l'exportation en image</span
                        >
                      </v-tooltip>
                    </td>
                    <td>{{ ff.intitule }}</td>
                    <template v-for="annee in diagnostic_years">
                      <td :key="annee.index" class="text-right border-left">
                        <span v-html="format(ff[annee], ff)"></span>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!-- FF Export Image Table -->
            <div style="position: absolute; z-index: -1">
              <v-simple-table
                :id="tab.name"
                v-if="
                  tab.name == 'Flux financiers' && !br_loading && !GlobalLoading
                "
                light
                dense
                class="pb-3 imgtable"
                :key="exportFluxDatatableKey"
              >
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #0c6682">
                      <th class="text-left" style="color: #ffffff !important">
                        Intitulé
                      </th>
                      <th
                        v-for="annee in diagnostic_years"
                        :key="annee.entete1"
                        class="text-right euro_header border-left"
                        style="color: #ffffff !important"
                      >
                        {{ annee }}<br />
                        <span v-if="kilo_euro">K</span>€
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(ff, ffindex) in ffs">
                      <tr
                        v-if="ffs[ffindex].show == '1'"
                        :key="ffindex"
                        :class="`rang` + ff.rang"
                      >
                        <td>{{ ff.intitule }}</td>
                        <template v-for="annee in diagnostic_years">
                          <td :key="annee.index" class="text-right border-left">
                            <span v-html="format(ff[annee], ff)"></span>
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row><v-col></v-col></v-row>
  </v-container>
</template>

<style>
.imgtable td,
.imgtable th {
  height: auto !important;
  line-height: 1.3 !important;
}
.imgtable .rang4 td {
  padding: 8px !important;
}

/* 
alternance gris / blanc pour l'export en image des tableaux
tout en blanc cf recette 14/12

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
*/

.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}

/* .rang0 {font-weight: 200;} */

.rang0 ul {
  margin-bottom: 0px !important;
  font-size: 0.9em !important;
}

.rang0 .value {
  font-size: 0.9em !important;
}

.pourcent {
  font-style: italic;
}

.rang2 {
  background-color: #a6a6a6 !important;
}

.rang3 {
  background-color: #1ab7ea !important;
}

.rang4 {
  background-color: #0c6682 !important;
}

.rang4 td {
  padding: 20px !important;
}

.checkboxTD {
  width: 10px !important;
  padding: 5px 0 5px 5px !important;
}
.imgtable .rang4,
.imgtable .rang3 {
  color: #ffffff;
  font-weight: 400;
}
.imgtable .rang2 {
  color: #000000;
  font-weight: 400;
}
.br_header {
  width: 220px;
}
.euro_header {
  width: 100px;
}
.pourcent_header {
  width: 75px;
}

.border-left {
  border-left: 1px solid gray;
}
</style>

<script>
import { EventBus } from "../../../EventBus";

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

// Le script
export default {
  data() {
    return {
      rules: {
        required: (v) => !!v || "Ce champ est requis",
        required_select: (v) => v.length > 0 || "Ce champ est requis",
      },

      sigDatatableKey: 0,
      produitsDatatableKey: 0,
      chargesDatatableKey: 0,
      seuilDatatableKey: 0,
      resultatDatatableKey: 0,
      bilanfDatatableKey: 0,
      bilandDatatableKey: 0,
      ratioDatatableKey: 0,
      fluxDatatableKey: 0,

      exportSigDatatableKey: 0,
      exportProduitsDatatableKey: 0,
      exportChargesDatatableKey: 0,
      exportSeuilDatatableKey: 0,
      exportResultatDatatableKey: 0,
      exportBilanfDatatableKey: 0,
      exportBilandDatatableKey: 0,
      exportRatioDatatableKey: 0,
      exportFluxDatatableKey: 0,

      componentKey: 0,

      GlobalLoading: true,

      Loading: true,
      cr_loading: true,
      bd_loading: true,
      sig_loading: true,
      pb_loading: true,
      cb_loading: true,
      sr_loading: true,
      bf_loading: true,
      br_loading: true,
      ff_loading: true,

      diagnostic_years_loading: true,
      ClientsLoading: true,
      selectedClient: null,
      clients: [],
      possibles_diagnostic_years: [],
      diagnostic_years: [],
      kilo_euro: false,
      pourcent_PE: false,
      show_pourcent:true, 

      activTab: 0,

      tabs: [
        { index: 0, name: "SIG" },
        { index: 1, name: "Produits / Budget" },
        { index: 2, name: "Charges / Budget" },
        { index: 3, name: "Seuil de Rentabilité" },
        { index: 4, name: "Compte de résultat" },
        { index: 5, name: "Bilan fonctionnel" },
        { index: 6, name: "Bilan détaillé" },
        { index: 7, name: "Board Ratio" },
        { index: 8, name: "Flux financiers" },
      ],

      ffs: [], // flux financier

      crs: [], // compte de résultat par année

      bds: [], // bilan detaillé

      selectall_ff: "1",
      selectall_br: "1",
      selectall_bd: "1",
      selectall_bf: "1",
      selectall_cr: "1",
      selectall_sr: "1",
      selectall_sig: "1",
      selectall_pb: "1",
      selectall_cb: "1",

      // solde intermédiaire de gestion
      sigs: [
        {
          intitule: "Chiffre d'affaires",
          rang: 2,

          ajouter: [
            { base: "crs", intitule: "Ventes" },
            { base: "crs", intitule: "Prestations de services" },
          ],
        },

        {
          intitule: "Production stockée / immobilisée",
          rang: 1,

          ajouter: [
            { base: "crs", intitule: "Production stockée" },
            { base: "crs", intitule: "Production immobilisée" },
          ],
        },
        {
          intitule: "Achat consommé", //Achat consommé dont 604 achat études et prestation de service" label modifié cf mail du 12/04/2023,
          rang: 1,

          ajouter: [
            { base: "crs", intitule: "Achats" },
            { base: "crs", intitule: "Variation de stocks" },
          ],
        },
        {
          intitule: "Marge brute",
          rang: 2,

          ajouter: [
            { base: "sigs", intitule: "Chiffre d'affaires" },
            {
              base: "sigs",
              intitule: "Production stockée / immobilisée",
            },
          ],
          soustraire: [
            {
              base: "sigs",
              intitule: "Achat consommé", //Achat consommé dont 604 achat études et prestation de service", modif label cf mail du 12/04/2023
            },
          ],
        },
        {
          intitule: "Autres achats et charges externes",
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule: "Autres achats et charges externes",
            },
          ],
        },
        {
          intitule: "Valeur Ajoutée",
          rang: 2,

          ajouter: [{ base: "sigs", intitule: "Marge brute" }],
          soustraire: [
            {
              base: "sigs",
              intitule: "Autres achats et charges externes",
            },
          ],
        },
        {
          intitule: "Subvention d'exploitation",
          rang: 1,

          ajouter: [
            { base: "crs", intitule: "Subvention d'exploitation" },
            {
              base: "crs",
              intitule: "Reprise des fonds dédiés de N-1",
            },
            {
              base: "crs",
              intitule: "Correction fonds dédiés sur Subventions",
            },
          ],
        },
        {
          intitule: "Frais de personnel",
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule: "Salaires et traitements bruts",
            },
            {
              base: "crs",
              intitule: "Cotisations sociales patronales",
            },
          ],
        },
        {
          intitule: "Impôts, taxes et versement assimilés",
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule: "Impôt, taxes etr versements assimilés",
            },
          ],
        },
        {
          intitule: "Transfert de charges",
          rang: 1,

          ajouter: [{ base: "crs", intitule: "Transferts de charges" }],
        },
        {
          intitule: "Excédent brut d'exploitation",
          rang: 2,

          ajouter: [
            { base: "sigs", intitule: "Valeur Ajoutée" },
            { base: "sigs", intitule: "Subvention d'exploitation" },
            { base: "sigs", intitule: "Transfert de charges" },
          ],
          soustraire: [
            { base: "sigs", intitule: "Frais de personnel" },
            {
              base: "sigs",
              intitule: "Impôts, taxes et versement assimilés",
            },
          ],
        },
        {
          intitule: "Autres charges & produits de gestion courante",
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule: "Autres produits de gestion courante",
            },
          ],
          soustraire: [
            {
              base: "crs",
              intitule: "Autres charges de gestion courante",
            },
          ],
        },
        {
          intitule: "DNAP exploitation", // Dotations nettes aux amortissements et provisions (DNAP) exploitation", modif cf mail 12/042023
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule: "Reprise sur amort. et provisions exploitation",
            },
          ],
          soustraire: [
            {
              base: "crs",
              intitule:
                "Dotations aux amortissments et provisions d'exploitation",
            },
          ],
        },
        {
          intitule: "Résultat d'exploitation",
          rang: 2,

          ajouter: [
            {
              base: "sigs",
              intitule: "Excédent brut d'exploitation",
            },
            {
              base: "sigs",
              intitule: "Autres charges & produits de gestion courante",
            },
            {
              base: "sigs",
              intitule: "DNAP exploitation", //"Dotations nettes aux amortissements et provisions (DNAP) exploitation", cf mail du 12/04
            },
          ],
        },
        {
          intitule: "Résultat financier hors DNAP financiers", // "Résultat financier hors dotations nettes aux amortissements et provisions financiers", cf mail du 12/04
          rang: 1,

          ajouter: [{ base: "crs", intitule: "Autres produits financiers" }],
          soustraire: [
            { base: "crs", intitule: "Intérêts d'emprunts" },
            { base: "crs", intitule: "Autres charges financières" },
          ],
        },
        {
          intitule: "DNAP financiers", // "Dotations nettes aux amortissements et provisions financiers", cf mail du  12/04
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule: "Reprises sur amortissements et provisions financières",
            },
          ],
          soustraire: [
            {
              base: "crs",
              intitule:
                "Dotations aux amortissements et provisions financières",
            },
          ],
        },
        {
          intitule: "Résultat courant",
          rang: 2,

          ajouter: [
            { base: "sigs", intitule: "Résultat d'exploitation" },
            {
              base: "sigs",
              intitule: "Résultat financier hors DNAP financiers", //"Résultat financier hors dotations nettes aux amortissements et provisions financiers", cf mail du 12/04
            },
            {
              base: "sigs",
              intitule: "DNAP financiers", // Dotations nettes aux amortissements et provisions financiers", cf mail du 12/04
            },
          ],
        },
        {
          intitule: "Résultat exceptionnel hors DNAP exceptionnel", //Résultat exceptionnel hors dotations nettes aux amortissements et provisions exceptionnel", cf mail du 12/04
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule: "Q/P de subv. Invt virée au résultat",
            },
            {
              base: "crs",
              intitule: "Produits de cession des éléments d'actif",
            },
            {
              base: "crs",
              intitule: "Autres produits exceptionnels",
            },
          ],
          soustraire: [
            {
              base: "crs",
              intitule: "Valeurs comptables des éléments d'actif cédés",
            },
            {
              base: "crs",
              intitule: "Autres charges exceptionnelles",
            },
          ],
        },
        {
          intitule: "DNAP exceptionnel", //"Dotations nettes aux amortissements et provisions exceptionnel",cf mail du 12/04
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule:
                "Reprise sur amortissements et provisions exceptionnelles",
            },
          ],
          soustraire: [
            {
              base: "crs",
              intitule:
                "Dotations aux amortissements et provisions exceptionnelles",
            },
          ],
        },
        {
          intitule: "Participation des salariés",
          rang: 1,

          ajouter: [{ base: "crs", intitule: "Participation des salariés" }],
        },
        {
          intitule: "Impôt sur les sociétés",
          rang: 1,

          ajouter: [{ base: "crs", intitule: "Impôts sur les sociétés" }],
          soustraire: [{ base: "crs", intitule: "Crédit d'impôts" }],
        },
        {
          intitule: "Résultat Net",
          rang: 3,

          ajouter: [
            { base: "sigs", intitule: "Résultat courant" },
            {
              base: "sigs",
              intitule: "Résultat exceptionnel hors DNAP exceptionnel", //"Résultat exceptionnel hors dotations nettes aux amortissements et provisions exceptionnel", cf mail du 12/04
            },
            {
              base: "sigs",
              intitule: "DNAP exceptionnel", //"Dotations nettes aux amortissements et provisions exceptionnel", cf mail du 12/04
            },
          ],
          soustraire: [{ base: "sigs", intitule: "Impôt sur les sociétés" }],
        },
        {
          intitule: "Capacité d’autofinancement",
          rang: 3,

          ajouter: [
            { base: "crs", intitule: "RESULTAT NET" },
            {
              base: "crs",
              intitule:
                "Dotations aux amortissments et provisions d'exploitation",
            }, //0
            {
              base: "crs",
              intitule:
                "Dotations aux amortissements et provisions financières",
            }, //0
            {
              base: "crs",
              intitule: "DNAP exceptionnel", // "Dotations nettes aux amortissements et provisions exceptionnel", cf mail du 12/04
            }, // "Dotations aux amortissements et provisions exceptionnelles"
            {
              base: "crs",
              intitule: "Valeurs comptables des éléments d'actif cédés",
            },
            { // ajout suite à modification formule cf recette du 11/07/2024
              base: "crs",
              intitule:"Correction fonds dédiés sur Subventions"
            }
          ],
          soustraire: [
            {
              base: "crs",
              intitule: "Reprise sur amort. et provisions exploitation",
            }, //0
            {
              base: "crs",
              intitule: "Reprises sur amortissements et provisions financières",
            }, //0
            {
              base: "crs",
              intitule:
                "Reprise sur amortissements et provisions exceptionnelles",
            }, //0
            {
              base: "crs",
              intitule: "Produits de cession des éléments d'actif",
            }, //0
            {
              base: "crs",
              intitule: "Q/P de subv. Invt virée au résultat",
            }, //0
            { // soustraction suite à modification formule cf recette du 11/07/2024
              base: "crs",
              intitule:"Reprise des fonds dédiés de N-1"
            }            
          ],
        },
      ],

      //produits par budget
      pbs: [
        {
          intitule: "Chiffre d'affaires",
          rang: 1,

          ajouter: [
            { base: "crs", intitule: "Ventes" },
            { base: "crs", intitule: "Prestations de services" },
          ],
        },
        {
          intitule: "Subventions",
          rang: 1,

          ajouter: [
            { base: "crs", intitule: "Subvention d'exploitation" },
            {
              base: "crs",
              intitule: "Reprise des fonds dédiés de N-1",
            },
          ],
          soustraire: [
            {
              base: "crs",
              intitule: "Correction fonds dédiés sur Subventions",
            },
          ],
        },
        {
          intitule: "Autres produits d'exploitation",
          rang: 1,

          ajouter: [{ base: "crs", intitule: "PRODUITS D'EXPLOITATION" }],
          soustraire: [
            { base: "sigs", intitule: "Chiffre d'affaires" },
            { base: "pbs", intitule: "Subventions" },
          ],
        },
      ],

      //charges / budget
      cbs: [
        {
          intitule: "Budgets détaillés de fonctionnement à engager",
          rang: 2,
        },

        {
          intitule: "Budget Achats & Transports",
          rang: 1,

          ajouter: [
            { base: "crs", intitule: "Achats" },
            { base: "crs", intitule: "Variation de stocks" },
            {
              base: "crs",
              intitule:
                "Transports de biens et transports collectifs du personnel",
            },
          ],
        },

        {
          intitule: "Budget matériel et fourniture",
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule: "Achats de matériel, équipements et travaux",
            }, // Achat de matériel = Achats de matériel, équipements et travaux ???
            {
              base: "crs",
              intitule: "Fournitures non stockables (eau, energie,...)",
            },
            {
              base: "crs",
              intitule: "Fournitures d'entretien et de petit équipement",
            },
            {
              base: "crs",
              intitule: "Fournitures administratives",
            },
            {
              base: "crs",
              intitule: "Autres matières et fournitures",
            },
          ],
        },

        {
          intitule: "Budget sous-traitance",
          rang: 1,

          ajouter: [{ base: "crs", intitule: "Sous-traitance générale" }],
        },

        {
          intitule:
            "Budget rémunération d'intermédiaires & personnel ext à l'entreprise",
          rang: 1,

          ajouter: [
            { base: "crs", intitule: "Etudes et recherches" },
            {
              base: "crs",
              intitule: "Rémunérations d'intermédiaires et honoraires",
            },
            {
              base: "crs",
              intitule: "Personnel extérieur à l'entreprise",
            },
          ],
        },

        {
          intitule: "Budget marketing / développement",
          rang: 1,

          ajouter: [
            { base: "crs", intitule: "Annonces et insertions" },
            { base: "crs", intitule: "Foires et expositions" },
            {
              base: "crs",
              intitule: "Publicité, publications, relations publiques",
            },
            {
              base: "crs",
              intitule: "Documentation / frais de colloques",
            },
          ],
        },

        {
          intitule: "Budget déplacement / mission",
          rang: 1,

          ajouter: [
            { base: "crs", intitule: "Frais de déplacements" },
            {
              base: "crs",
              intitule: "Frais de missions et réceptions",
            },
          ],
        },

        {
          intitule: "Budget loyer/charges immobilières",
          rang: 1,

          ajouter: [
            { base: "crs", intitule: "Locations immobilières" },
            {
              base: "crs",
              intitule: "Charges locatives et de copropriété mobilières",
            }, // Charges locatives et de copropriété = Charges locatives et de copropriété mobilières ?
          ],
        },

        {
          intitule: "Budget Maintenance / entretien",
          rang: 1,

          ajouter: [{ base: "crs", intitule: "Entretien et réparations" }],
        },
        {
          intitule: "Budget frais généraux",
          rang: 1,

          ajouter: [
            { base: "crs", intitule: "Primes d'assurances" },
            {
              base: "crs",
              intitule:
                "Rabais, remises et ristournes obtenus sur services extérieurs",
            },
            {
              base: "crs",
              intitule: "Frais postaux et de télécommunications",
            },
            {
              base: "crs",
              intitule: "Services bancaires et assimilés",
            },
            { base: "crs", intitule: "Divers" },
            {
              base: "crs",
              intitule:
                "Rabais, remises et ristournes obtenus sur autres services extérieurs",
            },
            { base: "crs", intitule: "Redevances de crédit-bail" },
            { base: "crs", intitule: "Locations mobilières" },
          ],
        },

        {
          intitule: "Budget impôt et taxes",
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule: "Impôt, taxes etr versements assimilés",
            },
          ],
        },

        {
          intitule: "Budget masse salariale",
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule: "Salaires et traitements bruts",
            },
            {
              base: "crs",
              intitule: "Cotisations sociales patronales",
            },
          ],
        },

        {
          intitule: "Autres charges de gestion courante",
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule: "Autres charges de gestion courante",
            },
          ],
        },

        {
          intitule: "Dotations aux amortissements et provisions d'exploitation",
          rang: 1,

          ajouter: [
            {
              base: "crs",
              intitule:
                "Dotations aux amortissments et provisions d'exploitation",
            },
          ],
        },

        {
          intitule: "Affectation des charges selon leur nature",
          rang: 2,
        },
      ],

      // Seuil de rentabilité
      srs: [
        {
          intitule: "Charges variables",
          rang: 2,
          ajouter: [{ base: "cbs", intitule: "Charges variables" }],
        },
        {
          intitule: "Charges fixes",
          rang: 2,
          ajouter: [{ base: "cbs", intitule: "Charges fixes" }],
        },
        {
          intitule: "Marge sur coût variable",
          rang: 1,
          ajouter: [{ base: "sigs", intitule: "Chiffre d'affaires" }],
          soustraire: [{ base: "cbs", intitule: "Charges variables" }],
        },
        {
          intitule: "Taux de marge sur coût variable",
          rang: 1,
          taux: true,
        },

        { intitule: "Seuil de rentabilité net de subvention", rang: 3 },

        { intitule: "Seuil de rentabilité en budget global", rang: 3 },
      ],

      // bilan fonctionnel
      bfs: [
        {
          intitule: "Fonds de Roulement",
          rang: 1,
          ajouter: [
            { base: "bds", intitule: "Capitaux Propres" },
            { base: "bds", intitule: "Provisions et fonds dédiés" },
            { base: "bds", intitule: "Dettes MLT" },
          ],
          soustraire: [
            { base: "bds", intitule: "Actif Net immobilisé" },
            {
              base: "bds",
              intitule:
                "Capital souscrit non appelé & souscrit, appelé non versé",
            },
          ],
        },

        {
          intitule: "Besoin en Fonds de Roulement",
          rang: 1,
          ajouter: [
            { base: "bds", intitule: "Stocks" },
            {
              base: "bds",
              intitule: "Avances et acomptes versés sur commandes", //Av et acomptes versés sur comde
            },
            { base: "bds", intitule: "Créances" },
            {
              base: "bds",
              intitule: "Autres",
              type: "actif",
              rang: 1,
            }, // attention "Autres" se retrouve plusieurs fois dans le bilan détaillé
          ],
          soustraire: [
            {
              base: "bds",
              intitule: "Avances et acomptes reçus sur commandes", //Avces et acptes reçus sur cdes
            },
            { base: "bds", intitule: "Fournisseurs" },
            {
              base: "bds",
              intitule: "Dettes fiscales et sociales",
            },
            { base: "bds", intitule: "Autres dettes CT" },
            {
              base: "bds",
              intitule: "Autres",
              type: "passif",
              rang: 1,
            }, // attention "Autres" se retrouve plusieurs fois dans le bilan détaillé
          ],
        },

        {
          intitule: "Trésorerie nette",
          rang: 1,
          ajouter: [{ base: "bds", intitule: "Trésorerie active" }],
          soustraire: [{ base: "bds", intitule: "Trésorerie passive" }],
        },
      ],

      // board ratio
      brs: [
        { intitule: "Ratio de rentabilité", rang: 2 },
        {
          intitule: "Taux de marge global",
          rang: 1,
          taux: true,
          calcul: "(Marge brute / Chiffre d’affaires) x 100",
        },
        {
          intitule: "Taux de marge brute",
          rang: 1,
          taux: true,
          calcul: "100X (Ventes – Achats – Variation de stocks) / Ventes",
        },
        {
          intitule: "Taux de valeur ajoutée",
          rang: 1,
          taux: true,
          calcul: "(Valeur ajoutée / Chiffre d’affaires) x 100",
        },
        {
          intitule: "Masse salariale annuelle hors personnel extérieur",
          rang: 1,
          calcul:
            "Salaires et traitements bruts + Cotisations sociales patronales",
        },
        {
          intitule: "Masse salariale annuelle dont personnel extérieur",
          rang: 1,
          calcul:
            "Salaires et traitements bruts + Cotisations sociales patronales + Personnel extérieur",
        },
        {
          intitule: "Productivité du personnel globale (N°1)",
          rang: 1,
          calcul:
            "(Chiffre d’affaires + Subventions) / (ETPdirect + ETPindirect) ",
        },
        {
          intitule: "Productivité du personnel sur contributeurs direct (N°2)",
          rang: 1,
          calcul: "(Chiffre d’affaires + Subventions) / (ETPdirect)",
        },
        {
          intitule:
            "Productivité du personnel rapportée au budget annuel (hors personnel extérieur) (N°3)",
          rang: 1,
          calcul:
            "(Chiffre d’affaires + Subventions) / (Salaires et traitements bruts + Cotisations sociales patronales)",
        },
        {
          intitule: "Masse salariale moyenne (hors personnel extérieur)",
          rang: 1,
          calcul:
            "(Salaires et traitements bruts + Cotisations sociales patronales) / (ETPdirect)",
        },
        {
          intitule: "Représentativité du personnel contributeurs directs",
          rang: 1,
          taux: true,
          calcul: "ETPdirect / (ETPdirect+ETPindirect )*100",
        },
        {
          intitule: "Rendement de la main d'oeuvre",
          rang: 1,
          sans_unite: true,
          calcul: "Valeur ajoutée / (ETPdirect + ETPindirect)",
        },
        {
          intitule: "Résultat d'exploitation",
          rang: 1,
          calcul: "PRODUITS D’EXPLOITATION – CHARGES D’EXPLOITATION",
        },
        {
          intitule: "Rentabilité d'exploitation",
          rang: 1,
          taux: true, // modifié cf mail du 2023-03-17
          calcul:
            "100 * (Valeur Ajoutée + Subvention d’exploitation  + Transfert de charges  - Frais de personnel - Impôts, taxes et versement assimilés) / Chiffre d’affaire", // modifié cf mail du 2023-03-17
        },
        {
          intitule: "Taux de marge",
          rang: 1,
          taux: true,
          calcul:
            "= 100* (Valeur Ajoutée + Subvention d’exploitation  + Transfert de charges  - Frais de personnel - Impôts, taxes et versement assimilés) / Valeur ajoutée", // modifié cf mail du 2023-03-17
        },
        {
          intitule: "Poids des charges financières sur CA",
          rang: 1,
          taux: true,
          calcul: "CHARGES FINANCIERES / Chiffre d’affaires",
        },
        {
          // modification formule cd recette mail 05/12/ => multiplication par 100
          intitule: "Poids des charges financières",
          rang: 1,
          taux: true,
          calcul: "100 * CHARGES FINANCIERES / Dettes MLT", 
        },
        {
          // formule modifiée suite recette du 11/07/2024
          intitule: "Capacité d'auto-financement",
          rang: 1,
          calcul:
            "Résultat Net + Dotations aux amortissements et provisions exploitation + Dotations aux amortissements et provisions financières + Dotations aux amortissements et provisions ex-ceptionnelles + Valeur comptable des éléments d’actif cédés - Reprises sur amortissements et provisions exploitation - Reprises sur amortissements et provisions financières - Reprises sur amortissements et provisions exceptionnels – Produits de cession des éléments d’actif – Q/P de subv.invt virée au résultat + Correction fonds dédiés sur Subventions - Reprise des fonds dédiés de N-1",
        },
        {
          intitule: "Rentabilité globale",
          taux: true, // modifié cf mail du 2023-03-17
          rang: 1,
          calcul:
            "100 * (Résultat Net + Dotations aux amortissements et provisions exploitation + Dotations aux amortissements et provisions financières + Dotations aux amortissements et provisions ex-ceptionnelles + Valeur comptable des éléments d’actif cédés - Reprises sur amortissements et provisions exploitation - Reprises sur amortissements et provisions financières - Reprises sur amortissements et provisions exceptionnels – Produits de cession des éléments d’actif – Q/P de subv.invt virée au résultat) / (Chiffre d’affaires + Subventions)",
            // formule modifiée cf recette dec 2024/12/05 (ajout X 100)
        },
        {
          intitule: "Rentabilité financière",
          rang: 1,
          taux: true,
          calcul: "Résultat Net  / Capitaux propres",
        },

        { intitule: "Ratio de structuration financière", rang: 2 },
        {
          intitule: "Solvabilité",
          rang: 1,
          taux: true,
          calcul: "Capitaux propres  / Total bilan (passif)",
        },
        {
          intitule: "Capacité d'endettement structurel",
          rang: 1,
          taux: true,
          calcul: "100 * (Capitaux propres / Dettes MLT)", //modifié cf mail du 2023-03-17
        },
        {
          intitule: "Marge de manoeuvre d'endettement",
          rang: 1,
          calcul: "Capitaux propres - Dettes MLT",
        },
        {
          intitule: "Capacité de remboursement des dettes MLT",
          rang: 1,
          annee: true,
          calcul:
            "(Dettes MLT / (Résultat Net + Dotations aux amortissements et provisions exploitation + Dotations aux amortissements et provisions financières + Dotations aux amortissements et provisions exceptionnelles + Valeur comptable des éléments d’actif cédés - Reprises sur amortissements et provisions exploitation - Reprises sur amortissements et provisions finan-cières - Reprises sur amortissements et provisions exceptionnels – Produits de cession des éléments d’actif – Q/P de subv.invt virée au résultat)) ",
        },
        {
          intitule: "Taux brut d'endettement financier",
          rang: 1,
          annee: true,
          taux: true,
          calcul: "100 * Dettes MLT / Capitaux propres", //modifié cf mail du 2023-03-17
        },
        {
          intitule: "Indépendance financière",
          rang: 1,
          taux: true,
          calcul: "Capitaux propres / (Capitaux propres + Dettes MLT)",
        },
        {
          intitule: "Couverture des capitaux investis",
          rang: 1,
          taux: true,
          calcul:
            "(Capitaux propres + Dettes MLT) / (Actif net immobilisé + (Stocks + Avances et acomptes versés sur commandes + Créances + Autres (actif) – Avces et acptes reçus sur comdes – Fournisseurs – Dettes fiscales et sociales – Autres dettes CT – Autres (passif)))",
        },
        {
          intitule: "Immobilisation de l'actif",
          rang: 1,
          taux: true,
          calcul: "100 * (Somme des classes 2 / Total bilan (actif))", // modifié cf mail du 2023-03-17
        },
        {
          intitule: "Intensité capitalistique",
          rang: 1,
          taux: true,
          calcul: "100* Somme des classes 2 débitrices / Chiffre d’affaires", // modifié cf mail du 2023-03-17
        },
        {
          intitule: "Vétusté des investissements",
          rang: 1,
          taux: true,
          calcul:
            "100 * (Somme 21 – abs(Somme 281) – abs(Somme 291)) / (Somme 21 débiteur)", // modifiée (ajout X 100) cf recette dec 2024/12/05
        },
        {
          intitule: "Financement des immobilisations",
          rang: 1,
          taux: true,
          calcul: "(Capitaux propres + Dettes MLT) / Actif net immobilisé",
        },
        {
          intitule: "Valeur relative du FdR",
          rang: 1,
          jour: true,
          calcul:
            "periode exercice * (Capitaux propres + Provisions et fonds dédiés + Dettes MLT – Actif net immobilisé – Capital souscrit non appelé & souscrit, appelé, non versé) / Chiffre d’affaires",
        },
        {
          intitule: "Liquidité de l'actif",
          rang: 1,
          taux: true,
          calcul:
            "(Stocks + Avances et acomptes versés sur commandes + Créances + Trésorerie active – Trésorerie pas-sive) / Total bilan (actif)",
        },
        {
          intitule: "Liquidité générale",
          rang: 1,
          sans_unite: true,
          calcul:
            "(Stocks + Avances et acomptes versés sur commandes + Créances + Trésorerie active – Trésorerie pas-sive) / (Avces et acptes reçus sur comde + Fournisseurs + Dettes fiscales et sociales + Autres dettes CT)",
        },
        {
          intitule: "Liquidité immédiate",
          rang: 1,
          sans_unite: true,
          calcul:
            "(Trésorerie active – Trésorerie passive) / (Avces et acptes reçus sur comde + Fournisseurs + Dettes fiscales et sociales + Autres dettes CT)",
        },
        {
          intitule: "Couverture des frais fixes",
          rang: 1,
          jour: true,
          calcul:
            "periode exercice * [(Trésorerie active – Trésorerie passive) / Charges fixes]",
        },
        {
          intitule: "Valeur relative du BFR",
          rang: 1,
          jour: true,
          calcul:
            "periode exercice * [(Stocks + Avances et acomptes versés sur commandes + Créances + Autres (actif) – Avces et acptes reçus sur comdes – Fournisseurs – Dettes fiscales et sociales – Autres dettes CT – Autres (passif)) / Chiffre d’affaires]",
        },
        { intitule: "Durée des crédits", rang: 2 },
        {
          intitule: "Crédit clients en jours",
          rang: 1,
          jour: true,
          calcul:
            "periode exercice * Clients et compte rattachés / ((Ventes + Prestations) * tva client)",
        },

        {
          intitule: "Crédit fournisseurs en jours",
          rang: 1,
          jour: true,
          calcul:
            "periode exercice * Fournisseurs / ((Achats + Autres achats et charges externes) * (1 + TVA/100 )",
        },
        {
          intitule: "Stockage des matières 1ères en jours",
          rang: 1,
          jour: true,
          calcul:
            "periode exercice * ((Matières premières et autres approvisionnements + En-cours de production (biens et services) + Marchandises) / (Achats + Variation de stocks ))",
        },
        {
          intitule: "Stockage des produits finis en jours",
          rang: 1,
          jour: true,
          calcul:
            "periode exercice * ( Produits intermédiaires et finis / (Ventes + Production stockée))",
        },
      ],

      // somme de classe 2
      somme_2: {},

      // somme de classe 2 debiteur
      somme_2D: {},

      // somme de classe 21
      somme_21: {},

      // somme de classe 281
      somme_281: {},

      // somme de classe 291
      somme_291: {},

      // somme de classe 21 debiteur
      somme_21D: {},

      // somme de classe 6 solde
      somme_6: {},

      // somme de classe 7 solde
      somme_7: {},
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
  },

  watch: {
    kilo_euro(val) {
      EventBus.$emit("kilo_euroChanged", val);
    },
    //possibles_diagnostic_years
    diagnostic_years(val, old) {
      if (this.possibles_diagnostic_years.length > 0 && this.selectedClient) {
        // pour la vue "Charges / Budget"  on vide les valeurs précédentes
        // des lignes 'Affectation des charges selon leur nature'
        // (Charges fixe, Charges variable)

        this.cbs = this.cbs.filter(function (el) {
          if (
            el.intitule == "Charges fixes" ||
            el.intitule == "Charges variables"
          ) {
            return false;
          }
          return true;
        });

        this.cr_loading = true;
        this.bd_loading = true;
        this.sig_loading = true;
        this.ff_loading = true;
        this.pb_loading = true;
        this.cb_loading = true;
        this.sr_loading = true;
        this.bf_loading = true;
        this.br_loading = true;
        this.GlobalLoading = true;

        this.get_somme_2();
        this.get_somme_6();
        this.get_somme_7();
        this.get_somme_2D();
        this.get_somme_21();
        this.get_somme_21D();
        this.get_somme_291();
        this.get_somme_281();

        this.get_crs().then(() => {
          this.cr_loading = false;
          this.get_bds().then(() => {
            this.bd_loading = false;
            this.set_sigs().then(() => {
              this.sig_loading = false;
              this.get_ffs().then(() => {
                this.ff_loading = false;
              });
              this.set_pbs().then(() => {
                this.pb_loading = false;
                this.get_charges().then(() => {
                  this.set_cbs().then(() => {
                    this.cb_loading = false;
                    this.set_srs().then(() => {
                      this.sr_loading = false;
                      this.set_bfs().then(() => {
                        this.bf_loading = false;
                        this.set_brs().then(() => {
                          this.br_loading = false;
                          this.GlobalLoading = false;
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      }
    },

    selectedClient(val) {
      this.GlobalLoading = true;

      this.diagnostic_years = [];

      if (val) {
        // pour la vue "Charges / Budget"  on vide les valeurs précédentes
        // des lignes 'Affectation des charges selon leur nature'
        // (Charges fixe, Charges variable)

        this.cbs = this.cbs.filter(function (el) {
          if (
            el.intitule == "Charges fixes" ||
            el.intitule == "Charges variables"
          ) {
            return false;
          }
          return true;
        });

        //chargement de la liste des années de balance annuelle
        this.getDiagnosticYears();
        localStorage.selectedClient = JSON.stringify(val);
        EventBus.$emit("ClientChanged", val);
      }
    },
  },

  methods: {
    refresh() {
      //this.$forceUpdate()

      this.sigDatatableKey++;
      this.produitsDatatableKey++;
      this.chargesDatatableKey++;
      this.seuilDatatableKey++;
      this.resultatDatatableKey++;
      this.bilanfDatatableKey++;
      this.bilandDatatableKey++;
      this.ratioDatatableKey++;
      this.fluxDatatableKey++;

      this.exportSigDatatableKey++;
      this.exportProduitsDatatableKey++;
      this.exportChargesDatatableKey++;
      this.exportSeuilDatatableKey++;
      this.exportResultatDatatableKey++;
      this.exportBilanfDatatableKey++;
      this.exportBilandDatatableKey++;
      this.exportRatioDatatableKey++;
      this.exportFluxDatatableKey++;
    },

    // toggleAllRowVisibility pour afficher masquer toutes les lignes
    // dans l'exporation image d'un tableau

    toggleAllRowVisibility(state, targetData) {
      console.log(state, targetData);
      _.map(targetData, function (obj) {
        obj.show = state;
      });
      this.refresh();
    },
    // toggleRowVisibility pour afficher masquer une ligne
    // avec ses enfants selon les cas
    // target = jeux de donnée cible
    // row = identifiant de la ligne

    toggleRowVisibility(state, target, index) {
      //console.log(booleanValue);
      //console.log(target);
      //console.log(index);
      //console.log(target[index]);
      let i = index;

      // si rang = 3
      // on ne s'occupe pas des enfants
      if (target[index].rang == 3) {
        target[index].show = state;
      } else if (target == this.ffs) {
        do {
          target[i].show = state;
          i--;
        } while (target[i] && target[i].rang < target[index].rang);
      } else {
        do {
          target[i].show = state;
          i++;
        } while (target[i] && target[i].rang < target[index].rang);
      }
      this.refresh();
    },

    exportImage(tab) {
      var node = document.getElementById(tab.name);
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = tab.name + ".png";
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },

    validSR(annee) {
      let marge_sur_cout_variable = parseFloat(
        _.filter(this.srs, ["intitule", "Marge sur coût variable"])[0][annee]
      );
      if (marge_sur_cout_variable < 0) {
        return false;
      } else {
        return true;
      }
    },

    verifSR(annee) {
      if (!validSR(annee)) {
        return;
      }
    },

    validSig(annee) {
      return (
        parseFloat(-this.somme_7[annee] - this.somme_6[annee]).toLocaleString(
          "fr-FR",
          { minimumFractionDigits: 0, maximumFractionDigits: 0 }
        ) ==
        parseFloat(
          _.filter(this.sigs, ["intitule", "Résultat Net"])[0][annee]
        ).toLocaleString("fr-FR", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      );
    },

    verifSig(annee) {
      if (!this.validSig(annee)) {
        return (
          "Pour l'année " +
          annee +
          " : -S(7) - S(6) est différent de 'Résultat net' <br>Car " +
          parseFloat(-this.somme_7[annee] - this.somme_6[annee]).toLocaleString(
            "fr-FR",
            { minimumFractionDigits: 0, maximumFractionDigits: 0 }
          ) +
          " est différent de " +
          parseFloat(
            _.filter(this.sigs, ["intitule", "Résultat Net"])[0][annee]
          ).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        );
      }
      return (
        "Pour l'année " + annee + " : -S(7) - S(6) est égal au 'Résultat net'"
      );
    },

    validbf(annee) {
      return (
        parseFloat(
          parseFloat(
            _.filter(this.bfs, ["intitule", "Fonds de Roulement"])[0][annee]
          ) -
            parseFloat(
              _.filter(this.bfs, [
                "intitule",
                "Besoin en Fonds de Roulement",
              ])[0][annee]
            )
        ).toLocaleString("fr-FR", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }) ==
        parseFloat(
          _.filter(this.bfs, ["intitule", "Trésorerie nette"])[0][annee]
        ).toLocaleString("fr-FR", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      );
    },

    verifbf(annee) {
      if (!this.validbf(annee)) {
        return (
          "Pour l'année " +
          annee +
          " : 'Trésorerie nette' est différent de 'Fonds de roulement' - 'Besoin en Fonds de Roulement' <br>Car " +
          parseFloat(
            _.filter(this.bfs, ["intitule", "Trésorerie nette"])[0][annee]
          ).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }) +
          " est différent de " +
          parseFloat(
            parseFloat(
              _.filter(this.bfs, ["intitule", "Fonds de Roulement"])[0][annee]
            ) -
              parseFloat(
                _.filter(this.bfs, [
                  "intitule",
                  "Besoin en Fonds de Roulement",
                ])[0][annee]
              )
          ).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        );
      }
      return (
        "Pour l'année " +
        annee +
        " : 'Trésorerie nette' est égal de 'Fonds de roulement' - 'Besoin en Fonds de Roulement'"
      );
    },

    validFf(annee) {
      let vtn, ete, sto, sod, sof, soe;

      vtn = parseFloat(
        _.filter(this.ffs, [
          "intitule",
          "VARIATION DE TRESORERIE NETTE SUR L'ANNEE",
        ])[0][annee]
      );
      ete = parseFloat(
        _.filter(this.ffs, [
          "intitule",
          "EXEDENT DE TRESORERIE D'EXPLOITATION (ETE)",
        ])[0][annee]
      );
      sto = parseFloat(
        _.filter(this.ffs, [
          "intitule",
          "SOLDE DE TRESORERIE OPERATIONNELLE (STO)",
        ])[0][annee]
      );
      sod = parseFloat(
        _.filter(this.ffs, [
          "intitule",
          "SOLDE DES OPERATIONS DISCRETIONNAIRES",
        ])[0][annee]
      );
      sof = parseFloat(
        _.filter(this.ffs, ["intitule", "SOLDE DES OPERATIONS FINANCIERES"])[0][
          annee
        ]
      );
      soe = parseFloat(
        _.filter(this.ffs, [
          "intitule",
          "SOLDE DES OPERATIONS EXCEPTIONNELLES",
        ])[0][annee]
      );

      if (
        isNaN(vtn) ||
        isNaN(ete) ||
        isNaN(sto) ||
        isNaN(sod) ||
        isNaN(sof) ||
        isNaN(soe)
      ) {
        return false;
      }

      return (
        parseFloat(vtn).toLocaleString("fr-FR", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }) ==
        parseFloat(
          parseFloat(ete) +
            parseFloat(sto) +
            parseFloat(sod) +
            parseFloat(sof) +
            parseFloat(soe)
        ).toLocaleString("fr-FR", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      );
    },

    verifFf(annee) {
      let vtn, ete, sto, sod, sof, soe;

      vtn = parseFloat(
        _.filter(this.ffs, [
          "intitule",
          "VARIATION DE TRESORERIE NETTE SUR L'ANNEE",
        ])[0][annee]
      );
      ete = parseFloat(
        _.filter(this.ffs, [
          "intitule",
          "EXEDENT DE TRESORERIE D'EXPLOITATION (ETE)",
        ])[0][annee]
      );
      sto = parseFloat(
        _.filter(this.ffs, [
          "intitule",
          "SOLDE DE TRESORERIE OPERATIONNELLE (STO)",
        ])[0][annee]
      );
      sod = parseFloat(
        _.filter(this.ffs, [
          "intitule",
          "SOLDE DES OPERATIONS DISCRETIONNAIRES",
        ])[0][annee]
      );
      sof = parseFloat(
        _.filter(this.ffs, ["intitule", "SOLDE DES OPERATIONS FINANCIERES"])[0][
          annee
        ]
      );
      soe = parseFloat(
        _.filter(this.ffs, [
          "intitule",
          "SOLDE DES OPERATIONS EXCEPTIONNELLES",
        ])[0][annee]
      );

      if (
        isNaN(vtn) ||
        isNaN(ete) ||
        isNaN(sto) ||
        isNaN(sod) ||
        isNaN(sof) ||
        isNaN(soe)
      ) {
        return (
          "Pour l'année " +
          annee +
          " : vérification impossible : paramétrage manquant"
        );
      }

      if (!this.validFf(annee)) {
        return (
          "Pour l'année " +
          annee +
          " : 'Variation de trésorerie nette sur l'année' est différent de 'ETE' + 'STO' + 'SOD' + 'SOF' + 'SOE'" +
          "<br>Car " +
          vtn.toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }) +
          " est différent de " +
          parseFloat(
            parseFloat(ete) +
              parseFloat(sto) +
              parseFloat(sod) +
              parseFloat(sof) +
              parseFloat(soe)
          ).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        );
      }
      return (
        "Pour l'année " +
        annee +
        " : 'Variation de trésorerie nette sur l'année' est égal à 'ETE' + 'STO' + 'SOD' + 'SOF' + 'SOE'"
      );
    },

    validBd(annee) {
      let passif, actif;

      actif = parseFloat(
        _.filter(this.bds, ["intitule", "TOTAL BILAN ACTIF"])[0][annee]
      );
      passif = parseFloat(
        _.filter(this.bds, ["intitule", "TOTAL BILAN PASSIF"])[0][annee]
      );

      return (
        parseFloat(actif).toLocaleString("fr-FR", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }) ==
        parseFloat(passif).toLocaleString("fr-FR", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      );
    },

    verifBd(annee) {
      let passif, actif;

      actif = parseFloat(
        _.filter(this.bds, ["intitule", "TOTAL BILAN ACTIF"])[0][annee]
      );
      passif = parseFloat(
        _.filter(this.bds, ["intitule", "TOTAL BILAN PASSIF"])[0][annee]
      );

      if (!this.validBd(annee)) {
        return (
          "Pour l'année " +
          annee +
          " : 'Passif' est différent de 'Actif'" +
          "<br>Car " +
          passif.toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }) +
          " est différent de " +
          actif.toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        );
      }
      return "Pour l'année " + annee + " : 'Passif' est égal à 'Actif'";
    },

    set_excelHeaders() {
      var headers = { intitule: "intitule" };
      this.diagnostic_years.forEach((year) => {
        headers["année " + year] = year;
      });
      return headers;
    },

    /*
     * format value in K€
     */
    format(value, item) {
      // exception au formatage en Euro (precision au centième)
      if (
        item.intitule ==
        "Productivité du personnel rapportée au budget annuel (hors personnel extérieur) (N°3)"
      ) {
        return this.kilo_euro == true
          ? parseFloat(value / 1000).toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
            })
          : parseFloat(value).toLocaleString("fr-FR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            });
      }

      if (isNaN(parseFloat(value)) || item.taux || item.sans_unite) {
        if (isNaN(value)) {
          return value;
        } else {
          return parseFloat(value).toFixed(1);
        }
      }
      if (item.jour || item.annee) {
        return Math.round(value);
      }
      // formatage en K€ on préfère que les montants soient arrondis à l’unité cf recette du 31/03/2023 et mail du 12/04
      return this.kilo_euro == true
        ? parseFloat(value / 1000).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : parseFloat(value).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
    },

    pourcent_cr(cr, annee) {
      var pourcent;
      var total_category = _.filter(this.crs, ["intitule", cr.category])[0][
        annee
      ];

      if (parseFloat(total_category) != 0) {
        pourcent = parseFloat(
          (parseFloat(cr[annee]) * 100) / parseFloat(total_category)
        ).toFixed(1);
        if (pourcent > 200) {
          return ">200";
        }
        if (pourcent < -200) {
          return "<200";
        }
      } else {
        pourcent = 0.0;
      }

      return parseFloat(pourcent).toFixed(1);
    },

    pourcent_bd(bd, annee) {
      var pourcent;
      var total_category = _.filter(this.bds, ["intitule", bd.category])[0][
        annee
      ];

      if (parseFloat(total_category) != 0) {
        pourcent = parseFloat(
          (parseFloat(bd[annee]) * 100) / parseFloat(total_category)
        ).toFixed(1);
        if (pourcent > 200) {
          return ">200";
        }
        if (pourcent < -200) {
          return "<200";
        }
      } else {
        pourcent = 0.0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
      }

      return parseFloat(pourcent).toFixed(1);
    },

    pourcent_sig(sig, annee) {
      var pourcent;
      var base;
      if (this.pourcent_PE == true) {
        base = parseFloat(
          _.filter(this.crs, ["intitule", "PRODUITS D'EXPLOITATION"])[0][annee]
        );
      } else {
        base = parseFloat(
          _.filter(this.sigs, ["intitule", "Chiffre d'affaires"])[0][annee]
        );
      }

      if (parseFloat(base) != 0) {
        pourcent = parseFloat(
          (parseFloat(sig[annee]) * 100) / parseFloat(base)
        ).toFixed(1);

        if (pourcent > 200) {
          return ">200";
        }
        if (pourcent < -200) {
          return "<200";
        }
      } else {
        pourcent = 0.0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
      }

      return parseFloat(pourcent).toFixed(1);
    },
    pourcent_cb(cb, annee) {
      var pourcent;
      var base;
      if (this.pourcent_PE == true) {
        base = parseFloat(
          _.filter(this.crs, ["intitule", "PRODUITS D'EXPLOITATION"])[0][annee]
        );
      } else {
        base = parseFloat(
          _.filter(this.sigs, ["intitule", "Chiffre d'affaires"])[0][annee]
        );
      }

      if (parseFloat(base) != 0) {
        pourcent = parseFloat(
          (parseFloat(cb[annee]) * 100) / parseFloat(base)
        ).toFixed(1);
        if (pourcent > 200) {
          return ">200";
        }
        if (pourcent < -200) {
          return "<200";
        }
      } else {
        pourcent = 0.0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
      }

      return parseFloat(pourcent).toFixed(1);
    },

    /*
     * Board ratio
     */

    set_brs() {
      // console.log('set_brs')
      this.br_loading = true;

      return new Promise((resolve, reject) => {
        var years_values;
        var value;
        var ajouter;
        var marge_brute;
        var ca;
        var valeur_ajoutee;
        var salaires_et_traitements_bruts;
        var cotisations_sociales_patronales;
        var personnel_exterieur;
        var subventions;
        var ETPdirect;
        var ETPindirect;
        var produits_exploitation;
        var charges_exploitation;
        var charges_financieres;
        var resultat_net;
        var dotations_aux_amortissements_et_provisions_exploitation;
        var dotations_aux_amortissements_et_provisions_financieres;
        var dotations_aux_amortissements_et_provisions_exceptionnelles;
        var valeur_comptable_des_elements_actif_cedes;
        var reprises_sur_amortissements_et_provisions_exploitation;
        var reprises_sur_amortissements_et_provisions_financieres;
        var reprises_sur_amortissements_et_provisions_exceptionnels;
        var produits_de_cession_des_elements_actif;
        var qp_de_subv_invt_viree_au_resultat;
        var capitaux_propres;
        var total_bilan_passif;
        var dettes_mlt;
        var actif_net_immobilise;
        var stocks;
        var av_et_acomptes_verses_sur_comde;
        var creances;
        var autres_actif;
        var avces_et_acptes_recus_sur_comdes;
        var fournisseurs;
        var dettes_fiscales_et_sociales;
        var autres_dettes_CT;
        var autres_passif;
        var total_bilan_actif;
        var provisions_et_fonds_dedies;
        var capital_souscrit_non_appele_souscrit_appele_verse;
        var tresorerie_active;
        var tresorerie_passive;
        var charges_fixes;
        var periode_exercice;
        var ventes;
        var achats;
        var variation_stock;
        var Subvention_exploitation;
        var transferts_charges;
        var frais_personnel;
        var impots_taxes_et_versement_assimiles;
        var clients_et_compte_rattaches;
        var prestations_de_service;
        var autres_achats_et_charges_externes;
        var matieres_premieres_et_autres_approvisionnements;
        var en_cours_de_production_biens_et_services;
        var marchandises;
        var produits_intermediaires_et_finis;
        var production_stockee_immobilisee;

        _.forEach(this.brs, (br, key) => {
          // console.log("forEach this.brs",br)
          years_values = {};

          _.forEach(this.diagnostic_years, (annee) => {
            value = 0;
            ajouter = 0;

            if (
              br.intitule == "Ratio de rentabilité" ||
              br.intitule == "Ratio de structuration financière"
            ) {
              return;
            } // entete sans calcul

            if (br.intitule == "Taux de marge global") {
              // (Marge brute / Chiffre d’affaires) x 100
              marge_brute = _.find(this.sigs, { intitule: "Marge brute" })[
                annee
              ];
              ca = _.find(this.sigs, { intitule: "Chiffre d'affaires" })[annee];
              if (ca == 0 || ca == null) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (parseFloat(marge_brute) * 100) / parseFloat(ca)
                ).toFixed(2);
              }
            }
            if (br.intitule == "Taux de marge brute") {
              // 100 X (Ventes – Achats – Variation de stocks) / Ventes
              ventes = _.find(this.crs, { intitule: "Ventes" })[annee];
              achats = _.find(this.crs, { intitule: "Achats" })[annee];
              variation_stock = _.find(this.crs, {
                intitule: "Variation de stocks",
              })[annee];

              if (ventes == 0 || ventes == null) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  100 *
                    parseFloat(
                      parseFloat(
                        parseFloat(ventes) -
                          parseFloat(achats) -
                          parseFloat(variation_stock)
                      ) / parseFloat(ventes)
                    )
                ).toFixed(2);
              }
            }

            if (br.intitule == "Taux de valeur ajoutée") {
              //(Valeur ajoutée / Chiffre d’affaire) x 100"},
              valeur_ajoutee = _.find(this.sigs, {
                intitule: "Valeur Ajoutée",
              })[annee];
              ca = _.find(this.sigs, { intitule: "Chiffre d'affaires" })[annee];
              if (ca == 0 || ca == null) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (parseFloat(valeur_ajoutee) * 100) / parseFloat(ca)
                ).toFixed(2);
              }
            }

            if (
              br.intitule == "Masse salariale annuelle hors personnel extérieur"
            ) {
              //Salaires et traitements bruts + Cotisations sociales patronales
              salaires_et_traitements_bruts = _.find(this.crs, {
                intitule: "Salaires et traitements bruts",
              })[annee];
              cotisations_sociales_patronales = _.find(this.crs, {
                intitule: "Cotisations sociales patronales",
              })[annee];
              value = parseFloat(
                parseFloat(salaires_et_traitements_bruts) +
                  parseFloat(cotisations_sociales_patronales)
              ).toFixed(2);
            }

            if (
              br.intitule == "Masse salariale annuelle dont personnel extérieur"
            ) {
              //Salaires et traitements bruts + Cotisations sociales patronales + Personnel extérieur
              salaires_et_traitements_bruts = _.find(this.crs, {
                intitule: "Salaires et traitements bruts",
              })[annee];
              cotisations_sociales_patronales = _.find(this.crs, {
                intitule: "Cotisations sociales patronales",
              })[annee];
              personnel_exterieur = _.find(this.crs, {
                intitule: "Personnel extérieur à l'entreprise",
              })[annee];
              value = parseFloat(
                parseFloat(salaires_et_traitements_bruts) +
                  parseFloat(cotisations_sociales_patronales) +
                  parseFloat(personnel_exterieur)
              ).toFixed(2);
            }

            if (br.intitule == "Productivité du personnel globale (N°1)") {
              //(Chiffre d’affaires + Subventions) / (ETPdirect + ETPindirect)
              ca = _.find(this.sigs, { intitule: "Chiffre d'affaires" })[annee];
              subventions = _.find(this.pbs, { intitule: "Subventions" })[
                annee
              ];

              if (
                !_.find(this.selectedClient.etps, { annee: parseInt(annee) })
              ) {
                years_values[annee] =
                  "<span style='color:red'>ETP non paramétré pour " +
                  annee +
                  "</span>";
                return;
              } // pas de param etp pour le client

              ETPdirect = _.find(this.selectedClient.etps, {
                annee: parseInt(annee),
              })["etp_direct"];
              ETPindirect = _.find(this.selectedClient.etps, {
                annee: parseInt(annee),
              })["etp_indirect"];

              if (parseFloat(ETPdirect) + parseFloat(ETPindirect) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (parseFloat(ca) + parseFloat(subventions)) /
                    (parseFloat(ETPdirect) + parseFloat(ETPindirect))
                ).toFixed(2);
              }
            }

            if (
              br.intitule ==
              "Productivité du personnel sur contributeurs direct (N°2)"
            ) {
              //(Chiffre d’affaires + Subventions) / (ETPdirect)
              ca = _.find(this.sigs, { intitule: "Chiffre d'affaires" })[annee];
              subventions = _.find(this.pbs, { intitule: "Subventions" })[
                annee
              ];

              if (
                !_.find(this.selectedClient.etps, { annee: parseInt(annee) })
              ) {
                years_values[annee] =
                  "<span style='color:red'>ETP non paramétré pour " +
                  annee +
                  "</span>";
                return;
              } // pas de param etp pour le client
              ETPdirect = _.find(this.selectedClient.etps, {
                annee: parseInt(annee),
              })["etp_direct"];

              if (parseFloat(ETPdirect) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (parseFloat(ca) + parseFloat(subventions)) /
                    parseFloat(ETPdirect)
                ).toFixed(2);
              }
            }

            if (
              br.intitule ==
              "Productivité du personnel rapportée au budget annuel (hors personnel extérieur) (N°3)"
            ) {
              //(Chiffre d’affaires + Subventions) / (Salaires et traitements bruts + Cotisations sociales patronales)
              ca = _.find(this.sigs, { intitule: "Chiffre d'affaires" })[annee];
              subventions = _.find(this.pbs, { intitule: "Subventions" })[
                annee
              ];
              salaires_et_traitements_bruts = _.find(this.crs, {
                intitule: "Salaires et traitements bruts",
              })[annee];
              cotisations_sociales_patronales = _.find(this.crs, {
                intitule: "Cotisations sociales patronales",
              })[annee];

              if (
                parseFloat(salaires_et_traitements_bruts) +
                  parseFloat(cotisations_sociales_patronales) ==
                0
              ) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (parseFloat(ca) + parseFloat(subventions)) /
                    (parseFloat(salaires_et_traitements_bruts) +
                      parseFloat(cotisations_sociales_patronales))
                ).toFixed(2);
              }
            }

            if (
              br.intitule ==
              "Masse salariale moyenne (hors personnel extérieur)"
            ) {
              //(Salaires et traitements bruts + Cotisations sociales patronales) / (ETPdirect)
              salaires_et_traitements_bruts = _.find(this.crs, {
                intitule: "Salaires et traitements bruts",
              })[annee];
              cotisations_sociales_patronales = _.find(this.crs, {
                intitule: "Cotisations sociales patronales",
              })[annee];

              if (
                !_.find(this.selectedClient.etps, { annee: parseInt(annee) })
              ) {
                years_values[annee] =
                  "<span style='color:red'>ETP non paramétré pour " +
                  annee +
                  "</span>";
                return;
              } // pas de param etp pour le client
              ETPdirect = _.find(this.selectedClient.etps, {
                annee: parseInt(annee),
              })["etp_direct"];

              if (parseFloat(ETPdirect) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (parseFloat(salaires_et_traitements_bruts) +
                    parseFloat(cotisations_sociales_patronales)) /
                    parseFloat(ETPdirect)
                ).toFixed(2);
              }
            }

            if (
              br.intitule ==
              "Représentativité du personnel contributeurs directs"
            ) {
              // ETPdirect / (ETPdirect+ETPindirect )*100
              if (
                !_.find(this.selectedClient.etps, { annee: parseInt(annee) })
              ) {
                years_values[annee] =
                  "<span style='color:red'>ETP non paramétré pour " +
                  annee +
                  "</span>";
                return;
              } // pas de param etp pour le client
              ETPdirect = _.find(this.selectedClient.etps, {
                annee: parseInt(annee),
              })["etp_direct"];
              ETPindirect = _.find(this.selectedClient.etps, {
                annee: parseInt(annee),
              })["etp_indirect"];

              if (parseFloat(ETPdirect) + parseFloat(ETPindirect) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (parseFloat(ETPdirect) * 100) /
                    (parseFloat(ETPdirect) + parseFloat(ETPindirect))
                ).toFixed(2);
              }
            }

            if (br.intitule == "Rendement de la main d'oeuvre") {
              // console.log('suis ici')
              // Valeur ajoutée / (ETPdirect + ETPindirect)
              if (
                !_.find(this.selectedClient.etps, { annee: parseInt(annee) })
              ) {
                years_values[annee] =
                  "<span style='color:red'>ETP non paramétré pour " +
                  annee +
                  "</span>";
                return;
              } // pas de param etp pour le client

              valeur_ajoutee = _.find(this.sigs, {
                intitule: "Valeur Ajoutée",
              })[annee];
              // console.log('valeur_ajoutee',valeur_ajoutee)
              ETPdirect = _.find(this.selectedClient.etps, {
                annee: parseInt(annee),
              })["etp_direct"];
              // console.log('etp_direct',ETPdirect)
              ETPindirect = _.find(this.selectedClient.etps, {
                annee: parseInt(annee),
              })["etp_indirect"];
              // console.log('etp_indirect',ETPindirect)

              if (parseFloat(ETPdirect) + parseFloat(ETPindirect) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  parseFloat(valeur_ajoutee) /
                    (parseFloat(ETPdirect) + parseFloat(ETPindirect))
                ).toFixed(2);
              }
            }

            if (br.intitule == "Résultat d'exploitation") {
              // PRODUITS D’EXPLOITATION – CHARGES D’EXPLOITATION
              produits_exploitation = _.find(this.crs, {
                intitule: "PRODUITS D'EXPLOITATION",
              })[annee];
              charges_exploitation = _.find(this.crs, {
                intitule: "CHARGES D'EXPLOITATION",
              })[annee];

              value = (
                parseFloat(produits_exploitation) -
                parseFloat(charges_exploitation)
              ).toFixed(2);
            }

            if (br.intitule == "Rentabilité d'exploitation") {
              // 100 * (Valeur Ajoutée + Subvention d’exploitation  + Transfert de charges  - Frais de personnel - Impôts, taxes et versement assimilés) / Chiffre d’affaire
              valeur_ajoutee = _.find(this.sigs, {
                intitule: "Valeur Ajoutée",
              })[annee];
              Subvention_exploitation = _.find(this.crs, {
                intitule: "Subvention d'exploitation",
              })[annee];
              transferts_charges = _.find(this.crs, {
                intitule: "Transferts de charges",
              })[annee];
              frais_personnel = _.find(this.sigs, {
                intitule: "Frais de personnel",
              })[annee];
              impots_taxes_et_versement_assimiles = _.find(this.sigs, {
                intitule: "Impôts, taxes et versement assimilés",
              })[annee];
              ca = _.find(this.sigs, { intitule: "Chiffre d'affaires" })[annee];

              if (parseFloat(ca) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  100 *
                    parseFloat(
                      parseFloat(
                        parseFloat(valeur_ajoutee) +
                          parseFloat(Subvention_exploitation) +
                          parseFloat(transferts_charges) -
                          parseFloat(frais_personnel) -
                          parseFloat(impots_taxes_et_versement_assimiles)
                      ) / parseFloat(ca)
                    )
                ).toFixed(2);
              }
            }

            if (br.intitule == "Taux de marge") {
              // 100* (Valeur Ajoutée + Subvention d’exploitation  + Transfert de charges  - Frais de personnel - Impôts, taxes et versement assimilés) / Valeur ajoutée
              valeur_ajoutee = _.find(this.sigs, {
                intitule: "Valeur Ajoutée",
              })[annee];
              Subvention_exploitation = _.find(this.crs, {
                intitule: "Subvention d'exploitation",
              })[annee];
              transferts_charges = _.find(this.crs, {
                intitule: "Transferts de charges",
              })[annee];
              frais_personnel = _.find(this.sigs, {
                intitule: "Frais de personnel",
              })[annee];
              impots_taxes_et_versement_assimiles = _.find(this.sigs, {
                intitule: "Impôts, taxes et versement assimilés",
              })[annee];

              if (parseFloat(valeur_ajoutee) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  100 *
                    parseFloat(
                      parseFloat(
                        parseFloat(valeur_ajoutee) +
                          parseFloat(Subvention_exploitation) +
                          parseFloat(transferts_charges) -
                          parseFloat(frais_personnel) -
                          parseFloat(impots_taxes_et_versement_assimiles)
                      ) / parseFloat(valeur_ajoutee)
                    )
                ).toFixed(2);
              }
            }
            if (br.intitule == "Poids des charges financières sur CA") {
              // CHARGES FINANCIERES / Chiffre d’affaires
              charges_financieres = _.find(this.crs, {
                intitule: "CHARGES FINANCIERES",
              })[annee];
              ca = _.find(this.sigs, { intitule: "Chiffre d'affaires" })[annee];

              if (parseFloat(ca) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  parseFloat(charges_financieres) / parseFloat(ca)
                ).toFixed(2);
              }
            }
            if (br.intitule == "Poids des charges financières") {
              // 100 * CHARGES FINANCIERES / Dettes MLT
              charges_financieres = _.find(this.crs, {
                intitule: "CHARGES FINANCIERES",
              })[annee];
              dettes_mlt = _.find(this.bds, { intitule: "Dettes MLT" })[annee];

              //  console.log("dettes_mlt",dettes_mlt)
              //  console.log("charges_financieres",charges_financieres)


              if (parseFloat(dettes_mlt) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = 100*parseFloat(
                  parseFloat(charges_financieres) / parseFloat(dettes_mlt)
                ).toFixed(2);
              }
              // console.log("resultat",value)
            }
            if (br.intitule == "Capacité d'auto-financement") {
              value = parseFloat(
                _.find(this.sigs, { intitule: "Capacité d’autofinancement" })[
                  annee
                ]
              ).toFixed(2);
            }

            if (br.intitule == "Rentabilité globale") {
              //100 * (Résultat Net + Dotations aux amortissements et provisions exploitation + Dotations aux amortissements et provisions financières
              // + Dotations aux amortissements et provisions ex-ceptionnelles + Valeur comptable des éléments d’actif cédés
              //- Reprises sur amortissements et provisions exploitation - Reprises sur amortissements et provisions financières
              //- Reprises sur amortissements et provisions exceptionnels – Produits de cession des éléments d’actif – Q/P de subv.invt virée au résultat)
              // / (Chiffre d’affaires + Subventions)
              resultat_net = _.find(this.sigs, { intitule: "Résultat Net" })[
                annee
              ];

              // console.log('resultat_net',resultat_net)
              dotations_aux_amortissements_et_provisions_exploitation = _.find(
                this.cbs,
                {
                  intitule:
                    "Dotations aux amortissements et provisions d'exploitation",
                }
              )[annee];

              // console.log('dotations_aux_amortissements_et_provisions_exploitation',dotations_aux_amortissements_et_provisions_exploitation)

              dotations_aux_amortissements_et_provisions_financieres = _.find(
                this.crs,
                {
                  intitule:
                    "Dotations aux amortissements et provisions financières",
                }
              )[annee];

              // console.log('dotations_aux_amortissements_et_provisions_financieres',dotations_aux_amortissements_et_provisions_financieres)

              dotations_aux_amortissements_et_provisions_exceptionnelles =
                _.find(this.crs, {
                  intitule:
                    "Dotations aux amortissements et provisions exceptionnelles",
                })[annee];

                // console.log('dotations_aux_amortissements_et_provisions_exceptionnelles',dotations_aux_amortissements_et_provisions_exceptionnelles)


              valeur_comptable_des_elements_actif_cedes = _.find(this.crs, {
                intitule: "Valeurs comptables des éléments d'actif cédés",
              })[annee];

              // console.log('valeur_comptable_des_elements_actif_cedes',valeur_comptable_des_elements_actif_cedes)


              reprises_sur_amortissements_et_provisions_exploitation = _.find(
                this.crs,
                { intitule: "Reprise sur amort. et provisions exploitation" }
              )[annee];

              // console.log('reprises_sur_amortissements_et_provisions_exploitation',reprises_sur_amortissements_et_provisions_exploitation)


              reprises_sur_amortissements_et_provisions_financieres = _.find(
                this.crs,
                {
                  intitule:
                    "Reprises sur amortissements et provisions financières",
                }
              )[annee];

              // console.log('reprises_sur_amortissements_et_provisions_financieres',reprises_sur_amortissements_et_provisions_financieres)


              reprises_sur_amortissements_et_provisions_exceptionnels = _.find(
                this.crs,
                {
                  intitule:
                    "Reprise sur amortissements et provisions exceptionnelles",
                }
              )[annee];

              // console.log('reprises_sur_amortissements_et_provisions_exceptionnels',reprises_sur_amortissements_et_provisions_exceptionnels)

              produits_de_cession_des_elements_actif = _.find(this.crs, {
                intitule: "Produits de cession des éléments d'actif",
              })[annee];

              // console.log('produits_de_cession_des_elements_actif',produits_de_cession_des_elements_actif)

              qp_de_subv_invt_viree_au_resultat = _.find(this.crs, {
                intitule: "Q/P de subv. Invt virée au résultat",
              })[annee];
              
              // console.log('qp_de_subv_invt_viree_au_resultat',qp_de_subv_invt_viree_au_resultat)

              ca = _.find(this.sigs, { intitule: "Chiffre d'affaires" })[annee];
              // console.log('ca',ca)

              subventions = _.find(this.pbs, { intitule: "Subventions" })[
                annee
              ];
              // console.log('subventions',subventions)

              

              if (parseFloat(ca) + parseFloat(subventions) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(100*
                  (parseFloat(resultat_net) +
                    parseFloat(
                      dotations_aux_amortissements_et_provisions_exploitation
                    ) +
                    parseFloat(
                      dotations_aux_amortissements_et_provisions_financieres
                    ) +
                    parseFloat(
                      dotations_aux_amortissements_et_provisions_exceptionnelles
                    ) +
                    parseFloat(valeur_comptable_des_elements_actif_cedes) -
                    parseFloat(
                      reprises_sur_amortissements_et_provisions_exploitation
                    ) -
                    parseFloat(
                      reprises_sur_amortissements_et_provisions_financieres
                    ) -
                    parseFloat(
                      reprises_sur_amortissements_et_provisions_exceptionnels
                    ) -
                    parseFloat(produits_de_cession_des_elements_actif) -
                    parseFloat(qp_de_subv_invt_viree_au_resultat)) /
                    (parseFloat(ca) + parseFloat(subventions))
                ).toFixed(2);
              }
            }

            if (br.intitule == "Rentabilité financière") {
              // (Résultat Net  / Capitaux propres)
              resultat_net = _.find(this.sigs, { intitule: "Résultat Net" })[
                annee
              ];
              capitaux_propres = _.find(this.bds, {
                intitule: "Capitaux Propres",
              })[annee];

              if (capitaux_propres == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = (
                  (100 * parseFloat(resultat_net)) /
                  parseFloat(capitaux_propres)
                ).toFixed(2);
              }
            }

            if (br.intitule == "Solvabilité") {
              //(Capitaux propres  / Total bilan (passif))
              capitaux_propres = _.find(this.bds, {
                intitule: "Capitaux Propres",
              })[annee];
              total_bilan_passif = _.find(this.bds, {
                intitule: "TOTAL BILAN PASSIF",
              })[annee];

              if (parseFloat(total_bilan_passif) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (100 * parseFloat(capitaux_propres)) /
                    parseFloat(total_bilan_passif)
                ).toFixed(2);
              }
            }

            if (br.intitule == "Capacité d'endettement structurel") {
              // (Capitaux propres / Dettes MLT)
              capitaux_propres = _.find(this.bds, {
                intitule: "Capitaux Propres",
              })[annee];
              dettes_mlt = _.find(this.bds, { intitule: "Dettes MLT" })[annee];

              if (parseFloat(dettes_mlt) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  100 *
                    parseFloat(
                      parseFloat(capitaux_propres) / parseFloat(dettes_mlt)
                    )
                ).toFixed(2);
              }
            }
            if (br.intitule == "Marge de manoeuvre d'endettement") {
              // Capitaux propres - Dettes MLT
              capitaux_propres = _.find(this.bds, {
                intitule: "Capitaux Propres",
              })[annee];
              dettes_mlt = _.find(this.bds, { intitule: "Dettes MLT" })[annee];

              value = parseFloat(
                parseFloat(capitaux_propres) - parseFloat(dettes_mlt)
              ).toFixed(2);
            }

            if (br.intitule == "Capacité de remboursement des dettes MLT") {
              //  Dettes MLT
              // /
              // +Résultat Net + Dotations aux amortissements et provisions exploitation + Dotations aux amortissements et provisions financières +
              // +Dotations aux amortissements et provisions exceptionnelles + Valeur comptable des éléments d’actif cédés
              // - Reprises sur amortissements et provisions exploitation - Reprises sur amortissements et provisions finan-cières
              // - Reprises sur amortissements et provisions exceptionnels – Produits de cession des éléments d’actif – Q/P de subv.invt virée au résultat

              dettes_mlt = _.find(this.bds, { intitule: "Dettes MLT" })[annee];
              resultat_net = _.find(this.sigs, { intitule: "Résultat Net" })[
                annee
              ];
              dotations_aux_amortissements_et_provisions_exploitation = _.find(
                this.cbs,
                {
                  intitule:
                    "Dotations aux amortissements et provisions d'exploitation",
                }
              )[annee];
              dotations_aux_amortissements_et_provisions_financieres = _.find(
                this.crs,
                {
                  intitule:
                    "Dotations aux amortissements et provisions financières",
                }
              )[annee];
              dotations_aux_amortissements_et_provisions_exceptionnelles =
                _.find(this.crs, {
                  intitule:
                    "Dotations aux amortissements et provisions exceptionnelles",
                })[annee];
              valeur_comptable_des_elements_actif_cedes = _.find(this.crs, {
                intitule: "Valeurs comptables des éléments d'actif cédés",
              })[annee];
              reprises_sur_amortissements_et_provisions_exploitation = _.find(
                this.crs,
                { intitule: "Reprise sur amort. et provisions exploitation" }
              )[annee];
              reprises_sur_amortissements_et_provisions_financieres = _.find(
                this.crs,
                {
                  intitule:
                    "Reprises sur amortissements et provisions financières",
                }
              )[annee];
              reprises_sur_amortissements_et_provisions_exceptionnels = _.find(
                this.crs,
                {
                  intitule:
                    "Reprise sur amortissements et provisions exceptionnelles",
                }
              )[annee];
              produits_de_cession_des_elements_actif = _.find(this.crs, {
                intitule: "Produits de cession des éléments d'actif",
              })[annee];
              qp_de_subv_invt_viree_au_resultat = _.find(this.crs, {
                intitule: "Q/P de subv. Invt virée au résultat",
              })[annee];

              if (
                parseFloat(resultat_net) +
                  parseFloat(
                    dotations_aux_amortissements_et_provisions_exploitation
                  ) +
                  parseFloat(
                    dotations_aux_amortissements_et_provisions_financieres
                  ) +
                  parseFloat(
                    dotations_aux_amortissements_et_provisions_exceptionnelles
                  ) +
                  parseFloat(valeur_comptable_des_elements_actif_cedes) -
                  parseFloat(
                    reprises_sur_amortissements_et_provisions_exploitation
                  ) -
                  parseFloat(
                    reprises_sur_amortissements_et_provisions_financieres
                  ) -
                  parseFloat(
                    reprises_sur_amortissements_et_provisions_exceptionnels
                  ) -
                  parseFloat(produits_de_cession_des_elements_actif) -
                  parseFloat(qp_de_subv_invt_viree_au_resultat) ==
                0
              ) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  parseFloat(dettes_mlt) /
                    (parseFloat(resultat_net) +
                      parseFloat(
                        dotations_aux_amortissements_et_provisions_exploitation
                      ) +
                      parseFloat(
                        dotations_aux_amortissements_et_provisions_financieres
                      ) +
                      parseFloat(
                        dotations_aux_amortissements_et_provisions_exceptionnelles
                      ) +
                      parseFloat(valeur_comptable_des_elements_actif_cedes) -
                      parseFloat(
                        reprises_sur_amortissements_et_provisions_exploitation
                      ) -
                      parseFloat(
                        reprises_sur_amortissements_et_provisions_financieres
                      ) -
                      parseFloat(
                        reprises_sur_amortissements_et_provisions_exceptionnels
                      ) -
                      parseFloat(produits_de_cession_des_elements_actif) -
                      parseFloat(qp_de_subv_invt_viree_au_resultat))
                ).toFixed(2);
              }
            }

            if (br.intitule == "Taux brut d'endettement financier") {
              // 100 * Dettes MLT / Capitaux propres
              capitaux_propres = _.find(this.bds, {
                intitule: "Capitaux Propres",
              })[annee];
              dettes_mlt = _.find(this.bds, { intitule: "Dettes MLT" })[annee];

              if (parseFloat(capitaux_propres) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  100 *
                    parseFloat(
                      parseFloat(dettes_mlt) / parseFloat(capitaux_propres)
                    )
                ).toFixed(2);
              }
            }

            if (br.intitule == "Indépendance financière") {
              // (Capitaux propres / (Capitaux propres + Dettes MLT)
              capitaux_propres = _.find(this.bds, {
                intitule: "Capitaux Propres",
              })[annee];
              dettes_mlt = _.find(this.bds, { intitule: "Dettes MLT" })[annee];

              if (parseFloat(capitaux_propres) + parseFloat(dettes_mlt) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (100 * parseFloat(capitaux_propres)) /
                    (parseFloat(capitaux_propres) + parseFloat(dettes_mlt))
                ).toFixed(2);
              }
            }

            if (br.intitule == "Couverture des capitaux investis") {
              // (Capitaux propres + Dettes MLT)
              // /
              // (Actif net immobilisé + Stocks + Avances et acomptes versés sur commandes + Créances + Autres (actif)
              // – Avces et acptes reçus sur comdes – Fournisseurs – Dettes fiscales et sociales – Autres dettes CT – Autres (passif))

              capitaux_propres = _.find(this.bds, {
                intitule: "Capitaux Propres",
              })[annee];
              dettes_mlt = _.find(this.bds, { intitule: "Dettes MLT" })[annee];
              actif_net_immobilise = _.find(this.bds, {
                intitule: "Actif Net immobilisé",
              })[annee];
              stocks = _.find(this.bds, { intitule: "Stocks" })[annee];
              av_et_acomptes_verses_sur_comde = _.find(this.bds, {
                intitule: "Avances et acomptes versés sur commandes",
              })[annee];
              creances = _.find(this.bds, { intitule: "Créances" })[annee];
              autres_actif = _.find(this.bds, {
                intitule: "Autres",
                category: "TOTAL BILAN ACTIF",
                rang: 2,
              })[annee]; // attention
              avces_et_acptes_recus_sur_comdes = _.find(this.bds, {
                intitule: "Avances et acomptes reçus sur commandes",
              })[annee];
              fournisseurs = _.find(this.bds, { intitule: "Fournisseurs" })[
                annee
              ];
              dettes_fiscales_et_sociales = _.find(this.bds, {
                intitule: "Dettes fiscales et sociales",
              })[annee];
              autres_dettes_CT = _.find(this.bds, {
                intitule: "Autres dettes CT",
              })[annee];
              autres_passif = _.find(this.bds, {
                intitule: "Autres",
                category: "TOTAL BILAN PASSIF",
                rang: 2,
              })[annee]; // attention

              if (
                parseFloat(actif_net_immobilise) +
                  parseFloat(stocks) +
                  parseFloat(av_et_acomptes_verses_sur_comde) +
                  parseFloat(creances) +
                  parseFloat(autres_actif) -
                  parseFloat(avces_et_acptes_recus_sur_comdes) -
                  parseFloat(fournisseurs) -
                  parseFloat(dettes_fiscales_et_sociales) -
                  parseFloat(autres_dettes_CT) -
                  parseFloat(autres_passif) ==
                0
              ) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (100 *
                    (parseFloat(capitaux_propres) + parseFloat(dettes_mlt))) /
                    (parseFloat(actif_net_immobilise) +
                      parseFloat(stocks) +
                      parseFloat(av_et_acomptes_verses_sur_comde) +
                      parseFloat(creances) +
                      parseFloat(autres_actif) -
                      parseFloat(avces_et_acptes_recus_sur_comdes) -
                      parseFloat(fournisseurs) -
                      parseFloat(dettes_fiscales_et_sociales) -
                      parseFloat(autres_dettes_CT) -
                      parseFloat(autres_passif))
                ).toFixed(2);
              }
            }

            if (br.intitule == "Immobilisation de l'actif") {
              // 100 * Somme des classes 2 / Total bilan (actif)
              total_bilan_actif = _.find(this.bds, {
                intitule: "TOTAL BILAN ACTIF",
              })[annee];

              if (parseFloat(total_bilan_actif) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  100 *
                    parseFloat(
                      parseFloat(this.somme_2[annee]) /
                        parseFloat(total_bilan_actif)
                    )
                ).toFixed(2);
              }
            }

            if (br.intitule == "Intensité capitalistique") {
              //"100 * Somme des classes 2 débitrices / Chiffre d’affaires"
              ca = _.find(this.sigs, { intitule: "Chiffre d'affaires" })[annee];

              if (parseFloat(ca) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  100 *
                    parseFloat(
                      parseFloat(this.somme_2D[annee]) / parseFloat(ca)
                    )
                ).toFixed(2);
              }
            }

            if (br.intitule == "Vétusté des investissements") {
              // 100 * (Somme 21 – abs(Somme 281) – abs(Somme 291)) / (Somme 21 débiteur)

              if (parseFloat(this.somme_21D[annee]) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(100*
                  (parseFloat(this.somme_21[annee]) -
                    Math.abs(parseFloat(this.somme_281[annee])) -
                    Math.abs(parseFloat(this.somme_291[annee]))) /
                    parseFloat(this.somme_21D[annee])
                ).toFixed(2);
              }
            }

            if (br.intitule == "Financement des immobilisations") {
              //(Capitaux propres + Dettes MLT) / Actif net immobilisé

              capitaux_propres = _.find(this.bds, {
                intitule: "Capitaux Propres",
              })[annee];
              dettes_mlt = _.find(this.bds, { intitule: "Dettes MLT" })[annee];
              actif_net_immobilise = _.find(this.bds, {
                intitule: "Actif Net immobilisé",
              })[annee];

              if (parseFloat(actif_net_immobilise) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (100 *
                    (parseFloat(capitaux_propres) + parseFloat(dettes_mlt))) /
                    parseFloat(actif_net_immobilise)
                ).toFixed(2);
              }
            }

            if (br.intitule == "Valeur relative du FdR") {
              // 360 X [ (Capitaux propres + Provisions et fonds dédiés + Dettes MLT – Actif net immobilisé – Capital souscrit non appelé & souscrit, appelé, non versé)
              // /
              // Chiffre d’affaires]

              capitaux_propres = _.find(this.bds, {
                intitule: "Capitaux Propres",
              })[annee];
              provisions_et_fonds_dedies = _.find(this.bds, {
                intitule: "Provisions et fonds dédiés",
              })[annee];
              dettes_mlt = _.find(this.bds, { intitule: "Dettes MLT" })[annee];
              actif_net_immobilise = _.find(this.bds, {
                intitule: "Actif Net immobilisé",
              })[annee];
              capital_souscrit_non_appele_souscrit_appele_verse = _.find(
                this.bds,
                {
                  intitule:
                    "Capital souscrit non appelé & souscrit, appelé non versé",
                }
              )[annee]; // a confirmer !!!
              ca = _.find(this.sigs, { intitule: "Chiffre d'affaires" })[annee];

              // !!! la periode d'exercice n'est valable que pour le permier exercice !!!  cf recette visio du 06/06/2024
              // si l'annee de l'analyse est > l'année de fin d'exercice => periode exercice = 365

              if(annee > this.$moment(this.selectedClient.fin_exercice, "YYYY-MM-DD").year()){
                periode_exercice = 365;
              }
              else{
              periode_exercice =
                this.$moment(
                  this.selectedClient.fin_exercice,
                  "YYYY-MM-DD"
                ).diff(
                  this.$moment(
                    this.selectedClient.debut_exercice,
                    "YYYY-MM-DD"
                  ),
                  "days"
                ) + 1;
              }

              if (
                this.selectedClient.fin_exercice == null ||
                this.selectedClient.debut_exercice == null
              ) {
                value =
                  "<span style='color:red'>Période d'exercice non paramétrée pour le cient</span>";
              } else if (parseFloat(ca) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (parseFloat(periode_exercice) *
                    (parseFloat(capitaux_propres) +
                      parseFloat(provisions_et_fonds_dedies) +
                      parseFloat(dettes_mlt) -
                      parseFloat(actif_net_immobilise) -
                      parseFloat(
                        capital_souscrit_non_appele_souscrit_appele_verse
                      ))) /
                    parseFloat(ca)
                ).toFixed(2);

                // console log
                /*
                                console.log("periode_exercice en jour X [ (Capitaux propres + Provisions et fonds dédiés + Dettes MLT – Actif net immobilisé – Capital souscrit non appelé & souscrit, appelé, non versé)/Chiffre d’affaires]")

                                console.log(parseFloat(periode_exercice) + "X [(" + parseFloat(capitaux_propres) + "+" + parseFloat(provisions_et_fonds_dedies) + "+" + parseFloat(dettes_mlt) + "-" + parseFloat(actif_net_immobilise) + "-" + parseFloat(capital_souscrit_non_appele_souscrit_appele_verse) + ") / " + parseFloat(ca) + "]")

                                console.log(parseFloat(periode_exercice) * (parseFloat(capitaux_propres)
                                  + parseFloat(provisions_et_fonds_dedies)
                                  + parseFloat(dettes_mlt)
                                  - parseFloat(actif_net_immobilise)
                                  - parseFloat(capital_souscrit_non_appele_souscrit_appele_verse)) / parseFloat(ca))

                                console.log("periode_exercice: " + parseFloat(periode_exercice))
                                console.log("capitaux_propres: " + parseFloat(capitaux_propres))
                                console.log("provisions_et_fonds_dedies: " + parseFloat(provisions_et_fonds_dedies))
                                console.log("dettes_mlt: " + parseFloat(dettes_mlt))
                                console.log("actif_net_immobilise: " + parseFloat(actif_net_immobilise))
                                console.log("capital_souscrit_non_appele_souscrit_appele_verse: " + parseFloat(capital_souscrit_non_appele_souscrit_appele_verse))
                                console.log("CA :" + parseFloat(ca))
                                */
              }
            }

            if (br.intitule == "Liquidité de l'actif") {
              // (Stocks + Avances et acomptes versés sur commandes + Créances + Trésorerie active – Trésorerie passive) / Total bilan (actif)
              stocks = _.find(this.bds, { intitule: "Stocks" })[annee];
              av_et_acomptes_verses_sur_comde = _.find(this.bds, {
                intitule: "Avances et acomptes versés sur commandes",
              })[annee];
              creances = _.find(this.bds, { intitule: "Créances" })[annee];
              tresorerie_active = _.find(this.bds, {
                intitule: "Trésorerie active",
              })[annee];
              tresorerie_passive = _.find(this.bds, {
                intitule: "Trésorerie passive",
              })[annee];
              total_bilan_actif = _.find(this.bds, {
                intitule: "TOTAL BILAN ACTIF",
              })[annee];

              if (parseFloat(total_bilan_actif) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (100 *
                    (parseFloat(stocks) +
                      parseFloat(av_et_acomptes_verses_sur_comde) +
                      parseFloat(creances) +
                      parseFloat(tresorerie_active) -
                      parseFloat(tresorerie_passive))) /
                    parseFloat(total_bilan_actif)
                ).toFixed(2);
              }
            }

            if (br.intitule == "Liquidité générale") {
              //  Stocks + Avances et acomptes versés sur commandes + Créances + Trésorerie active – Trésorerie passive
              // /
              // Avces et acptes reçus sur comde + Fournisseurs + Dettes fiscales et sociales + Autres dettes CT

              stocks = _.find(this.bds, { intitule: "Stocks" })[annee];
              av_et_acomptes_verses_sur_comde = _.find(this.bds, {
                intitule: "Avances et acomptes versés sur commandes",
              })[annee];
              creances = _.find(this.bds, { intitule: "Créances" })[annee];
              tresorerie_active = _.find(this.bds, {
                intitule: "Trésorerie active",
              })[annee];
              tresorerie_passive = _.find(this.bds, {
                intitule: "Trésorerie passive",
              })[annee];
              avces_et_acptes_recus_sur_comdes = _.find(this.bds, {
                intitule: "Avances et acomptes reçus sur commandes",
              })[annee];
              fournisseurs = _.find(this.bds, { intitule: "Fournisseurs" })[
                annee
              ];
              dettes_fiscales_et_sociales = _.find(this.bds, {
                intitule: "Dettes fiscales et sociales",
              })[annee];
              autres_dettes_CT = _.find(this.bds, {
                intitule: "Autres dettes CT",
              })[annee];

              if (
                parseFloat(avces_et_acptes_recus_sur_comdes) +
                  parseFloat(fournisseurs) +
                  parseFloat(dettes_fiscales_et_sociales) +
                  parseFloat(autres_dettes_CT) ==
                0
              ) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (parseFloat(stocks) +
                    parseFloat(av_et_acomptes_verses_sur_comde) +
                    parseFloat(creances) +
                    parseFloat(tresorerie_active) -
                    parseFloat(tresorerie_passive)) /
                    (parseFloat(avces_et_acptes_recus_sur_comdes) +
                      parseFloat(fournisseurs) +
                      parseFloat(dettes_fiscales_et_sociales) +
                      parseFloat(autres_dettes_CT))
                ).toFixed(2);
              }
            }

            if (br.intitule == "Liquidité immédiate") {
              // (Trésorerie active – Trésorerie passive) / (Avances et acomptes reçus sur commandes + Fournisseurs + Dettes fiscales et sociales + Autres dettes CT)
              tresorerie_active = _.find(this.bds, {
                intitule: "Trésorerie active",
              })[annee];
              tresorerie_passive = _.find(this.bds, {
                intitule: "Trésorerie passive",
              })[annee];
              avces_et_acptes_recus_sur_comdes = _.find(this.bds, {
                intitule: "Avances et acomptes reçus sur commandes",
              })[annee];
              fournisseurs = _.find(this.bds, { intitule: "Fournisseurs" })[
                annee
              ];
              dettes_fiscales_et_sociales = _.find(this.bds, {
                intitule: "Dettes fiscales et sociales",
              })[annee];
              autres_dettes_CT = _.find(this.bds, {
                intitule: "Autres dettes CT",
              })[annee];

              if (
                parseFloat(avces_et_acptes_recus_sur_comdes) +
                  parseFloat(fournisseurs) +
                  parseFloat(dettes_fiscales_et_sociales) +
                  parseFloat(autres_dettes_CT) ==
                0
              ) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (parseFloat(tresorerie_active) -
                    parseFloat(tresorerie_passive)) /
                    (parseFloat(avces_et_acptes_recus_sur_comdes) +
                      parseFloat(fournisseurs) +
                      parseFloat(dettes_fiscales_et_sociales) +
                      parseFloat(autres_dettes_CT))
                ).toFixed(2);
              }
            }

            if (br.intitule == "Couverture des frais fixes") {
              //[360 X (Trésorerie active – Trésorerie passive) / Charges fixes]
              tresorerie_active = _.find(this.bds, {
                intitule: "Trésorerie active",
              })[annee];
              tresorerie_passive = _.find(this.bds, {
                intitule: "Trésorerie passive",
              })[annee];
              charges_fixes = _.find(this.cbs, { intitule: "Charges fixes" })[
                annee
              ];

              // !!! la periode d'exercice n'est valable que pour le permier exercice !!!  cf recette visio du 06/06/2024
              // si l'annee de l'analyse est > l'année de fin d'exercice => periode exercice = 365

              if(annee > this.$moment(this.selectedClient.fin_exercice, "YYYY-MM-DD").year()){
                periode_exercice = 365;
              }              
              else{
              periode_exercice =
                this.$moment(
                  this.selectedClient.fin_exercice,
                  "YYYY-MM-DD"
                ).diff(
                  this.$moment(
                    this.selectedClient.debut_exercice,
                    "YYYY-MM-DD"
                  ),
                  "days"
                ) + 1;
              }
              if (
                this.selectedClient.fin_exercice == null ||
                this.selectedClient.debut_exercice == null
              ) {
                value =
                  "<span style='color:red'>Période d'exercice non paramétrée pour le cient</span>";
              } else if (parseFloat(charges_fixes) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (parseFloat(periode_exercice) *
                    (parseFloat(tresorerie_active) -
                      parseFloat(tresorerie_passive))) /
                    parseFloat(charges_fixes)
                ).toFixed(2);
              }
              /*
                            console.log("periode_exercice : " + parseFloat(periode_exercice))
                            console.log("tresorerie_active : " + parseFloat(tresorerie_active))
                            console.log("tresorerie_passive : " + parseFloat(tresorerie_passive))
                            console.log("charges_fixes : " + parseFloat(charges_fixes))

                            //[360 X (Trésorerie active – Trésorerie passive) / Charges fixes] 

                            console.log(parseFloat(periode_exercice) + " * (" + parseFloat(tresorerie_active) + "-" + parseFloat(tresorerie_passive) + ") / " + parseFloat(charges_fixes))
                            console.log(parseFloat(periode_exercice) + " * (" + parseFloat(parseFloat(tresorerie_active) - parseFloat(tresorerie_passive)) + ") / " + parseFloat(charges_fixes))
                            console.log(parseFloat(parseFloat(periode_exercice) * parseFloat(parseFloat(tresorerie_active) - parseFloat(tresorerie_passive))) + " / " + parseFloat(charges_fixes))
                            console.log(parseFloat((parseFloat(parseFloat(periode_exercice) * parseFloat(parseFloat(tresorerie_active) - parseFloat(tresorerie_passive)))) / parseFloat(charges_fixes)))
                            */
            }

            if (br.intitule == "Valeur relative du BFR") {
              // 360 X (Stocks + Avances et acomptes versés sur commandes + Créances + Autres (actif)
              // – Avces et acptes reçus sur comdes – Fournisseurs – Dettes fiscales et sociales – Autres dettes CT – Autres (passif))
              // /
              //  Chiffre d’affaires

              stocks = _.find(this.bds, { intitule: "Stocks" })[annee];
              av_et_acomptes_verses_sur_comde = _.find(this.bds, {
                intitule: "Avances et acomptes versés sur commandes",
              })[annee];
              creances = _.find(this.bds, { intitule: "Créances" })[annee];
              autres_actif = _.find(this.bds, {
                intitule: "Autres",
                category: "TOTAL BILAN ACTIF",
              })[annee];
              avces_et_acptes_recus_sur_comdes = _.find(this.bds, {
                intitule: "Avances et acomptes reçus sur commandes",
              })[annee];
              fournisseurs = _.find(this.bds, { intitule: "Fournisseurs" })[
                annee
              ];
              dettes_fiscales_et_sociales = _.find(this.bds, {
                intitule: "Dettes fiscales et sociales",
              })[annee];
              autres_dettes_CT = _.find(this.bds, {
                intitule: "Autres dettes CT",
              })[annee];
              autres_passif = _.find(this.bds, {
                intitule: "Autres",
                category: "TOTAL BILAN PASSIF",
              })[annee];
              ca = _.find(this.sigs, { intitule: "Chiffre d'affaires" })[annee];

              // !!! la periode d'exercice n'est valable que pour le permier exercice !!!  cf recette visio du 06/06/2024
              // si l'annee de l'analyse est > l'année de fin d'exercice => periode exercice = 365

              if(annee > this.$moment(this.selectedClient.fin_exercice, "YYYY-MM-DD").year()){
                periode_exercice = 365;
              }              
              else{
              periode_exercice =
                this.$moment(
                  this.selectedClient.fin_exercice,
                  "YYYY-MM-DD"
                ).diff(
                  this.$moment(
                    this.selectedClient.debut_exercice,
                    "YYYY-MM-DD"
                  ),
                  "days"
                ) + 1;
              }
              if (
                this.selectedClient.fin_exercice == null ||
                this.selectedClient.debut_exercice == null
              ) {
                value =
                  "<span style='color:red'>Période d'exercice non paramétrée pour le cient</span>";
              } else if (parseFloat(ca) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value = parseFloat(
                  (parseFloat(periode_exercice) *
                    (parseFloat(stocks) +
                      parseFloat(av_et_acomptes_verses_sur_comde) +
                      parseFloat(creances) +
                      parseFloat(autres_actif) -
                      parseFloat(avces_et_acptes_recus_sur_comdes) -
                      parseFloat(fournisseurs) -
                      parseFloat(dettes_fiscales_et_sociales) -
                      parseFloat(autres_dettes_CT) -
                      parseFloat(autres_passif))) /
                    parseFloat(ca)
                ).toFixed(2);
              }

              // console log
              /*
                            console.log("periode_exercice : " + parseFloat(periode_exercice))
                            console.log("stocks : " + parseFloat(stocks))
                            console.log("av_et_acomptes_verses_sur_comde : " + parseFloat(av_et_acomptes_verses_sur_comde))
                            console.log("creances : " + parseFloat(creances))
                            console.log("autres_actif : " + parseFloat(autres_actif))
                            console.log("avces_et_acptes_recus_sur_comdes : " + parseFloat(avces_et_acptes_recus_sur_comdes))
                            console.log("fournisseurs : " + parseFloat(fournisseurs))
                            console.log("dettes_fiscales_et_sociales : " + parseFloat(dettes_fiscales_et_sociales))
                            console.log("autres_dettes_CT : " + parseFloat(autres_dettes_CT))
                            console.log("autres_passif : " + parseFloat(autres_passif))
                            console.log("ca : " + parseFloat(ca))
                            */
            }

            if (br.intitule == "Crédit clients en jours") {
              //période * Clients et compte rattachés / ((Ventes + Prestations)*taux tva
              // ???? Prestations = prestations de service (CR) ????
              clients_et_compte_rattaches = _.find(this.bds, {
                intitule: "Clients et comptes rattachés",
              })[annee];
              ventes = _.find(this.crs, { intitule: "Ventes" })[annee];
              prestations_de_service = _.find(this.crs, {
                intitule: "Prestations de services",
              })[annee];

              // !!! la periode d'exercice n'est valable que pour le permier exercice !!!  cf recette visio du 06/06/2024
              // si l'annee de l'analyse est > l'année de fin d'exercice => periode exercice = 365

              if(annee > this.$moment(this.selectedClient.fin_exercice, "YYYY-MM-DD").year()){
                periode_exercice = 365;
              }
              
              else{
              periode_exercice =
                this.$moment(
                  this.selectedClient.fin_exercice,
                  "YYYY-MM-DD"
                ).diff(
                  this.$moment(
                    this.selectedClient.debut_exercice,
                    "YYYY-MM-DD"
                  ),
                  "days"
                ) + 1;
              }

              if (
                parseFloat(ventes) + parseFloat(prestations_de_service) ==
                0
              ) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                // si le client a une tva
                if (this.selectedClient.tva != null) {
                  value = parseFloat(
                    parseFloat(
                      parseFloat(periode_exercice) *
                        parseFloat(clients_et_compte_rattaches)
                    ) /
                      parseFloat(
                        parseFloat(
                          parseFloat(ventes) +
                            parseFloat(prestations_de_service)
                        ) *
                          (1 + parseFloat(this.selectedClient.tva) / 100)
                      )
                  ).toFixed(2);
                } else {
                  value = parseFloat(
                    (parseFloat(periode_exercice) *
                      parseFloat(clients_et_compte_rattaches)) /
                      parseFloat(
                        parseFloat(ventes) + parseFloat(prestations_de_service)
                      )
                  ).toFixed(2);
                }
              }
            }

            if (br.intitule == "Crédit fournisseurs en jours") {
              //periode exercice * Fournisseurs / ((Achats + Autres achats et charges externes) * (1+ TVA/100))  // ajout TVA suite a recette avril 2024 puis modif formule cf mail du 18/12

              let tva = this.selectedClient.tva
              if(tva==null){                
                value = "<span style='color:red'>Param. TVA manquant</span>"
              }
              else{

              fournisseurs = _.find(this.bds, { intitule: "Fournisseurs" })[
                annee
              ];
              console.log('fournisseurs',fournisseurs)
              achats = _.find(this.crs, { intitule: "Achats" })[annee];
              console.log('achats',achats)
              autres_achats_et_charges_externes = _.find(this.crs, {
                intitule: "Autres achats et charges externes",
              })[annee];
              console.log('autres_achats_et_charges_externes',autres_achats_et_charges_externes)

              // !!! la periode d'exercice n'est valable que pour le permier exercice !!!  cf recette visio du 06/06/2024
              // si l'annee de l'analyse est > l'année de fin d'exercice => periode exercice = 365

              if(annee > this.$moment(this.selectedClient.fin_exercice, "YYYY-MM-DD").year()){
                periode_exercice = 365;
              }
              else{
              periode_exercice =
                this.$moment(
                  this.selectedClient.fin_exercice,
                  "YYYY-MM-DD"
                ).diff(
                  this.$moment(
                    this.selectedClient.debut_exercice,
                    "YYYY-MM-DD"
                  ),
                  "days"
                ) + 1;
              }

              //console.log('periode_exercice',periode_exercice)
              //console.log("tva",tva)

              if (
                parseFloat(1+ parseFloat(parseFloat(tva)/100)) * (parseFloat(achats) + parseFloat(autres_achats_et_charges_externes)) == 0
              ) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value =
                  parseFloat(periode_exercice) *
                  parseFloat(
                    parseFloat(fournisseurs) /
                    (
                        parseFloat(
                        parseFloat(achats) +
                        parseFloat(autres_achats_et_charges_externes)
                        )
                      )*parseFloat( 1+ parseFloat(tva)/100)
                  ).toFixed(2);
              }}
            }

            if (br.intitule == "Stockage des matières 1ères en jours") {
              //((Matières premières et autres approvisionnements + En-cours de production (biens et services) + Marchandises) / (Achats + Variation de stocks ))* periode_exercice

              matieres_premieres_et_autres_approvisionnements = _.find(
                this.bds,
                { intitule: "Matières premières et autres approvisionnements" }
              )[annee];
              en_cours_de_production_biens_et_services = _.find(this.bds, {
                intitule: "En-cours de production (biens et services)",
              })[annee];
              marchandises = _.find(this.bds, { intitule: "Marchandises" })[
                annee
              ];
              achats = _.find(this.crs, { intitule: "Achats" })[annee];
              variation_stock = _.find(this.crs, {
                intitule: "Variation de stocks",
              })[annee];

              // !!! la periode d'exercice n'est valable que pour le permier exercice !!!  cf recette visio du 06/06/2024
              // si l'annee de l'analyse est > l'année de fin d'exercice => periode exercice = 365

              if(annee > this.$moment(this.selectedClient.fin_exercice, "YYYY-MM-DD").year()){
                periode_exercice = 365;
              }              
              else{
              periode_exercice =
                this.$moment(
                  this.selectedClient.fin_exercice,
                  "YYYY-MM-DD"
                ).diff(
                  this.$moment(
                    this.selectedClient.debut_exercice,
                    "YYYY-MM-DD"
                  ),
                  "days"
                ) + 1;
              }

              if (parseFloat(achats) + parseFloat(variation_stock) == 0) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value =
                  parseFloat(periode_exercice) *
                  parseFloat(
                    parseFloat(
                      parseFloat(
                        matieres_premieres_et_autres_approvisionnements
                      ) +
                        parseFloat(en_cours_de_production_biens_et_services) +
                        parseFloat(marchandises)
                    ) /
                      parseFloat(
                        parseFloat(achats) + parseFloat(variation_stock)
                      )
                  ).toFixed(2);
              }
            }

            if (br.intitule == "Stockage des produits finis en jours") {
              //( Produits intermédiaires et finis / (Ventes + Production stockée))*365

              produits_intermediaires_et_finis = _.find(this.bds, {
                intitule: "Produits intermédiaires et finis",
              })[annee];
              production_stockee_immobilisee = _.find(this.sigs, {
                intitule: "Production stockée / immobilisée",
              })[annee];
              ventes = _.find(this.crs, { intitule: "Ventes" })[annee];

              // !!! la periode d'exercice n'est valable que pour le permier exercice !!!  cf recette visio du 06/06/2024
              // si l'annee de l'analyse est > l'année de fin d'exercice => periode exercice = 365

              if(annee > this.$moment(this.selectedClient.fin_exercice, "YYYY-MM-DD").year()){
                periode_exercice = 365;
              }
              
              else{

              periode_exercice =
                this.$moment(
                  this.selectedClient.fin_exercice,
                  "YYYY-MM-DD"
                ).diff(
                  this.$moment(
                    this.selectedClient.debut_exercice,
                    "YYYY-MM-DD"
                  ),
                  "days"
                ) + 1;
              }
              
              if (isNaN(periode_exercice)) {
                value =
                  "<span style='color:red'>Période d'exercice non paramétrée</span>";
              } else if (
                parseFloat(ventes) +
                  parseFloat(production_stockee_immobilisee) ==
                0
              ) {
                value = 0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
              } else {
                value =
                  parseFloat(periode_exercice) *
                  parseFloat(
                    parseFloat(parseFloat(produits_intermediaires_et_finis)) /
                      parseFloat(
                        parseFloat(ventes) +
                          parseFloat(production_stockee_immobilisee)
                      )
                  ).toFixed(2);
              }
            }

            years_values[annee] = value;
          });

          this.brs[key] = Object.assign({}, this.brs[key], years_values, {
            show: "1",
          });
        });

        resolve();
      });
    },

    /*
     * Bilan fonctionnel
     */

    set_bfs() {
      this.bf_loading = true;

      return new Promise((resolve, reject) => {
        var years_values;
        var value;

        _.forEach(this.bfs, (bf, key) => {
          years_values = {};

          _.forEach(this.diagnostic_years, (annee) => {
            value = 0;
            if (bf.ajouter) {
              _.forEach(bf.ajouter, (ajout) => {
                var ajouter = 0;

                if (_.find(this[ajout.base], { intitule: ajout.intitule })) {
                  ajouter = _.find(this[ajout.base], {
                    intitule: ajout.intitule,
                  })[annee];
                }

                //differenciation entre "autres" passif et actif
                if (ajout.type == "actif") {
                  if (
                    _.find(this[ajout.base], {
                      intitule: ajout.intitule,
                      category: "TOTAL BILAN ACTIF",
                    })
                  ) {
                    ajouter = _.find(this[ajout.base], {
                      intitule: ajout.intitule,
                      category: "TOTAL BILAN ACTIF",
                    })[annee];
                  }
                }
                if (ajout.type == "passif") {
                  if (
                    _.find(this[ajout.base], {
                      intitule: ajout.intitule,
                      category: "TOTAL BILAN PASSIF",
                    })
                  ) {
                    ajouter = _.find(this[ajout.base], {
                      intitule: ajout.intitule,
                      category: "TOTAL BILAN PASSIF",
                    })[annee];
                  }
                }

                value = parseFloat(
                  parseFloat(value) + parseFloat(ajouter)
                ).toFixed(2);
              });
            }

            if (bf.soustraire) {
              _.forEach(bf.soustraire, (soustraction) => {
                var soustraire = 0;
                if (
                  _.find(this[soustraction.base], {
                    intitule: soustraction.intitule,
                  })
                ) {
                  soustraire = _.find(this[soustraction.base], {
                    intitule: soustraction.intitule,
                  })[annee];
                }

                //differenciation entre "autres" passif et actif
                if (soustraction.type == "actif") {
                  if (
                    _.find(this[soustraction.base], {
                      intitule: soustraction.intitule,
                      category: "TOTAL BILAN ACTIF",
                    })
                  ) {
                    soustraire = _.find(this[soustraction.base], {
                      intitule: soustraction.intitule,
                      category: "TOTAL BILAN ACTIF",
                    })[annee];
                  }
                }
                if (soustraction.type == "passif") {
                  if (
                    _.find(this[soustraction.base], {
                      intitule: soustraction.intitule,
                      category: "TOTAL BILAN PASSIF",
                    })
                  ) {
                    soustraire = _.find(this[soustraction.base], {
                      intitule: soustraction.intitule,
                      category: "TOTAL BILAN PASSIF",
                    })[annee];
                  }
                }

                value = parseFloat(
                  parseFloat(value) - parseFloat(soustraire)
                ).toFixed(2);
              });
            }

            years_values[annee] = value;
          });

          this.bfs[key] = Object.assign({}, this.bfs[key], years_values, {
            show: "1",
          });
        });

        resolve();
      });
    },

    /*
     * Seuil de rentabilité
     */

    set_srs() {
      this.sr_loading = true;

      return new Promise((resolve, reject) => {
        var years_values;
        var value;
        var marge_sur_cout_variable = null;
        var ca = null;
        var charges_fixes = null;
        var production_stockee_immobilisee = null;
        var transfert_de_charge = null;
        var subventions = null;
        var taux_marge_cout_variable = null;

        _.forEach(this.srs, (sr, key) => {
          years_values = {};

          _.forEach(this.diagnostic_years, (annee) => {
            value = 0;
            if (sr.ajouter) {
              _.forEach(sr.ajouter, (ajout) => {
                var ajouter = 0;
                if (_.find(this[ajout.base], { intitule: ajout.intitule })) {
                  ajouter = _.find(this[ajout.base], {
                    intitule: ajout.intitule,
                  })[annee];
                }
                value = parseFloat(value) + parseFloat(ajouter);
              });
            }

            if (sr.soustraire) {
              _.forEach(sr.soustraire, (soustraction) => {
                var soustraire = 0;
                if (
                  _.find(this[soustraction.base], {
                    intitule: soustraction.intitule,
                  })
                ) {
                  soustraire = _.find(this[soustraction.base], {
                    intitule: soustraction.intitule,
                  })[annee];
                }
                value = parseFloat(value) - parseFloat(soustraire);
              });
            }

            //calcul du Taux de marge sur coût variable = 100 X Marge sur coût variable / Chiffre d'affaires

            if (sr.intitule == "Taux de marge sur coût variable") {
              value = "";

              if (_.find(this.srs, { intitule: "Marge sur coût variable" })) {
                marge_sur_cout_variable = parseFloat(
                  _.find(this.srs, { intitule: "Marge sur coût variable" })[
                    annee
                  ]
                );
              }

              if (_.find(this.sigs, { intitule: "Chiffre d'affaires" })) {
                ca = parseFloat(
                  _.find(this.sigs, { intitule: "Chiffre d'affaires" })[annee]
                );
              }

              if (ca != 0 && ca != null && marge_sur_cout_variable != null) {
                value = parseFloat(
                  (100 * marge_sur_cout_variable) / ca
                ).toFixed(2);
              } else {
                value = 0;
              }
            }

            // calcul Seuil de rentabilité net de subvention
            // 100*(Charges fixes - Production stockée/immobilisée - Transfert de charge - Subventions ) / (Taux de marge sur coût variable)

            if (sr.intitule == "Seuil de rentabilité net de subvention") {
              value = "";

              if (_.find(this.cbs, { intitule: "Charges fixes" })) {
                charges_fixes = parseFloat(
                  _.find(this.srs, { intitule: "Charges fixes" })[annee]
                );
              }

              if (
                _.find(this.sigs, {
                  intitule: "Production stockée / immobilisée",
                })
              ) {
                production_stockee_immobilisee = parseFloat(
                  _.find(this.sigs, {
                    intitule: "Production stockée / immobilisée",
                  })[annee]
                );
              }

              if (_.find(this.sigs, { intitule: "Transfert de charges" })) {
                transfert_de_charge = parseFloat(
                  _.find(this.sigs, { intitule: "Transfert de charges" })[annee]
                );
              }

              if (_.find(this.pbs, { intitule: "Subventions" })) {
                subventions = parseFloat(
                  _.find(this.pbs, { intitule: "Subventions" })[annee]
                );
              }

              if (
                _.find(this.srs, {
                  intitule: "Taux de marge sur coût variable",
                })
              ) {
                taux_marge_cout_variable = parseFloat(
                  _.find(this.srs, {
                    intitule: "Taux de marge sur coût variable",
                  })[annee]
                );
              }

              if (
                taux_marge_cout_variable != 0 &&
                taux_marge_cout_variable != 0 &&
                !isNaN(parseFloat(taux_marge_cout_variable))
              ) {
                value = parseFloat(
                  (100 *
                    (charges_fixes -
                      production_stockee_immobilisee -
                      transfert_de_charge -
                      subventions)) /
                    taux_marge_cout_variable
                ).toFixed(2);
              } else {
                value = 0;
              }

              //console.log( "Charges fixes - Production stockée/immobilisée - Transfert de charge - Subventions ) / (Taux de marge sur coût variable)")
              //console.log()
            }

            // calcul Seuil de rentabilité en budget global
            // (Charges fixes - Production stockée/immobilisée - Transfert de charge ) / (Taux de marge sur coût variable)

            if (sr.intitule == "Seuil de rentabilité en budget global") {
              value = "";
              if (_.find(this.cbs, { intitule: "Charges fixes" })) {
                charges_fixes = parseFloat(
                  _.find(this.srs, { intitule: "Charges fixes" })[annee]
                );
              }

              if (
                _.find(this.sigs, {
                  intitule: "Production stockée / immobilisée",
                })
              ) {
                production_stockee_immobilisee = parseFloat(
                  _.find(this.sigs, {
                    intitule: "Production stockée / immobilisée",
                  })[annee]
                );
              }

              if (_.find(this.sigs, { intitule: "Transfert de charges" })) {
                transfert_de_charge = parseFloat(
                  _.find(this.sigs, { intitule: "Transfert de charges" })[annee]
                );
              }

              if (
                _.find(this.srs, {
                  intitule: "Taux de marge sur coût variable",
                })
              ) {
                taux_marge_cout_variable = parseFloat(
                  _.find(this.srs, {
                    intitule: "Taux de marge sur coût variable",
                  })[annee]
                );
              }

              if (
                taux_marge_cout_variable != 0 &&
                taux_marge_cout_variable != null &&
                !isNaN(parseFloat(taux_marge_cout_variable))
              ) {
                value = parseFloat(
                  (100 *
                    (charges_fixes -
                      production_stockee_immobilisee -
                      transfert_de_charge)) /
                    taux_marge_cout_variable
                ).toFixed(2);
              } else {
                value = 0;
              }
            }

            years_values[annee] = value;
          });

          this.srs[key] = Object.assign({}, this.srs[key], years_values, {
            show: "1",
          });
        });

        resolve();
      });
    },

    /*
     * Charges / budget
     */
    set_cbs() {
      this.cb_loading = true;

      return new Promise((resolve, reject) => {
        var years_values;
        var value;

        _.forEach(this.cbs, (cb, key) => {
          if (
            cb.intitule == "Charges variables" ||
            cb.intitule == "Charges fixes"
          ) {
            return;
          } // in loadash return = continue
          years_values = {};

          _.forEach(this.diagnostic_years, (annee) => {
            value = 0;
            if (cb.ajouter) {
              _.forEach(cb.ajouter, (ajout) => {
                var ajouter = 0;
                if (_.find(this[ajout.base], { intitule: ajout.intitule })) {
                  ajouter = _.find(this[ajout.base], {
                    intitule: ajout.intitule,
                  })[annee];
                }
                value = parseFloat(value) + parseFloat(ajouter);
              });
            }

            if (cb.soustraire) {
              _.forEach(cb.soustraire, (soustraction) => {
                var soustraire = 0;
                if (
                  _.find(this[soustraction.base], {
                    intitule: soustraction.intitule,
                  })
                ) {
                  soustraire = _.find(this[soustraction.base], {
                    intitule: soustraction.intitule,
                  })[annee];
                }
                value = parseFloat(value) - parseFloat(soustraire);
              });
            }

            years_values[annee] = parseFloat(value).toFixed(2);
          });

          this.cbs[key] = Object.assign({}, this.cbs[key], years_values, {
            show: "1",
          });
        });

        resolve();
      });
    },
    /*
     * Produits / budget
     */
    set_pbs() {
      this.pb_loading = true;

      return new Promise((resolve, reject) => {
        var years_values;
        var value;

        _.forEach(this.pbs, (pb, key) => {
          years_values = {};

          _.forEach(this.diagnostic_years, (annee) => {
            value = 0;
            if (pb.ajouter) {
              _.forEach(pb.ajouter, (ajout) => {
                var ajouter = 0;
                if (_.find(this[ajout.base], { intitule: ajout.intitule })) {
                  ajouter = _.find(this[ajout.base], {
                    intitule: ajout.intitule,
                  })[annee];
                }
                value = parseFloat(value) + parseFloat(ajouter);
              });
            }

            if (pb.soustraire) {
              _.forEach(pb.soustraire, (soustraction) => {
                var soustraire = 0;
                if (
                  _.find(this[soustraction.base], {
                    intitule: soustraction.intitule,
                  })
                ) {
                  soustraire = _.find(this[soustraction.base], {
                    intitule: soustraction.intitule,
                  })[annee];
                }
                value = parseFloat(value) - parseFloat(soustraire);
              });
            }

            years_values[annee] = parseFloat(value).toFixed(2);
          });

          this.pbs[key] = Object.assign({}, this.pbs[key], years_values, {
            show: "1",
          });
        });

        resolve();
      });
    },

    /*
     * SIG
     */

    set_sigs() {
      this.sig_loading = true;

      return new Promise((resolve, reject) => {
        var years_values;
        var value;

        _.forEach(this.sigs, (sig, key) => {
          years_values = {};

          _.forEach(this.diagnostic_years, (annee) => {
            value = 0;
            if (sig.ajouter) {
              _.forEach(sig.ajouter, (ajout) => {
                var ajouter = 0;
                if (_.find(this[ajout.base], { intitule: ajout.intitule })) {
                  ajouter = _.find(this[ajout.base], {
                    intitule: ajout.intitule,
                  })[annee];
                }
                value = parseFloat(value) + parseFloat(ajouter);
              });
            }

            if (sig.soustraire) {
              _.forEach(sig.soustraire, (soustraction) => {
                var soustraire = 0;
                if (
                  _.find(this[soustraction.base], {
                    intitule: soustraction.intitule,
                  })
                ) {
                  soustraire = _.find(this[soustraction.base], {
                    intitule: soustraction.intitule,
                  })[annee];
                }
                value = parseFloat(value) - parseFloat(soustraire);
              });
            }

            years_values[annee] = parseFloat(value).toFixed(2);
          });

          this.sigs[key] = Object.assign({}, this.sigs[key], years_values, {
            show: "1",
          });
        });

        resolve();
      });
    },

    /*
     * get flux financiers
     */

    get_ffs() {
      var value;
      var signe;
      var intitule;
      var base;

      let payload = {
        client_id: this.selectedClient.id,
        annees: this.diagnostic_years,
      };

      return new Promise((resolve, reject) => {
        axios
          .post("/api/diag/analyse/flux_financiers", payload)
          .then((response) => {
            // premiere passe pour les valeurs en référence dans CR, BD ou SIG
            response.data.forEach((item) => {
              if (item["base"]) {
                this.diagnostic_years.forEach((annee) => {
                  value = 0;

                  item["base"].forEach((basecalcul) => {
                    signe = basecalcul.split("|")[0];
                    base = basecalcul.split("|")[1];
                    intitule = basecalcul.split("|")[2];

                    if (signe == "+") {
                      value =
                        value +
                        parseFloat(
                          _.find(this[base], { intitule: intitule })[annee]
                        );
                    }
                    if (signe == "-") {
                      value =
                        value -
                        parseFloat(
                          _.find(this[base], { intitule: intitule })[annee]
                        );
                    }
                  });

                  _.find(response.data, { intitule: item["intitule"] })[annee] =
                    parseFloat(value).toFixed(2);
                });
              }
            });

            //derniere passe pour le calcul des sous totaux
            response.data.forEach((item) => {
              if (item["soustotal"]) {
                this.diagnostic_years.forEach((annee) => {
                  value = 0;

                  item["soustotal"]["ajouter"].forEach((ajout) => {
                    value += parseFloat(
                      _.find(response.data, { order: parseInt(ajout) })[annee]
                    );
                  });
                  item["soustotal"]["soustraire"].forEach((soustraction) => {
                    value -= parseFloat(
                      _.find(response.data, { order: parseInt(soustraction) })[
                        annee
                      ]
                    );
                  });

                  _.find(response.data, { intitule: item["intitule"] })[annee] =
                    parseFloat(value).toFixed(2);
                  if (isNaN(parseFloat(value))) {
                    _.find(response.data, { intitule: item["intitule"] })[
                      annee
                    ] = "<span style='color:red'>Paramétrage manquant</span>";
                  }
                });
              }
            });

            this.ffs = response.data;

            _.map(this.ffs, function (obj) {
              obj.show = "1";
            });

            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    /*
     * somme selon class
     */

    get_somme(classes, type, annee) {
      let payload = {
        client_id: this.selectedClient.id,
        annee: annee,
        classes: classes,
        type: type,
      };

      return new Promise((resolve, reject) => {
        axios
          .post("/api/diag/analyse/somme", payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    /*
     * somme de class 2
     */
    get_somme_2() {
      _.forEach(this.diagnostic_years, (annee) => {
        this.get_somme([2], "solde", annee).then((resp_value) => {
          this.somme_2[annee] = resp_value;
        });
      });
    },

    /*
     * somme de class 2 debitrice
     */
    get_somme_2D() {
      _.forEach(this.diagnostic_years, (annee) => {
        this.get_somme([2], "debit", annee).then((resp_value) => {
          this.somme_2D[annee] = resp_value;
        });
      });
    },

    /*
     * somme de class 21
     */
    get_somme_21() {
      _.forEach(this.diagnostic_years, (annee) => {
        this.get_somme([21], "solde", annee).then((resp_value) => {
          this.somme_21[annee] = resp_value;
        });
      });
    },

    /*
     * somme de class 21 debiteur
     */
    get_somme_21D() {
      _.forEach(this.diagnostic_years, (annee) => {
        this.get_somme([21], "debit", annee).then((resp_value) => {
          this.somme_21D[annee] = resp_value;
        });
      });
    },

    /*
     * somme de class 281
     */

    get_somme_281() {
      _.forEach(this.diagnostic_years, (annee) => {
        this.get_somme([281], "solde", annee).then((resp_value) => {
          this.somme_281[annee] = resp_value;
        });
      });
    },

    /*
     * somme de class 291
     */

    get_somme_291() {
      _.forEach(this.diagnostic_years, (annee) => {
        this.get_somme([291], "solde", annee).then((resp_value) => {
          this.somme_291[annee] = resp_value;
        });
      });
    },

    /*
     * somme de class 7 solde
     */

    get_somme_7() {
      _.forEach(this.diagnostic_years, (annee) => {
        this.get_somme([7], "solde", annee).then((resp_value) => {
          this.somme_7[annee] = resp_value;
        });
      });
    },
    /*
     * somme de class 6 solde
     */

    get_somme_6() {
      _.forEach(this.diagnostic_years, (annee) => {
        this.get_somme([6], "solde", annee).then((resp_value) => {
          this.somme_6[annee] = resp_value;
        });
      });
    },

    /*
     * somme des charges fixe et variable
     */

    get_charges() {
      this.charges_loading = true;

      let payload = {
        client_id: this.selectedClient.id,
        annees: this.diagnostic_years,
      };

      return new Promise((resolve, reject) => {
        axios
          .post("/api/diag/analyse/charges", payload)
          .then((response) => {
            response.data.forEach((item) => {
              var obj = { intitule: item.intitule, show: "1", rang: 1 };

              this.diagnostic_years.forEach((annee) => {
                obj[annee] = item[annee];
              });

              // supression des valeurs précédentes
              // (charges varfiable et charges fixes)
              // console.log('if client changed => supression des valeurs précédentes de charges varfiable et charges fixes');
              // console.log('valeurs de cbs avant suppression');
              // console.log(this.cbs);

              // console.log("filter Charges fixes & variables")

              // var newcbs =  this.cbs.filter(function(el) {
              //         if((el.intitule == "Charges fixes" || el.intitule == "Charges variables")){return false}
              //         return true;
              //     })

              //     console.log(newcbs)

              //     this.cbs= Object.assign({},newcbs)

              // console.log(this.cbs);

              this.cbs.push(obj);
            });

            this.charges_loading = false;

            resolve();
          })
          .catch((err) => {
            this.charges_loading = false;

            reject(err);
          });
      });
    },

    /*
     * Bilan détaillé
     */

    get_bds() {
      this.bd_loading = true;

      let payload = {
        client_id: this.selectedClient.id,
        annees: this.diagnostic_years,
      };

      return new Promise((resolve, reject) => {
        axios
          .post("/api/diag/analyse/bd", payload)
          .then((response) => {
            this.bds = _.orderBy(response.data, ["order"], ["asc"]);
            _.map(this.bds, function (obj) {
              obj.show = "1";
            });
            this.bd_loading = false;
            resolve();
          })
          .catch((err) => {
            this.bd_loading = false;

            reject(err);
          });
      });
    },

    /*
     * Compte de resultat
     */

    get_crs() {
      this.cr_loading = true;

      let payload = {
        client_id: this.selectedClient.id,
        annees: this.diagnostic_years,
      };

      return new Promise((resolve, reject) => {
        axios
          .post("/api/diag/analyse/cr", payload)
          .then((response) => {
            this.crs = _.orderBy(response.data, ["order"], ["asc"]);
            _.map(this.crs, function (obj) {
              obj.show = "1";
            });

            this.cr_loading = false;
            resolve();
          })
          .catch((err) => {
            this.cr_loading = false;

            reject(err);
          });
      });
    },

    /*
     * ordre croissant des onglets années
     */

    orderYear() {
      this.diagnostic_years = _.sortBy(this.diagnostic_years);
    },

    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist")
          .then((response) => {
            // console.log("/api/userclientlist/ OK");
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },

    getDiagnosticYears() {
      if (!this.selectedClient.id) {
        this.diagnostic_years = [];
        return;
      }
      this.diagnostic_years_loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/load_balanceannuelle_history_select?client_id=" +
              this.selectedClient.id
          )
          .then((response) => {
            this.possibles_diagnostic_years = _.uniq(
              _.map(response.data, function (v) {
                return v.cloture.substring(0, 4);
              })
            );

            // les trois dernieres années sont selectionnées par default

            this.diagnostic_years = _.sortBy(
              _.take(this.possibles_diagnostic_years, 3)
            );

            this.diagnostic_years_loading = false;
            resolve(response);
          })
          .catch((err) => {
            this.possibles_diagnostic_years = [];
            this.diagnostic_years_loading = false;
            reject(err);
          });
      });
    },
  },

  mounted() {
    this.getClients().then(() => {
      // recuperation du client selectionné sauvegardé en local storage
      // mise a jour du local storage si entretemps un paramétrage client a été mis à jour

      if (localStorage.selectedClient) {
        var selectedClientInlocalstorage = JSON.parse(
          localStorage.selectedClient
        );
        this.selectedClient = _.find(this.clients, {
          id: selectedClientInlocalstorage.id,
        });
        localStorage.selectedClient = JSON.stringify(this.selectedClient);
      }
    });
  },
};
</script>