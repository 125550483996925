<template>
  <div class="pa-3" v-if="top3categories.length > 0">
    <v-row>
      <v-col class="black--text text-center text-h5"
        >Situation à {{ selectedMonth }} : Budgets dépenses annuelles cibles
      </v-col>
    </v-row>
    <v-row class="" dense align="center">
      <v-col class="black--text text-center" cols="5"></v-col>
      <v-col class="black--text text-center" cols="7" align="center">
        <v-row dense align="center">
          <v-col
            class="black--text text-center pa-0"
            cols="4"
            style="font-weight: 500"
            >1er poste<br />{{ top3categories[0].intitule }}</v-col
          >
          <v-col
            class="black--text text-center pa-0"
            cols="4"
            style="font-weight: 500"
            >2eme poste<br />{{ top3categories[1].intitule }}</v-col
          >
          <v-col
            class="black--text text-center pa-0"
            cols="4"
            style="font-weight: 500"
            >3eme poste<br />{{ top3categories[2].intitule }}</v-col
          >
        </v-row>
      </v-col>
    </v-row>

    <v-row align="center" dense>
      <v-col
        align="center"
        class="black--text pa-0 text-center"
        cols="5"
        style="position: relative; padding-top: 25px !important"
      >
        <div
          class="pa-0"
          style="
            font-weight: 600;
            width: 26%;
            position: absolute;
            top: 27%;
            left: 10%;
            transform: translate(-0%, -100%);
          "
        >
          {{ top3categories[1].intitule }} <br /><span style="font-weight: 700"
            >({{ formatNumber(formatAmount(top3categories[1].total)) }}
            <span v-if="kilo_euro">K</span>€)</span
          >
        </div>
        <div
          class="pa-0"
          style="
            font-weight: 500;
            width: 26%;
            position: absolute;
            top: 10%;
            left: 37%;
            transform: translate(-0%, -100%);
          "
        >
          {{ top3categories[0].intitule }} <br /><span style="font-weight: 700"
            >({{ formatNumber(formatAmount(top3categories[0].total))
            }}<span v-if="kilo_euro">K</span>€)</span
          >
        </div>
        <div
          class="pa-0"
          style="
            font-weight: 500;
            width: 26%;
            position: absolute;
            top: 34%;
            left: 64%;
            transform: translate(-0%, -100%);
          "
        >
          {{ top3categories[2].intitule }} <br /><span style="font-weight: 700"
            >({{ formatNumber(formatAmount(top3categories[2].total)) }}
            <span v-if="kilo_euro">K</span>€)</span
          >
        </div>
        <img src="/podium.png" style="max-width: 100%" />
      </v-col>
      <v-col cols="7" class="pa-0" align="center">
        <v-row dense align="center">
          <v-col class="black--text text-center pa-0" cols="4">
            <v-row dense
              ><v-col class="pa-0"
                ><Doughnut
                  :data="chartData1"
                  :options="chartOptions"
                  :plugins="[chart1DoughnutPointer]"
                ></Doughnut></v-col
            ></v-row>
            <v-row dense
              ><v-col class="pa-0" style="font-weight: 500">
                {{ chartData1.datasets[0].data[0] }}% réalisé<br />
                ({{
                  formatNumber(
                    formatAmount(
                      this.SommeComptesBalance(this.top3categories[0].comptes)
                    )
                  )
                }}
                <span v-if="kilo_euro">K</span>€)
              </v-col></v-row
            >
          </v-col>
          <v-col class="black--text text-center pa-0" cols="4">
            <v-row dense
              ><v-col class="pa-0"
                ><Doughnut
                  :data="chartData2"
                  :options="chartOptions"
                  :plugins="[chart2DoughnutPointer]"
                ></Doughnut> </v-col
            ></v-row>
            <v-row dense
              ><v-col class="pa-0" style="font-weight: 500"
                >{{ chartData2.datasets[0].data[0] }}% réalisé<br />
                ({{
                  formatNumber(
                    formatAmount(
                      this.SommeComptesBalance(this.top3categories[1].comptes)
                    )
                  )
                }}
                <span v-if="kilo_euro">K</span>€)</v-col
              ></v-row
            >
          </v-col>
          <v-col class="black--text text-center pa-0" cols="4">
            <v-row dense
              ><v-col class="pa-0"
                ><Doughnut
                  :data="chartData3"
                  :options="chartOptions"
                  :plugins="[chart3DoughnutPointer]"
                ></Doughnut></v-col
            ></v-row>
            <v-row dense
              ><v-col class="pa-0" style="font-weight: 500"
                >{{ chartData3.datasets[0].data[0] }}% réalisé<br />
                ({{
                  formatNumber(
                    formatAmount(
                      this.SommeComptesBalance(this.top3categories[2].comptes)
                    )
                  )
                }}
                <span v-if="kilo_euro">K</span>€)</v-col
              ></v-row
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <!-- Affichage des 3 plus gros postes pour Frais généraux si cette categorie est sur le podium -->
      <v-col class="black--text text-left"> 
        <span v-if="this.top3categories.some(item => item.intitule === 'Frais généraux')">
          <b>Top 3 des postes de la catégorie Frais généraux</b><br>
          <span v-for="category in this.top3categories" :key="category.id">
            
            <span v-if="category.intitule === 'Frais généraux'"> 
                         
              <span v-for="(value, propertyName, index) in category.top3postes" :key="index">
                
            {{value.intitule }} ({{formatNumber(formatAmount(value.total)) }}<span v-if="kilo_euro">K</span>€)<br>
          </span>
          </span>
          </span>
        
        </span></v-col>
      <v-col class="black--text text-center"> </v-col>
      <v-col class="black--text text-center"> </v-col>
    </v-row>
  </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  name: "BudgetCible",
  components: { Doughnut },
  props: {
    selectedClient: { type: Object },
    exercice: { type: Number },
    balances: { type: Array },
    currentPrevis: { type: Array },
    kilo_euro: { type: Boolean },
    situationMonth: { type: String },
    selectedMonth: { type: String },
    moisExercice: { type: Array },
  },
  data() {
    return {
      loaded: false,
      DateCloturePlusProche: null,
      chartOptions: { responsive: true, maintainAspectRatio: true },

      categories_de_classe_6: [
        { intitule: "Achats", id: 6 },
        { intitule: "Frais généraux", id: 7 },
        { intitule: "Impôts, taxes et vers. ass.", id: 8 },
        { intitule: "Masse salariale", id: 9 },
        { intitule: "Dot. aux amort. & prov.", id: 10 },
        { intitule: "Charges financières", id: 11 },
        { intitule: "Autres charges d'expl.", id: 12 },
        { intitule: "Autres charges finan.", id: 13 },
        { intitule: "Autres charges except.", id: 14 },
      ],
    };
  },
  watch: {
    selectedMonth() {
      this.DateCloturePlusProche = this.getDateCloturePlusProche();
    },
  },
  computed: {
    top3categories() {
      const categories = this.categories_de_classe_6;

      // Filtrer les lignes budgétaires de classe 6 et convertir le champ 'total' en nombre
      const class6BudgetLines = _.filter(this.currentPrevis, { class: 6 }).map(
        (line) => ({
          ...line,
          total: parseFloat(line.total),
        })
      );

      // Regrouper les lignes budgétaires par catégorie
      const groupedByCategory = _.groupBy(
        class6BudgetLines,
        "budgetline_categorie_id"
      );

      // Calculer la somme de 'total' pour chaque catégorie
      const categoryTotals = _.mapValues(groupedByCategory, (lines) =>
        _.sumBy(lines, "total")
      );

      // Trier les catégories par montant décroissant
      const sortedCategories = _.orderBy(
        Object.entries(categoryTotals),
        [(entry) => entry[1]],
        ["desc"]
      );

      // Ajouter les intitulés de catégorie à sortedCategories
      const categoriesWithIntitules = sortedCategories.map(
        ([categoryId, total]) => {
          const category = categories.find(
            (cat) => cat.id === parseInt(categoryId)
          );
          return {
            id: categoryId,
            total,
            intitule: category ? category.intitule : "Inconnu",
            comptes: [], // Initialiser la liste des comptes
          };
        }
      );

      // Parcourir les catégories et ajouter les comptes correspondants
      categoriesWithIntitules.forEach((category) => {
        const categoryId = parseInt(category.id);
        const linesWithSameCategory = class6BudgetLines.filter(
          (line) => line.budgetline_categorie_id === categoryId
        );
        const allComptes = [];

        linesWithSameCategory.forEach((line) => {
          allComptes.push(...line.comptes.map((compte) => compte.compte));
        });

        category.comptes = allComptes;
      });

      // Obtenir les 3 premières catégories
      const top3categories = categoriesWithIntitules.slice(0, 3);

      // obtenir les 3 premiers postes pour chaque catégorie
      top3categories.forEach((category) => {
       
        // Filtrer les lignes budgétaires sur la categorie
        //  pour obtenir les postes de la categorie
        let postes = class6BudgetLines.filter(
          (line) => line.budgetline_categorie_id === parseInt(category.id)
        );

        //console.log("class6BudgetLines",class6BudgetLines)
        

        // Trier la collection par la propriété "total" de manière décroissante
        let sortedCollection = postes.sort((a, b) => parseFloat(b.total) - parseFloat(a.total));

        //console.log("les postes de la categorie" + category.id + " " +category.intitule ,sortedCollection)

        // Extraire les trois premiers éléments
        let topThree = sortedCollection.slice(0, 3);

        category.top3postes = topThree

        //console.log("topThree",topThree)




      })

      //console.log("top3categories",top3categories)
      return top3categories;
    },

    caCible() {
      // filtre currentPrevis budgetline_categorie_id = 1 => Chiffre d'affaire
      const lignesCA = _.filter(this.currentPrevis, {
        budgetline_categorie_id: 1,
      });
      let sommeTotalCA = _.sumBy(lignesCA, (item) => parseFloat(item.total));

      if (this.kilo_euro == true) {
        sommeTotalCA = sommeTotalCA / 1000;
      }
      return parseFloat(sommeTotalCA);
    },
    chart1DoughnutPointer() {
      const chart1doughnutPointer = {
        id: "chart1doughnutPointer",
        afterDatasetsDraw: (chart, args, plugins) => {
          const { ctx, data } = chart;

          ctx.save();

          const xCenter = chart.getDatasetMeta(0).data[0].x;
          const yCenter = chart.getDatasetMeta(0).data[0].y;

          const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
          const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
          const DoughnutThickness = outerRadius - innerRadius;

          const pointerColor = "#003c96";
          const pointerValue = this.selectedMonthPoste1CiblePourcent();
          const pointerRadius = 5;
          const angle = Math.PI / 180;

          // total value
          function summArray(arr) {
            return arr.reduce((acc, current) => acc + current, 0);
          }
          const dataPointArray = data.datasets[0].data.map((datapoint) => {
            return datapoint;
          });

          const totalSum = summArray(dataPointArray);
          const targetPointerRotation = (pointerValue / totalSum) * 360;
          const datapointPercentage =
            (data.datasets[0].data[0] / totalSum) * 100;

          // text
          ctx.font = "bold 20px sans-serif";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.baseLine = "middle";
          ctx.fillText("Réalisation", xCenter, yCenter - 10);
          ctx.fillText(
            `${datapointPercentage.toFixed(1)}%`,
            xCenter,
            yCenter + 15
          );

          //pointer
          ctx.translate(xCenter, yCenter);
          ctx.rotate(angle * targetPointerRotation);

          ctx.beginPath();
          ctx.fillStyle = pointerColor;
          // roundRect( x, y, width,height,radius)
          ctx.roundRect(
            0 - 2,
            -outerRadius - 5,
            4,
            DoughnutThickness + 10,
            pointerRadius
          );
          ctx.fill();

          ctx.restore();
        },
      };
      return chart1doughnutPointer;
    },
    chart2DoughnutPointer() {
      const chart2doughnutPointer = {
        id: "chart2doughnutPointer",
        afterDatasetsDraw: (chart, args, plugins) => {
          const { ctx, data } = chart;

          ctx.save();

          const xCenter = chart.getDatasetMeta(0).data[0].x;
          const yCenter = chart.getDatasetMeta(0).data[0].y;

          const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
          const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
          const DoughnutThickness = outerRadius - innerRadius;

          const pointerColor = "#003c96";
          const pointerValue = this.selectedMonthPoste2CiblePourcent();
          const pointerRadius = 5;
          const angle = Math.PI / 180;

          // total value
          function summArray(arr) {
            return arr.reduce((acc, current) => acc + current, 0);
          }
          const dataPointArray = data.datasets[0].data.map((datapoint) => {
            return datapoint;
          });

          const totalSum = summArray(dataPointArray);
          const targetPointerRotation = (pointerValue / totalSum) * 360;
          const datapointPercentage =
            (data.datasets[0].data[0] / totalSum) * 100;

          // text
          ctx.font = "bold 20px sans-serif";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.baseLine = "middle";
          ctx.fillText("Réalisation", xCenter, yCenter - 10);
          ctx.fillText(
            `${datapointPercentage.toFixed(1)}%`,
            xCenter,
            yCenter + 15
          );

          //pointer
          ctx.translate(xCenter, yCenter);
          ctx.rotate(angle * targetPointerRotation);

          ctx.beginPath();
          ctx.fillStyle = pointerColor;
          // roundRect( x, y, width,height,radius)
          ctx.roundRect(
            0 - 2,
            -outerRadius - 5,
            4,
            DoughnutThickness + 10,
            pointerRadius
          );
          ctx.fill();

          ctx.restore();
        },
      };
      return chart2doughnutPointer;
    },
    chart3DoughnutPointer() {
      const chart3doughnutPointer = {
        id: "chart3doughnutPointer",
        afterDatasetsDraw: (chart, args, plugins) => {
          const { ctx, data } = chart;

          ctx.save();

          const xCenter = chart.getDatasetMeta(0).data[0].x;
          const yCenter = chart.getDatasetMeta(0).data[0].y;

          const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
          const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
          const DoughnutThickness = outerRadius - innerRadius;

          const pointerColor = "#003c96";
          const pointerValue = this.selectedMonthPoste3CiblePourcent();
          const pointerRadius = 5;
          const angle = Math.PI / 180;

          // total value
          function summArray(arr) {
            return arr.reduce((acc, current) => acc + current, 0);
          }
          const dataPointArray = data.datasets[0].data.map((datapoint) => {
            return datapoint;
          });

          const totalSum = summArray(dataPointArray);
          const targetPointerRotation = (pointerValue / totalSum) * 360;
          const datapointPercentage =
            (data.datasets[0].data[0] / totalSum) * 100;

          // text
          ctx.font = "bold 20px sans-serif";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.baseLine = "middle";
          ctx.fillText("Réalisation", xCenter, yCenter - 10);
          ctx.fillText(
            `${datapointPercentage.toFixed(1)}%`,
            xCenter,
            yCenter + 15
          );

          //pointer
          ctx.translate(xCenter, yCenter);
          ctx.rotate(angle * targetPointerRotation);

          ctx.beginPath();
          ctx.fillStyle = pointerColor;
          // roundRect( x, y, width,height,radius)
          ctx.roundRect(
            0 - 2,
            -outerRadius - 5,
            4,
            DoughnutThickness + 10,
            pointerRadius
          );
          ctx.fill();

          ctx.restore();
        },
      };
      return chart3doughnutPointer;
    },
    chartData1() {
      if (this.top3categories[0].total == 0) {
        return;
      }

      const realise = Math.ceil(
        (100 * this.SommeComptesBalance(this.top3categories[0].comptes)) /
          this.top3categories[0].total
      );

      let resteafaire = 100 - realise;
      if (resteafaire < 0) {
        resteafaire = 0;
      }

      return {
        labels: ["Réalisé", "Reste à faire"],
        datasets: [
          {
            backgroundColor: ["#ffbde9", "#0070c0"],
            data: [realise, resteafaire],
            cutout: "65%",
          },
        ],
      };
    },
    chartData2() {
      if (this.top3categories[1].total == 0) {
        return;
      }

      const realise = Math.ceil(
        (100 * this.SommeComptesBalance(this.top3categories[1].comptes)) /
          this.top3categories[1].total
      );

      let resteafaire = 100 - realise;
      if (resteafaire < 0) {
        resteafaire = 0;
      }

      return {
        labels: ["Réalisé", "Reste à faire"],
        datasets: [
          {
            backgroundColor: ["#ffbde9", "#0070c0"],
            data: [realise, resteafaire],
            cutout: "65%",
          },
        ],
      };
    },
    chartData3() {
      if (this.top3categories[2].total == 0) {
        return;
      }

      const realise = Math.ceil(
        (100 * this.SommeComptesBalance(this.top3categories[2].comptes)) /
          this.top3categories[2].total
      );

      let resteafaire = 100 - realise;
      if (resteafaire < 0) {
        resteafaire = 0;
      }

      return {
        labels: ["Réalisé", "Reste à faire"],
        datasets: [
          {
            backgroundColor: ["#ffbde9", "#0070c0"],
            data: [realise, resteafaire],
            cutout: "65%",
          },
        ],
      };
    },
  },

  methods: {
    formatNumber(nombre) {
      return nombre.toLocaleString("fr-FR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    // Fonction pour formater la valeur en euros ou K€
    formatAmount(amount) {
      if (this.kilo_euro == true) {
        amount = amount / 1000;
      }
      return parseFloat(amount);
    },

    selectedMonthPoste1CiblePourcent() {
      // index du mois selectionné
      const selectedMonthIndex = _.indexOf(
        this.moisExercice,
        this.selectedMonth
      );

      // filtre les previs sur la categorie ID du premier poste de dépense

      const lignesPrevis = _.filter(this.currentPrevis, {
        budgetline_categorie_id: parseInt(this.top3categories[0].id),
      });

      // valeur cumulé du poste pour le mois selectionné

      const selectedMonthCibleValuePoste1 = _.sumBy(lignesPrevis, (item) => {
        let total = 0;
        for (let i = 1; i <= selectedMonthIndex + 1; i++) {
          total += parseFloat(item["mois_" + i]);
        }
        return total;
      });

      // pourcentage par rapport à la valeur cible sur l'année
      const selectedMonthcaCiblePourcentPoste1 =
        (100 * selectedMonthCibleValuePoste1) / this.top3categories[0].total;

      return selectedMonthcaCiblePourcentPoste1;
    },
    selectedMonthPoste2CiblePourcent() {
      // index du mois selectionné
      const selectedMonthIndex = _.indexOf(
        this.moisExercice,
        this.selectedMonth
      );

      // filtre les previs sur la categorie ID du deuxieme poste de dépense

      const lignesPrevis = _.filter(this.currentPrevis, {
        budgetline_categorie_id: parseInt(this.top3categories[1].id),
      });

      // valeur cumulé du poste pour le mois selectionné

      const selectedMonthCibleValuePoste2 = _.sumBy(lignesPrevis, (item) => {
        let total = 0;
        for (let i = 1; i <= selectedMonthIndex + 1; i++) {
          total += parseFloat(item["mois_" + i]);
        }
        return total;
      });

      // pourcentage par rapport à la valeur cible sur l'année
      const selectedMonthcaCiblePourcentPoste2 =
        (100 * selectedMonthCibleValuePoste2) / this.top3categories[1].total;

      return selectedMonthcaCiblePourcentPoste2;
    },
    selectedMonthPoste3CiblePourcent() {
      // index du mois selectionné
      const selectedMonthIndex = _.indexOf(
        this.moisExercice,
        this.selectedMonth
      );

      // filtre les previs sur la categorie ID du troisieme poste de dépense

      const lignesPrevis = _.filter(this.currentPrevis, {
        budgetline_categorie_id: parseInt(this.top3categories[2].id),
      });

      // valeur cumulé du poste pour le mois selectionné

      const selectedMonthCibleValuePoste3 = _.sumBy(lignesPrevis, (item) => {
        let total = 0;
        for (let i = 1; i <= selectedMonthIndex + 1; i++) {
          total += parseFloat(item["mois_" + i]);
        }
        return total;
      });

      // pourcentage par rapport à la valeur cible sur l'année
      const selectedMonthcaCiblePourcentPoste3 =
        (100 * selectedMonthCibleValuePoste3) / this.top3categories[2].total;

      return selectedMonthcaCiblePourcentPoste3;
    },
    getDateCloturePlusProche() {
      // recherche de la date de cloture de balance la plus proche de selectedMonth
      // sans la depasser

      let selectedCloture = this.$moment(this.selectedMonth, "MMMYY").endOf(
        "month"
      );

      // clone balances and remove its reactivity
      let sortedBalances = JSON.parse(JSON.stringify(this.balances));

      // Trier la collection en fonction de la proximité des dates avec 'selectedCloture'
      sortedBalances.sort((a, b) => {
        // Convertir les dates 'cloture' de chaque élément en objets Moment
        let dateA = this.$moment(a.cloture);
        let dateB = this.$moment(b.cloture);

        // Calculer les différences entre les dates et les comparer
        return (
          Math.abs(dateA.diff(selectedCloture)) -
          Math.abs(dateB.diff(selectedCloture))
        );
      });

      // La date la plus proche sans la dépasser est maintenant dans le premier élément de la collection triée
      let dateCloturePlusProche =
        sortedBalances.length > 0
          ? this.$moment(sortedBalances[0].cloture)
          : null;

      return dateCloturePlusProche;
    },

    /*
     * calcul des sommes de compte dans la balance mensuelle la plus proche
     * de selectedMonth (sans la dépasser)
     */

    SommeComptesBalance(comptes) {
      let sommeSolde = 0;

      //conversion des numeros de compte pour ne garder que le radical
      const radicalComptes = _.map(comptes, (item) => item.replace(/0+$/, ""));

      // Filtrer la collection pour ne garder que les objets avec la date de clôture la plus proche de selectedMonth
      const filteredBalances = _.filter(this.balances, (item) =>
        this.$moment(item.cloture).isSame(this.DateCloturePlusProche)
      );

      filteredBalances.forEach((objet) => {
        const solde = parseFloat(objet.solde);
        if (!isNaN(solde)) {
          radicalComptes.forEach((numero) => {
            // Vérifier si la propriété "compte" commence par le numéro de compte
            if (objet.compte.startsWith(Math.abs(numero).toString())) {
              //ajout du solde (nous sommes dans les charges)
              sommeSolde += solde;
            }
          });
        }
      });
      return sommeSolde;
    },
  },

  mounted() {
    this.DateCloturePlusProche = this.getDateCloturePlusProche();
    this.loaded = true;
  },
};
</script>